import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  add_experience,
  delete_experience,
} from "../../../../reducers/jsResume/experienceReducer";
import { job_listing, resume } from "../../../../theme";
import { Formik, Form } from "formik";
import FormikTextInput from "../../../ReusableComponents/FormikTextInput";
import FormikDropDown from "../../../ReusableComponents/FormikDropDown";
import { work_experiences } from "../../../../validations/resume";
import FormikDateInput from "../../../ReusableComponents/FormikDate";
import { DeleteOutline } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import UpdateExperience from "./UpdateResume/UpdateExperience";
import InfoIcon from "@material-ui/icons/Info";


const OptionButton = (props) => {
  return (
    <button
      style={{
        ...job_listing.button,
        backgroundColor: props.checked ? "#249afa" : "white",
        color: props.checked ? "white" : "black",
        width: "50%",
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const EditWorkExperiences = () => {
  const dispatch = useDispatch();

  const [is_current_job, set_is_current_job] = useState(false);
  const [positions, setPositions] = useState([]);
  const user_email = useSelector( ({user})=> user.email)
  const user_phone = useSelector( ({job_seeker_info})=> job_seeker_info.telephone)
  const general = useSelector(({ general }) => general);
  const all_experiences = useSelector(
    ({ work_experiences }) => work_experiences
  );

  const [show_menu, set_show_menu] = useState(false);
  const addMenuRef = useRef()
  const handle_menu = (e) => {
    e.preventDefault()
    set_show_menu(!show_menu)
    setTimeout(()=>scrollToDiv(),100) 
  }
  const scrollToDiv = () => addMenuRef.current.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    if (general.length !== 0) {
      const positionsArray = general.positions.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setPositions(positionsArray);
    }
  }, [general]);

  const handle_delete = async (e, id) => {
    e.preventDefault();
    const ok = window.confirm("Are you sure you want to delete Experience?");
    if (ok) {
      dispatch(delete_experience(id));
    }
  };

  const [curr_experience, set_curr_experience] = useState(null);
  const [show_edit, set_show_edit] = useState(false);
  const handle_edit_click = (ev, e) => {
    ev.preventDefault();
    set_curr_experience(e);
    set_show_edit(true);
  };

  return (
    <div className="logged-in-container">
      {show_edit ? (
        <UpdateExperience
          set_show_edit={set_show_edit}
          experience={curr_experience}
        />
      ) : null}

      <div className="resume-item-page">
        <div>
        <p className="font-weight-bold text-secondary h5 mt-4"> My Work Experiences </p>

          {all_experiences.length > 0 ? (
            <div className="resume-item-page-added">
              {all_experiences.map((e) => (
                <div key={e.id}>
                  <table style={resume.experience_table}>
                    <tbody>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ ...resume.titles, marginTop: "10px" }}
                        >
                          {e.position.name}
                          <button
                            onClick={(ev) => handle_delete(ev, e.id)}
                            style={resume.delete_button}
                          >
                            <DeleteOutline />
                          </button>
                          <button
                            onClick={(ev) => handle_edit_click(ev, e)}
                            style={resume.edit_button}
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Company</td>
                        <td style={resume.e_table_info}>{e.company_name}</td>
                      </tr>

                      <tr>
                        <td>Start Date</td>
                        <td style={resume.e_table_info}>
                          {e.start_date
                            ? new Intl.DateTimeFormat("en-US").format(
                                Date.parse(e.start_date)
                              )
                            : "Not provided"}
                        </td>
                      </tr>

                      <tr>
                        <td>End Date</td>
                        <td style={resume.e_table_info}>
                          {e.end_date
                            ? new Intl.DateTimeFormat("en-US").format(
                                Date.parse(e.end_date)
                              )
                            : "Current"}
                        </td>
                      </tr>

                      <tr>
                        <td>Reference Name</td>
                        <td style={resume.e_table_info}>{e.contact_name}</td>
                      </tr>

                      <tr>
                        <td>Reference Email</td>
                        <td style={resume.e_table_info}>{e.contact_email}</td>
                      </tr>

                      <tr>
                        <td>Reference Phone</td>
                        <td style={resume.e_table_info}>{e.contact_phone}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <button onClick={handle_menu} className="btn btn-outline-primary mt-4">Add new experience</button> 
        <div ref={addMenuRef}></div>
        {show_menu && 
        <div className="resume-item-page-form shadow">
          <Formik
              initialValues={{
                company_name: "",
                position: undefined,
                contact_name: "",
                contact_email: "",
                contact_phone: "",
                start_date: new Date(),
                end_date: new Date(),
              }}
              validationSchema={work_experiences}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // Reset Form
                setSubmitting(false);

                const payload = {
                  company_name: values.company_name,
                  position_id: values.position.id,
                  contact_name: values.contact_name,
                  contact_email: values.contact_email,
                  contact_phone: values.contact_phone,
                  start_date: values.start_date,
                  end_date: is_current_job ? null : values.end_date,
                  is_current_job,
                };
               
                dispatch(add_experience(payload));
                resetForm();
              }}
            >
              {(props) => (
                <Form
                  style={{ textAlign: "left", width: "100%", margin: "auto" }}
                >
                  <p className="font-weight-bold text-center text-secondary h6 mt-4"> Add new Work experience </p>
                  <FormikTextInput
                    label="Work Experience"
                    name="company_name"
                    placeholder="Company Name"
                  />
                  <FormikDropDown
                    // label='Country'
                    options={positions}
                    name="position"
                    placeholder={"Position"}
                    value={positions.find(
                      (pos) => pos.value === props.values.position?.id
                    )}
                  />

                  <br />
                  <p className="text-input-label">Current Job?</p>
                  <div className="text-center">
                    <OptionButton
                      label={"No"}
                      checked={is_current_job === false}
                      onClick={(e) => {
                        e.preventDefault();
                        set_is_current_job(false);
                      }}
                    />
                    <OptionButton
                      label={"Yes"}
                      checked={is_current_job === true}
                      onClick={(e) => {
                        e.preventDefault();
                        set_is_current_job(true);
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <FormikDateInput name="start_date" label="Start date" />
                    {!is_current_job ? (
                      <FormikDateInput name="end_date" label="End date" />
                    ) : null}
                  </div>
                  <br />
                  <p className="text-input-label">
                    Reference <InfoIcon style={{ float: "right" }} />
                  </p>
                  <FormikTextInput
                    name="contact_name"
                    placeholder="Reference Name"
                  />
                  <FormikTextInput
                    name="contact_email"
                    placeholder="Reference Email"
                  />
                  {user_email===props.values.contact_email &&<label className="invalid-feedback"> This field cannot be your email! </label>}
                  <FormikTextInput
                    name="contact_phone"
                    placeholder="Reference Phone"
                  />
                  {user_phone===props.values.contact_phone &&<label className="invalid-feedback"> This field cannot be your phone number! </label>}

                  <br />

                  <div className="text-center">
                    <button
                      className="btn btn-outline-primary w-100"
                      type="submit"
                      disabled={user_email===props.values.contact_email}
                    >
                      {props.isSubmitting ? "Loading.." : "Add"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
      </div>
    </div>
  );
};

export default EditWorkExperiences;
