import React, { useState, useEffect } from "react";

//import Loader from "react-loader";
import './reusable_components.css'
import LoadingOverlay from "react-loading-overlay";
//import BounceLoader from "react-spinners/BounceLoader";

import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  padding-top:20%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

function LoadingSpinner() {
  return (
    <div className="loading_spinner">
      <DarkBackground>

        <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
          text="loading..."
        >
          {/* <p>Some content or children or something.</p> */}
        </LoadingOverlay>
      </DarkBackground>
    </div>
  );
}

export default LoadingSpinner;
