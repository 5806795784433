import React, {  useEffect, useState } from 'react'
import { Form, Spinner, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {update_user} from '../../../reducers/userReducer'
import ChangePassword from '../../JobSeeker/ManageProfile/AccountParts/ChangePassword'
import TextInput from '../../ReusableComponents/TextInput'
import { useHistory } from 'react-router'

const styles = {

    label:{
        margin:'20px',
        fontSize: '16px',
        color: '#445566',
        marginBottom:'5px'
    },
    value:{
        color: '#839eaf',
        fontSize: '16px',
        lineHeight: 1.5,
        marginLeft:'20px',
    },
    delete_label:{
        color:'#ee5566',
        fontSize: '16px',
        marginLeft:'20px'
    }
}


const MyProfileJP = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [first_name, set_first_name] = useState("")
    const [last_name, set_last_name] = useState("")
    const [email, set_email] = useState("")
    // const [password, set_password] = useState("")
    const [edit_mode, set_edit_mode] = useState(false)
    const [show_edit, set_show_edit] = useState(false)
    const user = useSelector( ({user}) => user )

    useEffect(()=>{
        if(user){
            set_first_name(user.first_name)
            set_last_name(user.last_name)
            set_email(user.email)
        }
        
    },[user])

    if(!user){ return (
        <div style={{textAlign:'center'}}>
         <Spinner style={{}} animation="border" /> 
        </div>
     )
    }

    const handle_update = async (e) => {
        e.preventDefault()
        const payload = {
            first_name: first_name,
            last_name: last_name,
        }
        dispatch(update_user(payload))
        set_edit_mode(false)
    }


    const handleTextField = (e,f) => { f(e.target.value) }


    return (
            <Form className='home-tab' onSubmit={handle_update} >
                {show_edit ? <ChangePassword set_show_edit={set_show_edit}/> : null }


                {edit_mode? 
                <TextInput
                    label={'First Name'}
                    handleChange = {(e)=>{handleTextField(e,set_first_name)}}
                    type={'text'}
                    value={first_name}
                />
                :
                <div> 
                    <img style={{paddingRight:10}} className='profile-pic float-right' alt='Profile Rapihire' src={user.profile_image} />
                    <p style={styles.label}>First Name</p> 
                    <p onDoubleClick={(e)=>{e.preventDefault();set_edit_mode(true)}} style={styles.value}>{first_name}</p>
                </div>
                }
            
                <hr/>

                {edit_mode? 
                <TextInput
                    label={'Last Name'}
                    handleChange = {(e)=>{handleTextField(e,set_last_name)}}
                    type={'text'}
                    value={last_name}
                />
                :
                <div> 
                    <p style={styles.label}>Last Name</p> 
                    <p onDoubleClick={(e)=>{e.preventDefault();set_edit_mode(true)}} style={styles.value}>{last_name}</p>
                </div>
                }

                <hr/>

                <div> 
                    <p style={styles.label}>Email</p> 
                    <p style={styles.value}>{email}</p>
                </div>

                <hr/>

                <div> 
                    <p style={styles.label}>Password <b onClick={(e)=>set_show_edit(true)} style={{fontSize:14,float:'right', color:'#249afa', cursor:'pointer'}}>Change</b></p> 
                    <p style={styles.value}>*******</p>
                </div>
                
                <hr/>
                <div> 
                    <p style={styles.label}>Security &amp; Privacy</p> 
                    <p style={styles.value}>2-step verification</p>
                </div>
                
                <hr/>
          

                { edit_mode ? 
                <div >
                    <Button onClick={(e)=>set_edit_mode(false)} variant="secondary">
                     Cancel
                    </Button>
                    <Button type='submit' style={{float:'right'}} variant="primary">
                        Update info
                    </Button>
                </div>
                : null} 
                
            </Form>

)
}




const MyProfileJPForm = () => {
    
    return <MyProfileJP/>
}
export default MyProfileJPForm
