import React, { useState } from "react";
import { job_listing } from "../../../theme";
import InfoIcon from "@material-ui/icons/Info";

const OptionButton = (props) => {
  return (
    <button
      className="option-button"
      style={{
        backgroundColor: props.checked ? "#249afa" : "white",
        color: props.checked ? "white" : "black",
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const Instructions = (props) => {
  const [type, set_type] = useState(false);
  const handle_no = (e) => {
    e.preventDefault();
    props.instructions.setValue('');
    set_type(false);
  };

  const handle_yes = (e) => {
    e.preventDefault();
    set_type(true);
  };

  return (
    <div>
      <p className="font-weight-bold text-secondary">
        Job Instructions for the winning candidate{" "}
        <InfoIcon style={{ float: "right" }} />{" "}
      </p>

      <div style={{ textAlign: "center" }}>
        <OptionButton onClick={handle_no} checked={!type} label={"No"} />
        <OptionButton onClick={handle_yes} checked={type} label={"Yes"} />
      </div>

      <br />
      <br />

      {type ? (
        <div>
          <p className="font-weight-bold text-secondary">Please provide instructions</p>

          <textarea
            style={job_listing.textarea}
            {...props.instructions}
            placeholder="Type here"
          />
        </div>
      ) : null}

      <p className="font-weight-bold text-secondary">
        Online Session <InfoIcon className="float-right" />
      </p>

      <div className="text-center">
        <OptionButton
          onClick={(e) => {
            e.preventDefault();
            props.set_online_session(false);
          }}
          checked={!props.online_session}
          label={"No"}
        />
        <OptionButton
          onClick={(e) => {
            e.preventDefault();
            props.set_online_session(true);
          }}
          checked={props.online_session}
          label={"Yes"}
        />
      </div>
      <br/><br/>
      <div>
        <p className="font-weight-bold text-secondary">Other notes</p>
        <textarea
          style={job_listing.textarea}
          {...props.other_notes}
          placeholder="Type here"
        />
      </div>

      <div>
        <button className="btn btn-light w-50" onClick={props.prevSection}>
          Previous
        </button>
        <button className="btn btn-primary w-50" onClick={props.handleApprove}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Instructions;
