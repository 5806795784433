import userService from '../services/user'
import { errorAlert, successAlert} from './alertReducer'
import { set_loading_false, set_loading_true } from './loadingReducer'


const jsReducer = (state=[], action) => {
    switch (action.type){

        case 'INIT_INFO':
            return action.data

        case 'UPDATE_INFO':
            return action.data
        
        default:
            return state
    }
}

export const get_js_info = (user) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await userService.getJS(user)
            dispatch({
                type:'INIT_INFO',
                data
            })
            dispatch(set_loading_false())
        }
        catch(exception){
            dispatch(set_loading_false())
            console.log('Error getting jobseeker info')
        }
    }
}


export const update_js_info = (payload,user) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            await userService.updateJS(payload) 
            const data = await userService.getJS(user)

            dispatch({
                type:'UPDATE_INFO',
                data
            })

            dispatch(set_loading_false())
            dispatch(successAlert("Successfully updated information!"));
            setTimeout(()=>dispatch(successAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert("There is an error updating information!"));
        }
    }
}


export default jsReducer