import axios from 'axios'
import {url} from './utils.js'

const baseURL = url

let token = null

const setToken = (newToken) => {
    token = `token ${newToken}`
}

const login = async (credentials) => {
    const response = await axios.post(`${baseURL}accounts/token/`, credentials)
    return response.data
}

const getUser = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}accounts/me/`, config)
    return response.data
}

const updateUser = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}accounts/me/`,payload, config)
    return response.data
}

const changePassword = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}accounts/changepassword/`,payload, config)
    return response.data
}

const forgotPassword = async (payload) => {

    const response = await axios.patch(`${baseURL}accounts/forgotpassword/`,payload)
    return response.data
}

const getJS = async (user) => {
    // const id = user.id
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}accounts/js/`, config)
    return response.data
}

const getJSasCandidate = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}accounts/candidatedetail/${id}`, config)
    return response.data
}

const createJP = async (credentials) => {
    const response = await axios.post(`${baseURL}accounts/create/jp/`, credentials)
    return response.data
}

const createJS = async (credentials) => {
    const response = await axios.post(`${baseURL}accounts/create/js/`, credentials)
    return response.data
}


const updateJS = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}accounts/js/updateprofile/`,payload, config)
    return response.data
}

// User Info services


const getExperiences = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}experience/`, config)
    return response.data
}

const addExperience = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}experience/`, payload, config)
    return response.data
}

const deleteExperience = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}experience/${id}`, config)
    return response.data
}

const updateExperience = async (payload, id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}experience/${id}`,payload, config)
    return response.data
}

const getCandidateExperiences = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}candidateexperience/${id}`, config)
    return response.data
}

// Educations

const getEducations = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}education/`, config)
    return response.data
}

const addEducation = async (payload) => {
    const config = {
        headers: { Authorization: token},

    }
    const response = await axios.post(`${baseURL}education/`, payload, config)
    return response.data
}

const uploadEducation = async (form_data, id) => {
    const config = {
        headers: { 
            Authorization: token,
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await axios.patch(`${baseURL}education/upload/${id}`, form_data, config)
    return response.data
}

const deleteEducation = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}education/${id}`, config)
    return response.data
}

const updateEducation = async (payload, id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}education/${id}`,payload, config)
    return response.data
}

const getCandidateEducations = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}candidateeducation/${id}`, config)
    return response.data
}
// License 

const getLicenses = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}license/`, config)
    return response.data
}

const addLicense = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}license/`, payload, config)
    return response.data
}

const uploadLicense = async (form_data, id) => {
    console.log('Uploading...');
    const config = {
        headers: { 
            Authorization: token,
            'Content-Type': 'multipart/form-data',
        },

    }
    const response = await axios.patch(`${baseURL}license/upload/${id}`, form_data, config)
    return response.data
}

const deleteLicense = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}license/${id}`, config)
    return response.data
}

const updateLicence = async (payload, id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}license/${id}`,payload, config)
    return response.data
}

const getCandidateLicenses = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}candidatelicense/${id}`, config)
    return response.data
}
// Cerification

const getCertifications = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}certification/`, config)
    return response.data
}

const deleteCertification = async(id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}certification/${id}`, config)
    return response.data
}

const addCertification = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}certification/`, payload, config)
    return response.data
}

const uploadCertificate = async (form_data, id) => {
    console.log('Uploading...');
    const config = {
        headers: { 
            Authorization: token,
            'Content-Type': 'multipart/form-data',
        },

    }
    const response = await axios.patch(`${baseURL}certification/upload/${id}`, form_data, config)
    return response.data
}

const updateCertification = async (payload, id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}certification/${id}`,payload, config)
    return response.data
}


const getCandidateCertifications = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}candidatecertification/${id}`, config)
    return response.data
}

// Skills 

const getUserSkills = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}userskills/`, config)
    return response.data
}

const deleteUserSkill = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}userskill/${id}`, config)
    return response.data
}

const addUserSkills = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}userskills/`, payload, config)
    return response.data
}

const getUserLanguages = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}userslanguages/`, config)
    return response.data
}

const addUserLanguages = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}userslanguages/`,payload, config)
    return response.data
}

const deleteUserLanguage = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}userslanguages/${id}`, config)
    return response.data
}

const get_referrals = async () => {

    const config = {
        headers: { Authorization: token},
    }

    const {data} = await axios.get(`${baseURL}referrals/`, config)
    return data
}

const create_referral = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.post(`${baseURL}referrals/`, payload, config)
    return data
}

const resend_email_referral = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.get(`${baseURL}resend/${id}`, config)
    return data
}


const update_profile_image = async (form_data) => {
    const config = {
        headers: { 
            Authorization: token,
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await axios.patch(`${baseURL}accounts/me/profile_image/`, form_data, config)
    return response.data
}

const get_notifications = async () => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }
    const response = await axios.get(`${baseURL}notifications/`, config)
    return response.data
}

const close_notification = async (payload) => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }
    const response = await axios.patch(`${baseURL}notifications-close/`, payload, config)
    return response.data
}

const get_reasons_declined_js = async (user_id) => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }

    const response = await axios.get(`${baseURL}accounts/reasons-declined-js/${user_id}`, config)
    return response.data
}

const resubmit_profile_js = async (user_id) => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }

    const response = await axios.patch(`${baseURL}accounts/resubmit-profile-js/${user_id}`, {}, config)
    return response.data
}

const resubmit_profile_jp = async (user_id) => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }

    const response = await axios.patch(`${baseURL}accounts/resubmit-profile-jp/${user_id}`, {}, config)
    return response.data
}

const get_approval_history = async (user_id) => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }

    const response = await axios.get(`${baseURL}accounts/approval-history/${user_id}`, config)
    return response.data
}
const delete_account = async () => {
    
    const config = {
        headers: { 
            Authorization: token,
        },
    }

    const response = await axios.patch(`${baseURL}accounts/deactivate/`,{}, config)
    return response.data
}

const unsubscribe_emails = async () => {
    const config = {
        headers: { 
            Authorization: token,
        },
    }
    const response = await axios.patch(`${baseURL}accounts/unsubscribe-from-emails/`,{}, config)
    return response.data
}

const twilio_send_code = async (phone_number) => {
    const response = await axios.post(`${baseURL}accounts/twilio-send-code/`,{phone_number})
    return response.data
}

const twilio_confirm_code = async (code, phone_number) => {

    const response = await axios.post(`${baseURL}accounts/twilio-confirm-code/`,{code, phone_number})
    return response.data
}


export default {login, getUser,updateUser, createJP, createJS, setToken, getJS, updateJS, 
    
    getExperiences, addExperience, updateExperience, deleteExperience, create_referral,

    getEducations, addEducation, uploadEducation, updateEducation, deleteEducation,
    
    getLicenses, updateLicence, addLicense, uploadLicense, deleteLicense, getJSasCandidate, uploadCertificate,

    getCertifications, addCertification, updateCertification, deleteCertification, deleteUserLanguage,

    getCandidateExperiences, getCandidateEducations, getCandidateLicenses, getUserLanguages, addUserLanguages,

    getCandidateCertifications, changePassword, forgotPassword, update_profile_image, close_notification,

    getUserSkills, deleteUserSkill, addUserSkills, get_referrals, resend_email_referral, get_notifications, unsubscribe_emails,

    get_reasons_declined_js, resubmit_profile_js, resubmit_profile_jp, get_approval_history, delete_account, twilio_send_code, twilio_confirm_code }
