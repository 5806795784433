import axios from 'axios'
import {url} from './utils.js'

const baseURL = url

let token = null

const setToken = (newToken) => {
    token = `token ${newToken}`
}

const listInterested = async () => {
    const config = {
        headers: { Authorization: token},
    }
    // console.log('Token/ListInterested ', token);
    const response = await axios.get(`${baseURL}interestedpositions/`, config)
    return response.data
}

const listOpenings = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}jobopenings/`, config)
    return response.data
}

const createListOpening = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}jobopenings/`, payload, config)
    return response.data
}

const getListingSkills = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}listingskills/${id}`, config)
    return response.data
}

const getListingLanguages = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}listinglanguages/${id}`, config)
    return response.data
}


const addListingToLanguage = async (
    {language_id,
    listing_id,
    required}
  ) => {
    const config = {
        headers: { Authorization: token },
    }
    const response = await axios.post(`${baseURL}listinglanguages/`, {
        language_id,
        listing_id,
        required,
      }, config)
    return response.data
};


const deleteListingToLanguage = async language_id => {
    const response = await axios.delete(
      `${baseURL}listinglanguages/delete/${language_id}`,
    );
    return response.data;
};


const getListingNumberOfEmployees = async (id) => {

    const response = await axios.get(`${baseURL}jobopeningemployed/${id}`)
    return response.data
}

const updateListOpeningById = async (payload,id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}jobopenings/${id}`, payload, config)
    return response.data
}

const deleteListOpeningById = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}jobopenings/${id}`, config)
    return response.data
}


const createInterested = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}interestedpositions/`, payload, config)
    return response.data
}

const getInterestedById = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}interestedpositions/${id}`, config)
    return response.data
}

const updateInterested = async (id, payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}interestedpositions/${id}`,payload, config)
    return response.data
}

const deleteInterestedById = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}interestedpositions/${id}`, config)
    return response.data
}

const updateListingSkills = async (payload,id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}listingskill/${id}`, payload, config)
    return response.data
}

const deleteListingSkill = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}listingskill/${id}`, config)
    return response.data
}

const addListingSkill = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}listingskills/1`, payload, config)
    return response.data
}

const add_listing_to_certificate = async (listing_id, certificate_name) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}listing-certificate-add/${certificate_name}/${listing_id}`, {}, config)
    return response.data
}

const delete_listing_to_certificate = async (listing_id, certificate_id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}listing-certificate-delete/${certificate_id}/${listing_id}`, {}, config)
    return response.data
}

const add_listing_to_license = async (listing_id, license_name) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}listing-license-add/${license_name}/${listing_id}`, {}, config)
    return response.data
}

const delete_listing_to_license = async (listing_id, license_id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.delete(`${baseURL}listing-license-delete/${license_id}/${listing_id}`, {}, config)
    return response.data
}



export default {
    listInterested, listOpenings, deleteListingToLanguage, delete_listing_to_license, add_listing_to_license,
    getInterestedById, setToken, addListingToLanguage, delete_listing_to_certificate,
    createListOpening, createInterested, updateInterested, add_listing_to_certificate,
    deleteInterestedById, updateListOpeningById,getListingNumberOfEmployees, addListingSkill,
    deleteListOpeningById, getListingSkills, updateListingSkills, deleteListingSkill, getListingLanguages
}
