import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import matchService from "../../../services/match";
import RateCompany from "./Extension/RateCompany";
import StopContractJS from "./Extension/StopContractJS";

const styles = {
  leftTable: {
    textAlign: "left",
    width: "60%",
    color: "#445566",
    fontWeight: 500,
    fontSize: 16,
    padding: "10px",
  },
  rightTable: {
    textAlign: "left",
    color: "#445566",
  },
};

const OfferForm = ({ listing, ...props }) => {
  const [offer, setOffer] = useState(null);
  const offerID = useParams().offerID;
  const [menu_state, set_menu_state] = useState('')
  console.log(offer);

  useEffect(() => {
    let mounted = true;
    matchService
      .getOfferByID(offerID)
      .then((offer) => {
        if (mounted) setOffer(offer);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => (mounted = false);
  }, [offerID]);

  const set_menu = (e, state) => {
    e.preventDefault();
    if (menu_state === state){
      set_menu_state('');
    }
    else set_menu_state(state);
  }


  if (!offer) return "loading...";
  return (
    <div className="offer-detail-div">
      <div className="offer-detail-time-circle">--:--</div>

      <table style={{ width: "100%" }}>
        <tbody>

          <tr>
            <td style={styles.leftTable}>
              <b>Offer Time</b>
            </td>
            <td style={styles.rightTable}>
              {new Intl.DateTimeFormat("en-US", {
                dateStyle: "full",
                timeStyle: "short",
              }).format(Date.parse(offer.match_time))}
              <hr />
            </td>
          </tr>
          <tr>
            <td style={styles.leftTable}>Company name</td>
            <td style={styles.rightTable}>{offer.job_listing.company.name}</td>
          </tr>
          <tr>
            <td style={styles.leftTable}>Position</td>
            <td style={styles.rightTable}>{offer.job_listing.position.name}</td>
          </tr>
          <tr>
            <td style={styles.leftTable}>Location</td>
            <td style={styles.rightTable}>
              {listing.address}
              <br />
              {offer.city.name}, {offer.city.province.name}, {offer.zip_code},{" "}
              {offer.city.province.country.name}
            </td>
          </tr>
          <tr>
            <td style={styles.leftTable}>Start - End date</td>
            <td style={styles.rightTable}>
              {new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
                Date.parse(offer.start_date)
              )}{" "}
              -
              {new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
                Date.parse(offer.end_date)
              )}
            </td>
          </tr>

          <tr>
            <td style={styles.leftTable}>Work is</td>
            <td style={styles.rightTable}>{listing.job_all_length}</td>
          </tr>

          <tr>
            <td style={styles.leftTable}>Job Period</td>
            <td style={styles.rightTable}>{listing.job_time_length}</td>
          </tr>

          <tr>
            <td style={styles.leftTable}>Frequency</td>
            <td style={styles.rightTable}>
              {listing.job_time_length_quantity}
            </td>
          </tr>

          <tr>
            <td style={styles.leftTable}>Daily hours</td>
            <td style={styles.rightTable}>{offer.daily_hours} hours</td>
          </tr>

          <tr>
            <td style={styles.leftTable}>Rate per hour</td>
            <td style={styles.rightTable}>
              ${offer.initial_net_payment?.toFixed(2)}/h
            </td>
          </tr>
          <tr>
            <td style={styles.leftTable}>Languages</td>
            <td style={styles.rightTable}>
            <hr/>
            {offer.job_listing.languages.map(l=> 
                    <div key={l.id}>
                        <p className="m-0"  >{l.name}</p>
                    </div>
                  )}
            </td>
          </tr>
          <tr>
            <td style={styles.leftTable}>Skills</td>
            <td style={styles.rightTable}>
            <hr/>
            {offer.job_listing.skills.map(s=> 
                    <div key={s.id}>
                        <p className="m-0"  >{s.name}</p>
                    </div>
                  )}
            </td>
          </tr>
        {offer.match_status === "Accepted" && <>
          <tr>
            <td style={styles.leftTable}>Instructions</td>
            <td style={styles.rightTable}><hr/>{offer.instructions}</td>
          </tr>

          <tr className="mt-4">
            <td style={styles.leftTable}>Other Notes</td>
            <td style={styles.rightTable}><hr/>{offer.other_notes}</td>
          </tr>
          </>
          }
        </tbody>
      </table>

      <hr />

      {offer.match_status === "Declined" ? (
        <p className="text-red"> You declined this offer</p>
      ) : (
        <>
          {offer.match_status === "Accepted" ? (
            <>
            <Link style={{ margin: "10px", width: "10em" }} to={"/messages"}>
              Chat with <b> {listing.company.name} </b>{" "}
            </Link>
          
          <div className="mt-2">
            <button 
              disabled={!offer.contract_active || offer?.job_listing.job_type === 'Hire'} 
              onClick={e=>set_menu(e, 'stop')} 
              className='btn btn-danger' 
            >
            {'Stop Contract'}
            </button>
            { menu_state === 'stop' && <StopContractJS set_menu={set_menu_state} contract={offer}/>}

            <button 
              disabled={!offer.can_js_rate} 
              onClick={e=>set_menu(e, 'rate')} 
              className='btn btn-primary' 
              style={{
                backgroundColor: offer.can_js_rate ? 'rgba(36,154,250,1)' : 'rgba(36,154,250,0.5)'
              }}
            >
            {'Rate Company'}
            </button>
          </div>
          
          { menu_state === 'rate' && <RateCompany set_menu={set_menu_state} contract={offer}/>}
          </>
          ) : (
            <>
              <p className='invalid-feedback'>
                * If you will not respond within the deadline, the job will be
                offered to someone else
              </p>

              <div className='text-center mb-4 mt-2'>
                <button onClick={props?.handleDecline} className="btn btn-light w-50">
                  Decline
                </button>

                <button onClick={props?.handleAccept} className="btn btn-primary w-50">
                  Accept
                </button>
              </div>

              <Link  to={"/js/offers/"}>View later</Link>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OfferForm;
