import React, { useState, useEffect } from "react";
import { Spinner, Badge } from "react-bootstrap";
import matchService from "../../../services/match";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const styles = {
  leftTable: {
    textAlign: "left",
    padding: "2%",
  },
  rightTable: {
    textAlign: "right",
    padding: "2%",
  },
  table: {
    width: "70%",
    margin: "auto",
    marginTop: "2%",
  },
};

const ApprovedList = () => {
  const [suggestions, setSuggestions] = useState([]);

  const opening_id = useParams().id;
  const jobOpening = useSelector(({ jobOpenings }) =>
    jobOpenings.find((j) => j.id === Number(opening_id))
  );

  useEffect(() => {
    if (jobOpening) {
      matchService
        .listApprovedCandidates(jobOpening.id)
        .then((data) => {
          setSuggestions(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [jobOpening]);

  if (!jobOpening) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner animation="border" />
      </div>
    );
  }


  return (
    <div className="logged-in-container">
      <div className="candidates-div-list">
        <p className="candidates-div-list-header">
          All your approved candidates for <b>{jobOpening.position.name}</b>{" "}
        </p>

        {suggestions.length === 0 && !jobOpening.is_failed ? (
          <h4>We are looking to find the best candidate!</h4>
        ) : null}
        {jobOpening.is_failed && 
          <h4>This job listing failed to find the candidates within the time period!</h4>
        }

        {suggestions.length > 0 && 
        <table className='custom-table' style={styles.table}>
          <tbody>
            <tr style={{fontWeight:700}}>
              <td>Name</td>
              <td>Your Decision</td>
              <td>Contract Status</td>
              <td>Start Date</td>
            </tr>

            {suggestions.map((approved_candidate) => (
              <tr className='align-left-row' key={approved_candidate.id}>
                <td style={styles.leftTable}>
                  {approved_candidate.match_status === "Declined" ? (
                    approved_candidate.interested_position.job_seeker.first_name
                  ) : (
                    <Link
                      to={`/jp/opening/${jobOpening.id}/approved/${approved_candidate.id}`}
                    >
                      {
                        approved_candidate.interested_position.job_seeker
                          .first_name
                      }
                    </Link>
                  )}
                </td>

                <td style={styles.rightTable}>
                  {approved_candidate.match_status === "Declined" ? (
                    <Badge style={{ fontSize: "14px" }} variant="danger">
                      {" "}
                      Declined
                    </Badge>
                  ) : approved_candidate.match_status === "Accepted" ? (
                    <Badge style={{ fontSize: "14px" }} variant="success">
                      {" "}
                      Approved
                    </Badge>
                  ) : (
                    <Badge style={{ fontSize: "14px" }} variant="info">
                      {" "}
                      Pending
                    </Badge>
                  )}
                </td>

                <td style={styles.rightTable}>
                  {approved_candidate.contract_active ? 
                    <Badge style={{ fontSize: "14px" }} variant="success">
                      {" "}
                      Active
                    </Badge>
                  :
                    <Badge style={{ fontSize: "14px" }} variant="danger">
                      {" "}
                     {approved_candidate.match_status === "Accepted" && (approved_candidate.resigned ? 'Resigned' : 'Stopped')}
                    </Badge>
                  }
                </td>

                <td style={styles.rightTable}>
                  {/* <Link  to={`/jp/opening/${.id}/filtered/${sug.id}`} style={{marginLeft:'40px'}} >view</Link> */}
                  {new Intl.DateTimeFormat("en-US").format(
                    Date.parse(approved_candidate.match_time)
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>}
      </div>
    </div>
  );
};

export default ApprovedList;
