import React from "react";
import { useSelector } from "react-redux";
import { Navbar, Nav, DropdownButton, Dropdown } from "react-bootstrap";
import logo from "../../icons/logo.svg";
import { Link } from "react-router-dom";

const styles = {
  logout: {
    color: "red",
    paddingLeft: "30%",
    paddingTop: "5%",
  },
  offers: {
    paddingLeft: "30%",
  },
};

const Navigation = ({ user, handleLogout }) => {
  const company = useSelector(({ company }) => company);

  if (
    window.location.pathname === "/login" ||
    window.location.pathname === "/jp/register" ||
    window.location.pathname.includes("/js/register") ||
    window.location.pathname === "/js/createprofile" ||
    window.location.pathname === "/"
  )
    return null;

  // If logged out no navbar
  if (!user) {
    return null;
  }
  // If JP is registering company
  if (!company && user.is_job_provider) {
    return (
      <nav className="navbar navbar-expand bg-primary">
        <span className="navbar-brand" >
        <img
          className="w-75"
          alt="raihire logo"
          src={logo}
        /></span>
        <div id="nav" className="mr-auto navbar-nav"></div>

        <a className="nav-link text-white" role="button" onClick={handleLogout}>Sign Out</a>
      </nav>
    );
  }

  // If Job Seeker
  if (user.is_job_seeker)
    return (
      <nav className="color-nav bg-primary navbar navbar-expand" >
        <img
          style={{ paddingLeft: 10, marginRight: 60, width: 120 }}
          alt="raihire logo"
          src={logo}
        />
        <a className="nav-link text-white" style={{fontWeight: 600}} target="_blank" href="https://www.youtube.com/watch?v=jOUi6s9KlXQ&t=73s">
        Click here to see how to use the platform.
            </a>
        <div className="mr-auto nav">
          {window.innerWidth < 800 ? (

            <DropdownButton
            drop="down"
            key="drop"
            variant="outline-secondary"
            title={`Menu`}
            >
            <Link className="dropdown-item mt-2" to="/js/mypositions">
              Home
            </Link>
            <Link className="dropdown-item mt-2" to="/messages">
              Messages
            </Link>
            <Link className="dropdown-item mt-2" to="/js/myprofile">
              Account
            </Link>
            <Link className="dropdown-item mt-2" to="/js/offers">
              Offers
            </Link>
            <Link className="text-danger dropdown-item mt-2" to="" onClick={handleLogout}>
              Logout
            </Link>
            </DropdownButton>
          ) : null}
        </div>
        {window.innerWidth > 800 ? (
    
          <DropdownButton
            className="dropdown"
            drop="left"
            key="drop"
            variant="outline-secondary"
            style={{ marginRight: "90px" }}
            title={user.first_name}
          >
            <Dropdown.Item href="/js/edit/profile"> Profile </Dropdown.Item>
            <Dropdown.Item href="/" onClick={handleLogout}>
              Logout
            </Dropdown.Item>
        </DropdownButton>
        ) : null}
      </nav>
    );
  // If Job Provider
  else if (company && user.is_job_provider)
    return (
      <nav className="color-nav bg-primary navbar navbar-expand " >
        <img
          style={{ paddingLeft: 10, marginRight: 60, width: 120 }}
          alt="raihire logo"
          src={logo}
        />
          <a style={{fontWeight: 600}} target="_blank" href="https://www.youtube.com/watch?v=dQ1wjB4m1rA&t=6s">
        Click here to see how to use the platform.
            </a>
        <div id="nav" className="mr-auto nav">
          {window.innerWidth < 800 ? (
              <DropdownButton
                drop="down"
                key="drop"
                variant="outline-secondary"
                title={`Menu`}
              >
                <Link className="dropdown-item mt-2" to="/jp/jobs">
                  Home
                </Link>
                <Link className="dropdown-item mt-2" to="/messages">
                  Messages
                </Link>
                <Link className="dropdown-item mt-2" to="/jp/invoices">
                  Invoices
                </Link>
                <Link className="dropdown-item mt-2" to="/jp">
                  Account
                </Link>
                <Link className="dropdown-item mt-2" to="/jp/previewcompany">
                  Company
                </Link>
                <Link className="text-danger dropdown-item mt-2" to="" onClick={handleLogout}>
                  Logout
                </Link>
              </DropdownButton>
          ) : null}
        </div>

        {window.innerWidth > 800
          ? [
              <p key={1} style={{ color: "white", margin: "10px" }}>
                {user.first_name}
              </p>,

              <DropdownButton
                className="dropdown"
                drop="left"
                key="drop"
                variant="outline-secondary"
                style={{ marginRight: "90px" }}
                title={` `}
              >
                <Dropdown.Item href="/jp/previewcompany">Company</Dropdown.Item>
                <Dropdown.Item href="/" onClick={handleLogout}>
                  Logout
                </Dropdown.Item>
              </DropdownButton>,
            ]
          : null}
      </nav>
    );
  else return null;
};

export default Navigation;
