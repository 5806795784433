import React from 'react'

import { useSelector } from 'react-redux'


const SidePhoto = () => {

    const user = useSelector( ({user}) => user)
    if (window.location.pathname.includes('/reference/rate/'))return null
    if (window.location.pathname.includes('/registeredsuccess'))return null
    if(!user && window.location.pathname !== '/')

    return (
        <div style={{width:'30%'}}>
            <img className='sidephoto' src={'https://rapihirepublic.s3.ca-central-1.amazonaws.com/webpage/REGISTER.jpg'} alt='Rapihire sidephoto' />
        </div>
    )
    else return null

}

export default SidePhoto;