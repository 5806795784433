import Visibility from '@material-ui/icons/Visibility';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import userService from '../../services/user'
import NotesModal from '../Shared/NotesModal';


const ApprovalHistoryJP = () => {

    const user_id = useSelector( ({user}) => user.id)

    const [histories, set_histories] = useState([])
    const [curr_notes, set_curr_notes] = useState([])
    const [show_notes, set_show_notes] = useState(false)

    useEffect(()=> {

        if(user_id){
            userService.get_approval_history(user_id)
                .then(data => set_histories(data))
                .catch(err => console.log(err))
        }
    },[user_id])

    
    const handle_view = (id) => {
        set_curr_notes(histories.find(h=>h.id===id).note)
        set_show_notes(true)
    }

    return (
        <div className='logged-in-container' >

        <p className='font-weight-bold text-secondary h5 mt-4'>Approval history</p>
        <div className="custom-table-div">  
          <table className="custom-table">
            <thead>
              <tr>
                <td>
                  <b>Date</b>
                </td>
                <td>
                  <b>Action</b>
                </td>
                <td>
                  <b>Recruiter</b>
                </td>
                <td>
                  <b>Notes</b>
                </td>
              </tr>
            </thead>

            <tbody>
                {histories.map(history => (
                    <tr key={history.id}>
                        <td>{moment(history.date).format('lll')}</td>
                        <td>{history.decision}</td>
                        <td>{history.staff ? history.staff : '-'}</td>
                        <td>
                          {history.staff ? <Visibility  onClick={()=>handle_view(history.id)} style={{cursor:'pointer'}}  /> : '-'}
                        </td>
                    </tr>
                ))}
            </tbody>
          </table>
        </div>

      
        <NotesModal 
            ok={(e)=>{
                e.preventDefault();
                set_show_notes(false);
            }} 
            notes={curr_notes}
            show={show_notes}
        />

        </div>
    )
}

export default ApprovalHistoryJP;