import React from 'react'

const styles = {

    label:{
        margin:'20px',
        fontSize: '16px',
        color: '#445566',
        marginBottom:'5px'
    },
    value:{
        color: '#839eaf',
        fontSize: '16px',
        lineHeight: 1.5,
        marginLeft:'20px',
    },
    delete_label:{
        color:'#ee5566',
        fontSize: '16px',
        marginLeft:'20px'
    }
}



const Preferences = () => {

    return (
        <div className='home-tab' >            
           
                <div> 
                    <p style={styles.label}>Alerts &amp; Notifications</p> 
                    <p style={styles.value}>Emails, app notifications</p>
                </div>
                <hr/>
                <div> 
                    <p style={styles.label}>Language</p> 
                    <p style={styles.value}>English</p>
                </div>
                
        </div>
    )
}

export default Preferences