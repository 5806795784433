import React from 'react'
import { useField } from "formik";
import Select from "react-select";
import InfoIcon from '@material-ui/icons/Info'


const DropDown = ({label,...props}) => {

    const [field, meta, helpers] = useField(props);


    const { setValue } = helpers;

  return (
    <div className='text_input__group' >
      <p className='text-input-label'>
        {label} 
        {props.info ?  <InfoIcon onClick={()=>alert(props.info_text)} style={{float:'right', cursor:'pointer'}}/>: null }
      </p>
      <Select
        options={props.options}
        name={field.name}
        onChange={(option) => setValue({name:option.label,id: option.value})}
        {...props}
      />
    {meta.touched && meta.error ? (<div className='invalid-feedback'>{meta.error}</div> ): null}
    </div>
  );
};

export default DropDown;