import React  from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { update_user } from '../../../../reducers/userReducer';
import userService from '../../../../services/user'


const styles = {

    label:{
        margin:'20px',
        fontSize: '16px',
        color: '#445566',
        marginBottom:'5px'
    },
    value:{
        color: '#839eaf',
        fontSize: '16px',
        lineHeight: 1.5,
        marginLeft:'20px',
    },
    delete_label:{
        color:'#ee5566', 
        fontSize: '16px',
        marginLeft:'20px'
    }
}


const PreferencesTab = () => {

    const dispatch = useDispatch();
    const history = useHistory()
    const user = useSelector(({ user }) => user);

    const onUnsubscribe = async (e) => {
        e.preventDefault()
        const ok = window.confirm("Are you sure?");
        if (ok){
        try{
          await userService.unsubscribe_emails()
         dispatch(update_user({}));
        }
        catch(err){
          console.log(err);
          alert("Problem updating email preferences! Please try again later!")
        }
        }   
    }
    
    return (
            <form className='home-tab' >

                <hr/>

                <div> 
                    <p style={styles.label}>Alerts and notifications</p> 
                    <p style={styles.value}>Emails, app notifications</p>
                    <div> 
          <p style={styles.delete_label}>
              {user?.receive_emails? 
              
              <b onClick={onUnsubscribe} style={{ cursor:'pointer'}}>
                  Unsubscribe from emails
              </b>
              :
              <b onClick={onUnsubscribe} style={{ cursor:'pointer', color:'green'}}>
                  Subscribe to emails
              </b>
          }
          </p>
        </div>
                </div>
                <hr/>
    
                <div> 
                    <p style={styles.label}>Language</p> 
                    <p style={styles.value}>Englsh</p>
                </div>
          
            </form>
    )
}

export default PreferencesTab
