import { Link } from 'react-router-dom';
import React from 'react'
import { logged_in } from '../../theme';


const PageNotFound = () => {
    return(
        <div style={logged_in.container}>
            <div style={{textAlign:'center'}}>
                <h1> 404</h1>
                <h2> Page that you requested was not found!</h2>
                <Link to='/login'>
                    Go home!
                </Link>
            </div>
        </div>
    )
}

export default PageNotFound;