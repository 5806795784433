const  is_number = (char) => !isNaN(parseInt(char));

function hasWhiteSpace(s) {
  return /\s/g.test(s);
}

export const validate_canada_zip = zip => {

    return (zip.length===7 
        && hasWhiteSpace(zip[3])
        && !is_number(zip[0]) && is_number(zip[1])
        && !is_number(zip[2]) && is_number(zip[4]) 
        && !is_number(zip[5]) && is_number(zip[6])) || 
  
        (zip.length===6
          && !is_number(zip[0]) && is_number(zip[1])
          && !is_number(zip[2]) && is_number(zip[3]) 
          && !is_number(zip[4]) && is_number(zip[5])
        ) 
}

export const validate_us_zip = zip => {
    // console.log();
    return (
    zip.length === 5 && is_number(zip[0]) && is_number(zip[1])
    && is_number(zip[2])  && is_number(zip[3])  && is_number(zip[4]))
}