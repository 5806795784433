
import React from 'react';
import InputRange from 'react-input-range';
import styled from 'styled-components';
import "react-input-range/lib/css/index.css"

const RangeSliderContainer = styled.div`
  .input-range__track--active,
  .input-range__slider {
    background: ${props => props.color};
    border-color: ${props => props.color};
  }
`;

const RangeSlider = (props) => (
  <RangeSliderContainer color="#249afa">
    <InputRange 
        minValue={props.minValue}
        maxValue={props.maxValue}
        onChange={props.onChange}
        step={props.step}
        value={props.value}
        formatLabel={props.formatLabel}
    />
  </RangeSliderContainer>
);

export default RangeSlider;