import React, { useEffect, useState } from 'react';
import matchService from '../../../../services/match'
import { useDispatch, useSelector } from 'react-redux';
import { validate_canada_zip, validate_us_zip } from '../../../../validations/GenralValidations';
import Select from 'react-select';
import { set_loading_false, set_loading_true } from '../../../../reducers/loadingReducer';
import { errorAlert, successAlert } from '../../../../reducers/alertReducer';

const ExtendContractJP = ({contract, set_menu}) => {
    const dispatch = useDispatch()
    const [end_date, set_end_date] = useState(contract?.end_date)
    const [rate_per_hour, set_rate_per_hour] = useState((contract?.initial_net_payment / ( 1 - contract?.job_listing.platform_commision)).toFixed(2))
    const [daily_hours, set_daily_hours] = useState(contract.daily_hours)
    
    const general = useSelector( ({general}) => general)  

    const [countries, set_countries] = useState([])
    const [cities, set_cities] = useState([])
    const [provinces, set_provinces] = useState([])

    const [address, set_address] = useState("")
    const [zip_code, set_zip_code] = useState(contract.zip_code)

    const [city, set_city] = useState({'id':null,'name':null})
    const [country, set_country] = useState({'id':null,'name':null})
    const [province, set_province] = useState({'id':null,'name':null})
    const [zip_error, set_zip_error] = useState(false);

    const handle_extend = async (e) => {
        e.preventDefault()
        set_zip_error(
            country.name === 'USA'
              ? !validate_us_zip(zip_code)
              : !validate_canada_zip(zip_code),
        );
        if (((country.name === 'USA' && validate_us_zip(zip_code)) || (country.name === 'Canada' && validate_canada_zip(zip_code)))){

            const payload = {
                end_date,
                rate_per_hour,
                daily_hours,
                contract_id: contract?.id,
                city_id: city.id,
                province_id: province?.id,
                country_id: country?.id,
                zip_code,
                address
            }

            dispatch(set_loading_true())
            try{
                await matchService.extend_contract_jp(payload);
                dispatch(set_loading_false())
                dispatch(successAlert('Successfully sent the extension proposlal!'))
                setTimeout(() => {
                    dispatch(successAlert(''))
                }, 4000);
                set_menu('')
            }
            catch(err){
                dispatch(set_loading_false())
                dispatch(errorAlert('Problem sending the extension proposlal!'))
            }
        }
    }

    const validate_zip = (zip, country) => {
        return (country.name === 'USA'
                ? !validate_us_zip(zip)
                : !validate_canada_zip(zip) )
    }

    const handleCountry = (choice) => {
        set_country({'id':choice.value,'name':choice.label})
    }
    
    const handleCity = (choice) => {
        set_city({'id':choice.value,'name':choice.label})
    }
    
    const handeProvince = (choice) => {
        set_province({'id':choice.value,'name':choice.label})
    }

    useEffect(() =>{
        
        if(general.length!==0){

            const countryArray = general.countries.map(pos => {
                return {value:pos.id,label:pos.name}
            })
            set_countries(countryArray)

            const cityArray = general.cities.map(pos => {
                return {value:pos.id,label:pos.name, province: pos.province}
            })
            set_cities(cityArray)

            const provinceArray = general.provinces.map(pos => {
                return {value:pos.id,label:pos.name, country: pos.country}
            })
            set_provinces(provinceArray)

            set_address(contract?.address)
            set_city(contract?.city)
            set_province(contract?.city.province)
            set_country(contract?.city.province.country)

        }
        
    },[general, contract])


    

    return (
        <form onSubmit={handle_extend} style={{backgroundColor:'rgba(0,128,12,0.3)'}} className='resume-item-page-form'>
            <label className='text-input-label'>Date to extend</label><br/>
            <input  
              value={end_date}
              onChange={e=>set_end_date(e.target.value)}
              type='date'
              className='text-input normal-size-input'
              min={contract?.end_date}
            /><br/><br/>

            <label className='text-input-label'>Rate per hour</label><br/>
            <input 
              value={rate_per_hour}
              className='text-input normal-size-input'
              onChange={e=>set_rate_per_hour(e.target.value)}
              type='number'
              min='1'
              max='1001'
            /><br/><br/>

            <label className='text-input-label'>Daily hours</label><br/>
            <input 
              value={daily_hours}
              onChange={e=>set_daily_hours(e.target.value)}
              type='number'
              min='1'
              max='24'
              className='text-input normal-size-input'
            /><br/><br/>

            <label className='text-input-label'>Country</label><br/>
            <Select 
              onChange={handleCountry} 
              options={countries}
              value={countries.find(pos => pos.value === country?.id)}
            /><br/>

            <label className='text-input-label'>State/Province</label><br/>
            <Select
              value={provinces.find(pos => pos.value === province?.id)}
              onChange={handeProvince} 
              options={provinces.filter(p => p.country?.id === country?.id)}
            /><br/>
            
            <label className='text-input-label'>City</label><br/>
            <Select
              value={cities.find(pos => pos.value === city?.id)}
              onChange={handleCity} 
              options={cities.filter(c => c.province?.id === province?.id)}
            /><br/>

            <label className='text-input-label'>Address</label><br/>
            <input 
              value={address}
              onChange={e=>set_address(e.target.value)}
              type='text'
              className='text-input normal-size-input'
              maxLength="40"
            /><br/><br/>

            <label className='text-input-label'>ZIP</label><br/>
            <input 
              value={zip_code}
              onChange={e=>set_zip_code(e.target.value)}
              type='text'
              className='text-input normal-size-input'
            />
            <br/>
            { zip_error && validate_zip(zip_code,country) && <p style={{textAlign:'right'}} className='invalid-feedback'>Invalid ZIP for {country.name}</p>}

            <button type='submit' className='btn btn-success w-100 mt-4'>Confirm</button>
        </form>
    )
}


export default ExtendContractJP;