import React, { useEffect, useState } from 'react'
// import ReactStars from 'react-rating'
import ReactStars from "react-rating-stars-component"
import { useParams } from 'react-router'
import './rating.css'
import generalService from '../../services/general'
import { useLocation } from 'react-router-dom'

const RateExperience = () => {

    const [reliability, set_reliability] = useState(3)
    const [quality, set_quality] = useState(3)
    const [trustworthy, set_trustworthy] = useState(3)

    const [experience, set_experience] = useState(null)
    const experience_id = useParams().experience_id
    const search = useLocation().search;
    const js_name = new URLSearchParams(search).get('js');
    const [rated, set_rated] = useState(false)

    useEffect(()=>{
        generalService.get_experience_to_rate(experience_id)
            .then(data=>{
                set_experience(data)

            })
            .catch(error=>{
                console.log('Error');
            })
    },[experience_id])

    const handle_rate = async (e) => {
        e.preventDefault()
        const payload = {
            experience_id,
            reliability,
            quality,
            trustworthy,
        }
        try{
            const confirm = window.confirm('Confirm rating?')
            if(confirm){
            await generalService.reference_rate_job_seeker(payload);
            set_rated(true)}
        }
        catch (error){
            console.log('');
        }
    }
    if (!experience?.position){
        return (
            <div className='rating-div'>
                <div className='rating-div-container'>
                <p className='rating-div-header'>
                    Invalid request
                </p>
                </div>
            </div>)
    }

    if (rated) {
        return (
            <div className='rating-div'  >
                <div className='rating-div-container'>
                <p className='rating-div-header'>
                    Thank your for your rating!
                    That will help us find the best fit for our candidates!
                </p>
                </div>
            </div>
        )
    }

    return (

        <div className='rating-div'  >

            <div className='rating-div-container'>
            <p className='rating-div-header'>
                Hello {experience.contact_name}
            </p>

            <p> We kindly ask you to rate your ex-collague <b>{js_name}</b> for the following experience:</p>
            <p> <b> {experience.position.name} at the company {experience.company_name} </b></p>

            <table className='rating-div-table'>
                <tbody>

                    <tr>
                        <td >Reliability</td>
                        <td className='rate-td'>
                            <ReactStars
                                size = {20}
                                value={reliability}
                                onChange={(rate) => set_reliability(rate)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Quality and Efficency of Work</td>
                        <td className='rate-td'>
                            <ReactStars
                                size = {20}
                                value={quality}
                                onChange={(rate)=> set_quality(rate)}
                            />
                        </td>

                    </tr>

                    <tr>
                        <td>Trustworthy</td>
                        <td className='rate-td'>
                            <ReactStars
                                size = {20}
                                value={trustworthy}
                                onChange={(rate)=>set_trustworthy(rate)}
                            />

                        </td>
                    </tr>

                </tbody>

            </table>

            <div style={{textAlign:'center'}}>
                <button onClick={handle_rate} className='rating-div-button'>Submit Rating</button>
            </div>
            </div>
        </div>
    )
}

export default RateExperience;
