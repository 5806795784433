import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import RangeSlider from "../../ReusableComponents/RangeSlider";
import jobService from "../../../services/jobs";
import { get_all_job_openings } from "../../../reducers/jobOpeningReducer";
import { errorAlert, successAlert } from "../../../reducers/alertReducer";

const AddSkill = ({ set_show, listing_id, required, old_skills }) => {
  const dispatch = useDispatch();
  const [skills, set_skills] = useState([]);
  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const skills_array = general.skills.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      set_skills(skills_array);
    }
  }, [general]);
  const handleClose = () => {
    set_show(false);
  };

  const [curr_skill, set_curr_skill] = useState({});

  const handleSkill = (choice) => {
    const nxt_skill = choice.value;
    set_curr_skill({
      skill_id: nxt_skill,
      listing_id,
      years_of_experience: 2,
      importance: 2,
      required,
    });
  };

  const handle_years_of_experience = (value) => {
    set_curr_skill({
      ...curr_skill,
      years_of_experience: value,
    });
  };

  const handle_importance = (value) => {
    set_curr_skill({
      ...curr_skill,
      importance: value,
    });
  };

  const handle_add = async (e) => {
    e.preventDefault();

    try {
      await jobService.addListingSkill(curr_skill);
      dispatch(get_all_job_openings());
      dispatch(successAlert("Successfully added skill"));
      setTimeout(() => {
        dispatch(successAlert());
      }, 2000);

      set_show(false);
    } catch (err) {
      dispatch(errorAlert("Problem adding skill! Please try again later!"));
    }
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Add Listing Skill </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          onChange={(choice) => handleSkill(choice)}
          options={skills.filter((s) => !old_skills.includes(s.value))}
        />
        <br />

        {curr_skill?.skill_id && (
          <div>
            <label style={{ fontSize: 14, marginLeft: 15, padding: 10 }}>
              Years of Experience
            </label>

            <div style={{ width: 300, margin: "auto" }}>
              <RangeSlider
                maxValue={20}
                minValue={0}
                step={1}
                value={curr_skill.years_of_experience}
                onChange={(value) => handle_years_of_experience(value)}
                formatLabel={(value) => `${value} Years`}
              />
            </div>

            <br />
            <label style={{ fontSize: 14, marginLeft: 15, padding: 10 }}>
              Importance
            </label>

            <div style={{ width: 300, margin: "auto" }}>
              <RangeSlider
                maxValue={20}
                minValue={0}
                step={1}
                value={curr_skill.importance}
                onChange={(value) => handle_importance(value)}
                formatLabel={(value) => `${value}`}
              />
            </div>
            <br />
            <button onClick={handle_add} className="btn btn-success w-100 mt-4">
              Add
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddSkill;
