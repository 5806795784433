import { Modal } from "react-bootstrap";
import React from "react";
import { posting_preview } from "../../../theme";
import EditSingleSkill from "./EditSingleSkill";

const EditSkills = ({ set_show, required_skills, optional_skills }) => {
  const handleClose = () => {
    set_show(false);
  };

  if (required_skills)
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title> Edit Listing Skills </Modal.Title> */}
          <Modal.Title> Edit Listing Experiences </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p style={{ ...posting_preview.td, fontSize: 24 }}>Required Skills</p> */}
          <p style={{ ...posting_preview.td, fontSize: 24 }}>Required Experiences</p>
          {required_skills.map((skill) => (
            <EditSingleSkill key={skill.id} skill={skill} />
          ))}

          <hr />
          {/* <p style={{ ...posting_preview.td, fontSize: 24 }}>Optional Skills</p> */}
          <p style={{ ...posting_preview.td, fontSize: 24 }}>Skill</p>
          {optional_skills.map((skill) => (
            <EditSingleSkill key={skill.id} skill={skill} />
          ))}
        </Modal.Body>
      </Modal>
    );
  else return "loading...";
};

export default EditSkills;
