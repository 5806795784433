import React, { useEffect, useState } from "react";

import ProfileTab from "./ManageProfile/AccountParts/ProfileTab";
import DocumentsTab from "./ManageProfile/AccountParts/DocumentsTab";
import PreferencesTab from "./ManageProfile/AccountParts/PreferencesTab";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OfferTabs from "./Offers/OfferTabs";
import {
  set_loading_false,
  set_loading_true,
} from "../../reducers/loadingReducer";
import { ProfileDeclinedJS, ProfileRejectedJS, WaitForApprovalJS } from "../Shared/ApprovalMessage";
import ExtensionOfferTabs from "./Offers/Extension/ExtensionOfferTabs";
import Notifications from "../Shared/Notification/Notifications";

const AccountJS = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [section, set_section] = useState("Profile");
  const profile_completed = useSelector(
    ({ job_seeker_info }) => job_seeker_info.completed_profile
  );
  const is_approved = useSelector(
    ({ job_seeker_info }) => job_seeker_info.approved_by_system
  );
  const user_id = useSelector(
    ({ job_seeker_info }) => job_seeker_info.user.id
  );

  useEffect(() => {
    dispatch(set_loading_true());
    if (profile_completed === false) {
      dispatch(set_loading_false());
      history.push("/js/createprofile");
    }
    dispatch(set_loading_false());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_completed]);

  return (
    <div className="logged-in-container">
      {is_approved === "Pending" && <WaitForApprovalJS />}
      {is_approved === "Declined" && <ProfileDeclinedJS user_id={user_id} />}
      {is_approved === "Rejected" && <ProfileRejectedJS user_id = {user_id} />}
      
      <OfferTabs />
      <ExtensionOfferTabs/>
      <Notifications/>

      <br />
      <div className="home-tab-nav">
        {/* Profile */}
        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Profile");
          }}
        >
          Profile
          <Link
            style={{ fontSize: "14px", float: "right" }}
            to="/js/previewprofile"
          >
            {" "}
            Edit
          </Link>
        </p>

        <hr />

        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Preferences");
          }}
        >
          Preferences
        </p>

        <hr />

        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Documents");
          }}
        >
          Documents
        </p>

        <hr />

        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            history.push('/approvalhistory')
          }}
        >
          Approval history
        </p>

        
      </div>

      {section === "Profile" ? <ProfileTab /> : null}
      {section === "Preferences" ? <PreferencesTab /> : null}
      {section === "Documents" ? <DocumentsTab /> : null}
    </div>
  );
};

export default AccountJS;
