import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from 'react-bootstrap'

const StartCompany = () => {
    return (
        <div style={{textAlign:'center', padding:'100px'}}>
            <h2>Hello, lets get started by first adding a company</h2>

         <Link to="/jp/newcompany"><Button vairant='primary' style={{margin:'20px'}} >Add company</Button></Link>
         </div>
    )
}

export default StartCompany;