import React, { useState } from 'react';
import companyService from '../../../../services/company'
import { useDispatch } from 'react-redux';
import { set_loading_false, set_loading_true } from '../../../../reducers/loadingReducer';
import { errorAlert, successAlert } from '../../../../reducers/alertReducer';
import { useHistory } from 'react-router-dom';
import Rating from 'react-rating'


const RateCompany = ({contract, set_menu}) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [rate_score, set_rate_score] = useState(3)
    const handle_rate = async (e) => {
        e.preventDefault()

        const payload = {
            contract_id: contract.id,
            company_id: contract.job_listing.company.id,
            rate: rate_score
        }

        dispatch(set_loading_true())
        try{

            await companyService.rate_company_js(payload)

            dispatch(set_loading_false())
            dispatch(successAlert('Successfully rated!'))
            setTimeout(() => {
                dispatch(successAlert(''))
            }, 4000);

            set_menu('')
            history.push('/')
        }
        catch(err){
            dispatch(set_loading_false())
            dispatch(errorAlert('You have already rated this company!'))
        }
    }

    return (
        <div style={{backgroundColor:'rgba(36,154,250,0.4)'}} className='resume-item-page-form'>

            <p>Please rate company on scale from 1 to 5</p>
            <Rating 
                initialRating={rate_score}
                onChange={(rate) => set_rate_score(rate)}  
            />

            <br/>
            <button 
              onClick={handle_rate} 
              style={{backgroundColor: 'rgba(36,154,250,1)'}} 
              className='btn btn-success w-50 mt-2'
            >Rate
            </button>

        </div>
    )
}

export default RateCompany;