import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch , useSelector } from 'react-redux'
import { errorAlert, successAlert } from '../../reducers/alertReducer'
import { get_companies } from '../../reducers/companyReducer'
import { set_loading_false, set_loading_true } from '../../reducers/loadingReducer'
import { update_user } from '../../reducers/userReducer'
import userService from '../../services/user'
import { Link } from "react-router-dom";


const styles = {
    wait_div:{
        borderRadius:8,
        backgroundColor:'red',
        border:'none',
        textAlign:'center',
        width:'70%',
        margin:'auto',
        color:'white',
        fontWeight:700,
        padding:20
    },
    reasons_div: {
        backgroundColor: 'rgb(255, 117, 117)',
        textAlign: 'left',
        borderRadius:8,
        padding:3
    }
}

export const WaitForApprovalJP = () => {
  
  const number_of_jobs_posted = useSelector(({jobOpenings}) => 
    jobOpenings.length
  )

    return (
        <>
        <div style={styles.wait_div}>
        <p> To use our services, your profile needs to be approved by our back office. Meanwhile, you can still
            add job listings or/and modify your profile. </p>

        {number_of_jobs_posted < 10 ? (
          <Link className="job-listings-account-page-button1" to="/jp/newopening">
            <button className='btn btn-primary w-50 mt-3'>Post new job</button>
          </Link>
        ) : (
          <div className="job-listings-account-page-button1">
              <button disabled className='btn btn-primary w-50 mt-3'>Max of 10 jobs posted</button>
          </div>
        )}
        </div>
        <br/>
        </>
    )
}

export const WaitForApprovalJS = () => {
    return (
        <>
        <div style={styles.wait_div}>
            To use our services, your profile needs to be approved by our back office. Meanwhile, you can still
            add interested positions or/and modify your profile.
            <br/>
            <Link className="job-listings-account-page-button1" to="/js/addposition">
            <button className='btn btn-primary w-75 mt-3'>Add interested position</button>
          </Link>
        </div>
        <br/>
        </>
    )
}

export const ProfileRejectedJS = () => {
    return (
        <>
        <div style={{...styles.wait_div, backgroundColor:'black'}}>
        We want to let you know that your account was rejected. If you think that this is done by
        error, please contact us at info@rapihire.com.
            <br/>
           
        </div>
        <br/>
        </>
    )
}

export const ProfileDeclinedJS = ({user_id}) => {
    const dispatch = useDispatch()
    const [reasons, set_reasons] = useState([])
    const [show_reasons, set_show_reasons] = useState(false)

    const render_redirect = title => {

        if (title.includes('experience')){
            window.open('/js/edit/workexperience')
        }
        else if (title.includes('Education')){
            window.open('/js/edit/education')
        }
        else if (title.includes('License')){
            window.open('/js/edit/licences')
        }
        else if (title.includes('Skills')){
            window.open('/js/previewprofile')
        }
        else if (title.includes('Certification')){
            window.open('/js/edit/certifications')
        }
        else{
            window.open('/js/edit/profile')
        }
    }

    const render_reasons = () => {
        return (
            <div style={styles.reasons_div}>
            <li style={{listStyleType:'none'}}> Please click each of the details and make the updates</li>
            <ul>
                {!reasons.length && 
                <li>The recruiter didn't leave any note!</li>
                }
                {reasons.map((reason, idx) => 
                    <li key={idx}>
                        <b style={{cursor:'pointer'}} onClick={()=>render_redirect(reason.title)}>{reason.title}</b>
                        <br/>
                        <span style={{fontSize:14}} > - {reason.reason.length ? reason.reason : 'No notes' }</span>
                    </li>
                )}
                <li style={{listStyleType:'none'}}>
                    <button onClick={handle_resubmit} className='btn btn-primary w-50 mt-3'>
                        Re-submit
                    </button> <br/>
                </li>
            </ul>
            <KeyboardArrowUpOutlined onClick={()=>set_show_reasons(false)} style={{cursor:'pointer'}} fontSize='large'/>
            </div>
        )
    }

    const handle_resubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(set_loading_true())
            await userService.resubmit_profile_js(user_id)

            dispatch(successAlert('Successfully submitted profile for review!'))
            setTimeout(()=> {
            dispatch(successAlert(''))
            }, 4000)

            dispatch(set_loading_false())
            dispatch(update_user({}))
        }catch (err){
            dispatch(errorAlert('Problem re submitting profile! Please try again later!'))
            dispatch(set_loading_false())
        }
    }

    useEffect(()=> {
        if (user_id){
            userService.get_reasons_declined_js(user_id)
                .then(data => set_reasons(data['reasons_declined']))
                .catch(err => console.log(err))
        }
    },[user_id])


    return (
        <>
        <div style={styles.wait_div}>
            Your profile has been declined by our back office.
            Please update your information and click the button for approval.
            <br/>
            
            {show_reasons 
            ? <span style={{cursor:'pointer'}} onClick={()=>set_show_reasons(false)} > Hide details <KeyboardArrowUpOutlined  fontSize='large'/> </span>
            : <span style={{cursor:'pointer'}} onClick={()=>set_show_reasons(true)} > View details <KeyboardArrowDownOutlined fontSize='large'/> </span>
            }
            <br/>
            { show_reasons && render_reasons()}
        </div>
        <br/>
        </>
    )
}



export const ProfileDeclinedJP = ({user_id}) => {
    const dispatch = useDispatch()
    const [reasons, set_reasons] = useState([])
    const [show_reasons, set_show_reasons] = useState(false)
    const [paymentMethod, set_paymentMethod] = useState(false)
    
    
    const render_ridirect = (reasons,a,b) => {
        if(reasons.title === "Payment Method"){ 
            set_paymentMethod(true)
        }else{
            set_paymentMethod(false)
        }
 
    }
    

    const render_reasons = () => {
        return (
            <div style={styles.reasons_div}>
            
            <ul>
                <li style={{listStyleType:'none'}}> Please click each of the details and make the updates</li>
                <br/>
                {!reasons.length && 
                <li>The recruiter didn't leave any note!</li>
                }
                {reasons.map((reason, idx) => 
                    <li key={idx}>
                    <Link target='_blank' className="text-white" to={paymentMethod ? "/jp" :"/jp/managecompany"  } onClick={()=>render_ridirect(reason)}>
                            <b>{reason.title}</b>
                            <br/>
                            <span style={{fontSize:14}} > - {reason.reason.length ? reason.reason : 'No notes' }</span>
                    </Link>
                    </li>
                )}
                <li style={{listStyleType:'none'}}>
                    <button onClick={handle_resubmit} className='btn btn-primary w-50 mt-3'>
                        Re-submit
                    </button> 
                </li>
            </ul>
            <KeyboardArrowUpOutlined onClick={()=>set_show_reasons(false)} style={{cursor:'pointer'}} fontSize='large'/>
            </div>
        )
    }

    const handle_resubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(set_loading_true())
            await userService.resubmit_profile_jp(user_id)

            dispatch(successAlert('Successfully submitted profile for review!'))
            setTimeout(()=> {
            dispatch(successAlert(''))
            }, 4000)

            dispatch(set_loading_false())
            dispatch(get_companies({}))
        }catch (err){
            dispatch(errorAlert('Problem re submitting profile! Please try again later!'))
            dispatch(set_loading_false())
        }
    }
    
    useEffect(()=> {
        if (user_id){
            userService.get_reasons_declined_js(user_id)
                .then(data => set_reasons(data['reasons_declined']))
                .catch(err => console.log(err))
        }
    },[user_id])
    
    return (
        <>
        <div style={styles.wait_div}>
            Your profile has been declined by our back office.
            Please update your information and click the button for approval.
            <br/>
                
             <br/>
            {show_reasons 
            ? <span style={{cursor:'pointer'}} onClick={()=>set_show_reasons(false)} > Hide details <KeyboardArrowUpOutlined  fontSize='large'/> </span>
            : <span style={{cursor:'pointer'}} onClick={()=>set_show_reasons(true)} > View details <KeyboardArrowDownOutlined fontSize='large'/> </span>
            }
            <br/>
            { show_reasons && render_reasons()}
        </div>
        <br/>
        </>
    )
}