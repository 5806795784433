import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

const LandingLoading = () => {
    return (
    <div className='logged-in-container'>
        <Skeleton variant="rect"  height={50} /><br/>
        <Skeleton variant="rect" width={500} height={200} />
        {/* <Skeleton variant="rect" width={210} height={118} /> */}
    </div>
    )
}

export default LandingLoading;
