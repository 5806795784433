import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {messages_state} from '../../../recoil_state'

const Contact = props => {

  const company = useSelector( ({company}) => company)
  const msg_state = useRecoilValue(messages_state);

  return (
    <NavLink to={`#`} style={{ color: "#fff", textDecoration:'none' }}>

      <li className="contact" 
        onClick={(e)=> {
          props.setChatter(props.person)
          props.setChatID(props.chat_id)
          props.set_curr_chat(props.chat_id)
        }}
        style={{backgroundColor: props.chat_id === props.curr_chat ? "#0099ee" : 'rgb(54, 68, 83)' , borderRadius:5}}
      >
        <div className="wrap">
          <img src={props.person.profile_image} alt="Rapihire profile" />
          <div className="meta">
            <p style={{fontWeight:'bold', fontSize:12}} className="name">
              {`${props.person.first_name} - ${!company ? props.company : props.position} - ${props.city}`} 

              {(msg_state.includes(props.chat_id) || props.number_of_unread_messages > 0 ) && <p style={styles.badge}>{props.number_of_unread_messages || 1}</p>}
              
            </p>

            {/* <p style={{marginTop:-10}}>
              {props.last_msg} 
            </p> */}
            
          </div>
        </div>
      </li>
    </NavLink>
  );
}

const styles = {
  badge:{
      marginLeft: '5px',
      fontWeight:'bold',
      backgroundColor:'#ee5566',
      padding:5,
      borderRadius:5,
      width:30,
      textAlign:'center',
      float:'right',
  }
}

export default Contact;