import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  validate_canada_zip,
  validate_us_zip,
} from "../../../validations/GenralValidations";

const Address = (props) => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [valid, set_valid] = useState(true);
  const [address_error, set_address_error] = useState(false);
  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const countryArray = general.countries.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setCountries(countryArray);
      const cityArray = general.cities.map((pos) => {
        return { value: pos.id, label: pos.name, province: pos.province };
      });
      setCities(cityArray);
      const provinceArray = general.provinces.map((pos) => {
        return { value: pos.id, label: pos.name, country: pos.country };
      });
      setProvinces(provinceArray);
    }
  }, [general]);

  const handleCountry = (choice) => {
    props.set_country({ id: choice.value, name: choice.label });
  };

  const handleCity = (choice) => {
    props.set_city({ id: choice.value, name: choice.label });
  };

  const handleProvince = (choice) => {
    props.set_province({ id: choice.value, name: choice.label });
  };

  const [zip_error, set_zip_error] = useState(false);

  const validate_address = (country, province, city) => {
    const country_id = general.countries.find(c => Number(c.id) === Number(country.id)).id
    const province_id = general.provinces.find(c => Number(c.id) === Number(province.id)).id
    const province_country_id = general.provinces.find(c => Number(c.id) === Number(province.id)).country.id
    const city_province_id = general.cities.find(c => Number(c.id) === Number(city.id)).province.id
    if(country_id === province_country_id && province_id === city_province_id){
        set_address_error(0)
        return true
    }
    set_address_error(1)
    return false
  }

  const validate_form = () => {
    set_zip_error(
      props.country.name === 'USA'
        ? !validate_us_zip(props.zip_code.value)
        : !validate_canada_zip(props.zip_code.value),
    )

    return (
      props.address.value.length > 1 &&
      validate_address(props.country, props.province, props.city) &&
      ((props.country.name === "USA" &&
        validate_us_zip(props.zip_code.value)) ||
        (props.country.name === "Canada" &&
          validate_canada_zip(props.zip_code.value))) &&
      props.city.id &&
      props.province &&
      props.country
    );
  };

  return (
    <div>
      <form>
        <Select
          onChange={handleCountry}
          options={countries}
          placeholder={"Country"}
          value={countries.find((pos) => pos.value === props.country.id)}
        />
        <br />

        <Select
          onChange={handleProvince}
          options={provinces.filter(
            (province) => province.country.id === props.country?.id
          )}
          placeholder={"State/Province"}
          value={provinces.find((pos) => pos.value === props.province.id)}
        />
        <br />

        <Select
          options={cities.filter(
            (city) => city.province.id === props.province?.id
          )}
          onChange={handleCity}
          placeholder={"City"}
          value={cities.find((pos) => pos.value === props.city.id)}
        />
        <br />
      </form>

      <div className="form-row">
          <input className="form-control col" {...props.address} placeholder="Address" />
        <div className="col">
          <input
            className="form-control"
            disabled={!props.country.id}
            {...props.zip_code}
            placeholder="ZIP Code"
          />
          {zip_error ? (
            <p className="invalid-feedback">
              Incorrect ZIP format for {props.country.name}{" "}
            </p>
          ) : null}
          { address_error ? <p className='invalid-feedback text-right'>Please check Country, Province and City </p> : null}

        </div>
      </div>

      <br />

      {!valid ? (
        <p className="invalid-feedback bg-white">
          All the fields are required!
        </p>
      ) : null}

      <div>
        <button className="btn btn-light w-50" onClick={(e) => props.prevSection(e)}>
          Previous
        </button>

        <button
          className="btn btn-primary w-50"
          onClick={(e) => {
            if (validate_form()) {
              props.nextSection(e);
              set_valid(true);
            } else set_valid(false);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Address;
