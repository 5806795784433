import userService from '../services/user'
import companyService from '../services/company'
import jobService from '../services/jobs'
import matchService from '../services/match'
import {errorAlert, successAlert} from './alertReducer'

import Cookies from 'js-cookie'
import { sidebar_change_size } from './sidebarReducer'
import { set_loading_false, set_loading_true } from './loadingReducer'

const userState = () => {
    const loggedUser = Cookies.get('user')
    
    if(loggedUser){
      const userOk = JSON.parse(loggedUser)
      tokenizeServices(userOk.token)

      return userOk.data
    }
    return null
}

const userReducer = (state=userState(), action) => {
    switch (action.type){

        case 'LOGIN':
            return action.data

        case 'UPDATE':
            return action.data

        case 'SET_USER':
            return action.data

        case 'LOGOUT':
            window.localStorage.clear()
            Cookies.remove('user')
            return null

        default:
            return state
    }
}




export const user_login = (payload) => {
    window.localStorage.clear()
    Cookies.remove('user')
    
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const dataToken = await userService.login(payload)
            tokenizeServices(dataToken.token)
            
            const user = await userService.getUser()

            const localUser = {
                token:dataToken.token,
                data:user
            }
            // window.localStorage.setItem('user', JSON.stringify(localUser) )

            Cookies.set('user', JSON.stringify(localUser),{ secure: true })

            if(user.is_job_provider){
                window.localStorage.setItem('user_role', 'jp')
            }
            else {
                window.localStorage.setItem('user_role', 'js')
            }
            dispatch({
                type:'LOGIN',
                data:user
            })
            dispatch(set_loading_false())
            dispatch(sidebar_change_size(0))
            window.location.reload()
        }
        catch(exception){
            dispatch(errorAlert("Invalid credentials or you have not clicked the confirmation email! \n\n If you do not see the validation email, please check your JUNK (or SPAM) folder and click the validation link. If you still do not see it, please contact us at: info@rapihire.com."));
            dispatch(set_loading_false())
        }
    }
}


export const update_user = (payload) => {

    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const new_user = await userService.updateUser(payload)
            
            const loggedUser = Cookies.get('user')
    
            const userOk = JSON.parse(loggedUser)
            const token = userOk.token

            const localUser = {
                token:token,
                data:new_user
            }

            Cookies.set('user', JSON.stringify(localUser),{ secure: true })

            dispatch({
                type:'UPDATE',
                data:new_user
            })

            dispatch(successAlert("Successfully Updated info!"));
            setTimeout(()=>dispatch(errorAlert("")),3000);
            dispatch(set_loading_false())
        }
        catch(exception){
            dispatch(errorAlert("Problem with updating info!"));
            setTimeout(()=>dispatch(errorAlert("")),3000);
            dispatch(set_loading_false())
        }
    }
}



export const logout = () => {
    return ({
        type:'LOGOUT'
    })
}

const tokenizeServices = (token) => {
    userService.setToken(token)
    companyService.setToken(token)
    jobService.setToken(token)
    matchService.setToken(token)
}

export default userReducer

