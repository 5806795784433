import React, { useState }  from 'react'
import { Spinner} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ChangePassword from './ChangePassword'
import userService from '../../../../services/user'
import { logout, update_user } from '../../../../reducers/userReducer'
import { useHistory } from 'react-router'



const styles = {

    label:{
        margin:'20px',
        fontSize: '16px',
        color: '#445566',
        marginBottom:'5px'
    },
    value:{
        color: '#839eaf',
        fontSize: '16px',
        lineHeight: 1.5,
        marginLeft:'20px',
    },
    delete_label:{
        color:'#ee5566',
        fontSize: '16px',
        marginLeft:'20px'
    }
}


const ProfileTab = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector( ({user}) => user )
    const js_data = useSelector( ({job_seeker_info})=>job_seeker_info)
    const [show_edit, set_show_edit] = useState(false)

    if(!user || !js_data){ 
        return (
        <div style={{textAlign:'center'}}>
         <Spinner style={{}} animation="border" /> 
        </div>
    )}

    return (
            <form className='home-tab' >
                {show_edit ? <ChangePassword set_show_edit={set_show_edit}/> : null }
                
                <div> 
                    <p style={styles.label}>First Name</p>
                    <p style={styles.value}>{user.first_name}</p>
                </div>
            
                <hr/>

                <div> 
                    <p style={styles.label}>Last Name</p> 
                    <p style={styles.value}>{user.last_name}</p>
                </div>
                <hr/>
                <div> 
                    <p style={styles.label}>Email</p> 
                    <p style={styles.value}>{user.email}</p>
                </div>
                <hr/>

                <div> 
                    <p style={styles.label}>Password <b onClick={(e)=>set_show_edit(true)} style={{fontSize:14,float:'right', color:'#249afa'}}>Change</b></p> 
                    <p style={styles.value}>*******</p>
                </div>
                <hr/>

                <div> 
                    <p style={styles.label}>Phone</p> 
                    <p style={styles.value}>{js_data.telephone}</p>
                </div>
                <hr/>

                <div> 
                    <p style={styles.label}>Address</p> 
                    <p style={styles.value}>
                        {js_data.address}, {js_data.zip_code},
                        {js_data.city?.name}, {js_data.country?.name}
                    </p>
                </div>

            

                <hr/>
                
                <div> 
                    <p style={styles.label}>Security &amp; Privacy</p> 
                    <p style={styles.value}>2-step verification</p>
                </div>
                
                <hr/>

                
          
                
            </form>
    )
}

export default ProfileTab
