import React from 'react'
import {Modal, Button} from 'react-bootstrap'

const Popup = (props) => {

    return (
      <Modal
        style={{width:'100%',margin:'auto'}}
        show={props.show}
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <div>
          {props.text}
        </div>
        <Modal.Footer>
          
          <Button onClick={props.ok} variant="primary">Ok</Button>
        </Modal.Footer>
      </Modal>
      )
    
}

export default Popup;