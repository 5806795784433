import React from "react";
import { Link } from "react-router-dom";

const styles = {
  link: {
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#445566",
  },
};

const ListingRow = ({ job, company }) => {
  if (!job) return "loading";
  return (
    <tr className="custom-table-row">
      <td>
        <Link style={styles.link} to={`/jp/opening/${job.id}`}>
          {job.position.name}
        </Link>
      </td>

      <td>
        {job.nr_matches}
      </td>

      <td>
        {job.nr_active_matches}
      </td>

      <td>
        {job.nr_sent_pending_offers}
      </td>

      <td>
        <Link
          style={{
            textDecoration: "none",
            color:
              job.nr_active_matches === job.nr_employees_wanted ? "black" : "#ff9700",
          }}
          to={`/jp/opening/${job.id}/suggestions`}
        >
          {job.nr_employees_wanted}{" "}
          {job.nr_active_matches !== job.nr_employees_wanted
            ? company.is_approved === "Approved"
              ? job.is_failed ? "Failed job" : "Searching"
              : " Waiting for Approval"
            : null}
        </Link>
      </td>

      <td className="text-right"> ${job.rate_per_hour}/h </td>

      <td className="text-right">
        {job.start_date
          ? new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
              Date.parse(job.start_date)
            )
          : "Not povided"}
      </td>
    </tr>
  );
};

export default ListingRow;
