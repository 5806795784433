import axios from 'axios'
import {url} from './utils.js'

const baseURL = url

let token = null

const setToken = (newToken) => {
    token = `token ${newToken}`
}

const getAll = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}companies/`,config)
    return response.data
}

const update = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.patch(`${baseURL}companies/${payload.id}`,payload,config)
    return response.data
}

const createCompany = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}companies/`,payload,config)
    return response.data
}

const rate_company_js = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.post(`${baseURL}company/rate/`, payload, config)
    return data
}

const get_company_from_jp = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}companyfromjp/${id}`,config)
    return response.data
}

export default {getAll, setToken, update, createCompany, get_company_from_jp, rate_company_js}
