import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { resume_item } from "../../../../../validations/resume";
import FormikTextInput from "../../../../ReusableComponents/FormikTextInput";
import FormikCreatableSelect from "../../../../ReusableComponents/FormikCreatableSelect";
import { useDispatch, useSelector } from "react-redux";
import userService from "../../../../../services/user";
import { errorAlert, successAlert } from "../../../../../reducers/alertReducer";
import { init_certifications } from "../../../../../reducers/jsResume/certificationReducer";
import { format_file } from "../EditLicences";

const UpdateCertification = ({ certification, set_show_edit }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    set_show_edit(false);
  };
  const [file, set_file] = useState(null);
  const certificate_options = useSelector( ({general}) => general.certificates.map( cert => {
    return { value: cert.id, label: cert.name }
  }))

  const handle_file_select = (e) => {
    set_file(e.target.files[0]);
  };

  
  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit certification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            title: {name:certification.title},
            year_achieved: certification.year_achieved,
            institution: certification.institution,
            institution_location: certification.institution_location,
          }}
          validationSchema={resume_item}
          onSubmit={async (values, { setSubmitting }) => {
            // Reset Form
            setSubmitting(false);
            const payload = {
              title: values.title.name,
              year_achieved: values.year_achieved,
              institution: values.institution,
              institution_location: values.institution_location,
            };
            const formData = new FormData();
            formData.append("file", file);

            userService
              .updateCertification(payload, certification.id)
              .then((data) => {
                handleClose();
                dispatch(init_certifications());
                dispatch(successAlert("Updated Successfully"));
                setTimeout(() => {
                  dispatch(errorAlert(""));
                }, 2000);
              })
              .catch((error) => dispatch(errorAlert("Problem updating!")));

            userService
              .uploadCertificate(formData, certification.id)
              .then((data) => dispatch(init_certifications()))
              .catch((err) => console.log("Error uploading!"));
          }}
        >
          {(props) => (
              <Form className="text-left m-auto" >
              <FormikCreatableSelect
                options={certificate_options}
                name="title"
                placeholder={"Title"}
                value={{label:props.values.title.name, value:props.values.title.name}}
              />
              <FormikTextInput
                name="year_achieved"
                placeholder="Year Achieved"
              />
              <FormikTextInput name="institution" placeholder="Institution" />
              <FormikTextInput
                name="institution_location"
                placeholder="Institution Location"
              />
              <br />

              {certification.document && (
                <>
                  {" "}
                  <br />
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={certification.document}
                  >
                    {format_file(certification.document)}
                  </a>
                  <br />{" "}
                </>
              )}

              <input
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={handle_file_select}
                type="file"
              />

              <br />
              <br />

              <div className="text-center">
                <button
                    className="btn btn-outline-primary w-100"
                    type="submit"
                  >
                    {props.isSubmitting ? "Loading.." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCertification;
