import React from 'react'

const styles = {
    membership:{
        width: '500px',
        height: '428px',
        padding: '40px 41px 30px',
        borderRadius: '4px',
        border: 'solid 1px #ccd7df',
        backgroundColor: '#ffffff'
    },
    oval:{
        width: '86px',
        height: '86px',
        margin:'auto',
        marginTop:'20px',
        borderRadius:'50%',
        border: 'solid 6px #e2ae23',
        backgroundImage: 'linear-gradient(135deg, #e1b300, #f1ca52 53%, #a79259)'
    },
    m_type:{
        fontSize:'20px',
        color: '#36454f',
        fontWeight:'bold',
        paddingTop:'10px',
        textAlign:'center'
    },
    m_desc:{
        width: '418px',
        height: '96px',
        fontSize: '16px',
        color: '#839eaf',
        textAlign:'center'

    }
}



const Membership = () => {


    return (
        <div className='home-tab' >            
           
            <div style={styles.membership}>
                <div style={styles.oval}></div>
                <p style={styles.m_type}>--</p>

                <p style={styles.m_desc}>
                This command is not available for your company in this moment
                </p>
                <div style={{textAlign:'center', paddingTop:'30px'}}>
                <button className='btn btn-primary disabled'>Upgrade plan</button>
                </div>

            </div>
                
        </div>
    )
}

export default Membership