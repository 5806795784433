import React, { useEffect, useState } from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { delete_job_opening } from '../../reducers/jobOpeningReducer'
import AddIcon from '@material-ui/icons/Add';
import jobService from '../../services/jobs'
import moment from 'moment'
import EditOpening from './EditOpening/EditOpening'
import EditSkills from './EditOpening/EditSkills'
import AddSkill from './EditOpening/AddSkill'
import EditLanguages from './EditOpening/EditLanguages'
import EditCertifications from './EditOpening/EditCertifications';

const OpeningDetail = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const opening_id = useParams().id  
  const jobOpening = useSelector( ({jobOpenings}) => jobOpenings.find(j => j.id === Number(opening_id)))
  const [required_skills, set_required_skills] = useState([])
  const [optional_skills, set_optional_skills] = useState([])
  const [required_languages, set_required_languages] = useState([])
  const [optional_languages, set_optional_languages] = useState([])

  const [show_edit, set_show_edit] = useState(false)
  const [show_skills, set_show_skills] = useState(false)
  const [show_languages, set_show_languages] = useState(false)
  const [show_certifications, set_show_certifications] = useState(false)
  const [add_required_skill, set_add_required_skill] = useState(false)
  const [add_optional_skill, set_add_optional_skill] = useState(false)

  useEffect(()=>{ 
    if(jobOpening){
      jobService.getListingSkills(opening_id)
        .then(all_skills => {
          set_required_skills(
            all_skills.filter(s=> s.required)
          )
          set_optional_skills(
            all_skills.filter(s=> !s.required)
          )
        })
        .catch(error=>{
          console.log("Error fetching skills");
        })

      jobService.getListingLanguages(opening_id)
        .then(all_languages => {
          set_required_languages(
            all_languages.filter(l=> l.required)
          )
          set_optional_languages(
            all_languages.filter(l=> !l.required)
          )
        })
        .catch(error=>{
          console.log("Error fetching languages");
        })
    }
  },[jobOpening,opening_id])

  if(!jobOpening){
    return (
        <div id="status">
            Job opening not found!
        </div>
    )
  }

  const handle_delete = (e) => {
    e.preventDefault()
    const ok = window.confirm("Are you sure you want to delete Job Listing?")
    if(ok){

      dispatch(delete_job_opening(jobOpening.id))
      history.push('/jp/jobs')
    }
  }

  return(
    
    <div className='logged-in-container'>
          {show_edit ? <EditOpening set_show_edit={set_show_edit} posting={jobOpening} /> : null}

          {add_required_skill 
          ? <AddSkill 
              set_show={set_add_required_skill} 
              listing_id={jobOpening.id} 
              required={true}
              old_skills={jobOpening.skills.map(s=>s.id)}
            /> : null}

          {add_optional_skill 
          ? <AddSkill 
              set_show={set_add_optional_skill} 
              listing_id={jobOpening.id} 
              required={false}
              old_skills={jobOpening.skills.map(s=>s.id)}
            /> : null}

          {show_skills ? 
            <EditSkills 
              set_show={set_show_skills} 
              required_skills={required_skills}
              optional_skills={optional_skills}/> 
            : null}
          
          {show_languages &&
            <EditLanguages  
              set_show={set_show_languages} 
              old_required_languages={required_languages}
              old_optional_languages={optional_languages}
              listing_id={opening_id}
            />
          }

          {show_certifications &&
            <EditCertifications
              set_show={set_show_certifications}
              old_certifications = {jobOpening?.certifications}
              old_licenses = {jobOpening?.licenses}
              listing_id={opening_id}
            />
          }
          <div id="status" className='w-50 text-center m-auto' >
              <Link to={`/jp/opening/${jobOpening.id}/filtered`}>
                <button className='btn btn-success mt-2 w-100'  >See Potenital Candidates</button>
              </Link>
              <br/>
              <Link to={`/jp/opening/${jobOpening.id}/suggestions`}>
                <button className='btn btn-primary mt-2 w-100' >See Approved Candidates</button>
              </Link>
          </div>

          <table className='job-opening-table'>
          
            <tbody>
              <tr> 
                <td colSpan='2' className="font-weight-bold text-left text-secondary h4 pt-4">Overview: {jobOpening.position.name} </td>
              </tr>
              <tr>
                <td colSpan='2' className="font-weight-bold text-left text-secondary h5 pt-4">Job Posting <hr style={{borderTop:'1px solid #a3bbca'}} /></td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Position</td>
                <td className="text-right p-3"> {jobOpening.position.name}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Permanent/Temporary</td>
                <td className="text-right p-3">{jobOpening.job_all_length}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Employment type </td>
                <td className="text-right p-3"> {jobOpening.job_type}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary"> Can be performed remotely  </td>
                <td className="text-right p-3"> {jobOpening.is_remote ? "Yes" : "No"}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Number of employees wanted</td>
                <td className="text-right p-3">{jobOpening.nr_employees_wanted}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Approval</td>
                <td className="text-right p-3"> {jobOpening.approve_yourself ? 'By Company' : 'By Platform'} </td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Address</td>
                <td className="text-right p-3"> {jobOpening.address}<br/>{jobOpening.city.name}, {jobOpening.province.name}, {jobOpening.zip_code}, {jobOpening.country.name}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Shift</td>
                <td className="text-right p-3">{jobOpening.shift}</td>
              </tr>

              <tr>
              <td colSpan='2' className="font-weight-bold text-left text-secondary h5 pt-4">Job Time Length <hr style={{borderTop:'1px solid #a3bbca'}}/></td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Daily Hours</td>
                <td className="text-right p-3"> {jobOpening.daily_hours} </td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Rate per Hour</td>
                <td className="text-right p-3"> ${jobOpening.rate_per_hour} </td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Job length</td>
                <td className="text-right p-3"> {jobOpening.job_time_length}</td>
              </tr>

              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary">How many times</td>
                <td className="text-right p-3"> {jobOpening.job_time_length_quantity}</td>
              </tr>


              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary">Start date</td>
                <td className="text-right p-3"> {jobOpening.start_date ? new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(Date.parse(jobOpening.start_date)) : 'Not povided'}</td>
              </tr>


              <tr>
              <td colSpan='2' className="text-left pt-4">
                <span className="font-weight-bold text-secondary h5"> Skills and Languages </span> <br/>
                <button className="btn btn-info btn-sm h-25 m-2" onClick={(e)=>{e.preventDefault();set_show_languages(true);}}>Edit Languages</button> 
                <button className="btn btn-info btn-sm h-25 m-2" onClick={(e)=>{e.preventDefault();set_show_skills(true);}} >Edit Work Experiences</button>

                <hr style={{borderTop:'1px solid #a3bbca'}}/>
              </td>
              </tr>
              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary"> 
                  {/* Required Skills  */}
                  Required Experiences
                  <button className="btn btn-info btn-sm m-2 h-25" onClick={(e)=>{e.preventDefault();set_add_required_skill(true);}}>
                    <AddIcon />
                  </button>
                </td>
                <td className="text-right p-3"> 
                  {required_skills.map(e=> 
                  <div key={e.skill.id}>

                      <p className="m-0" >{e.skill.name}</p>
                      <p className="text-muted">
                        {e.importance} pts; {e.years_of_experience} years of experience
                      </p> 

                  </div>
                  )} 
                </td>
              </tr>

              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary">Skills 
                <button className="btn btn-info btn-sm m-2 h-25" onClick={(e)=>{e.preventDefault();set_add_optional_skill(true);}}>
                    <AddIcon />
                  </button>
                </td>
                <td className="text-right p-3"> 
                <hr/>
                  {optional_skills.map(e=> 
                      <div key={e.skill.id}>

                        <p className="m-0" >{e.skill.name}</p>
                        <p className="text-muted" >
                        {e.importance} pts; {e.years_of_experience} years of experience
                        </p> 

                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary"> Required Languages 
                </td>
                <td className="text-right p-3"> 
                <hr/>
                  {required_languages.map(e=> 
                    <div key={e.language.id}>
                        <p className="m-0"  >{e.language.name}</p>
                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary"> Optional Languages 
                </td>
                <td className="text-right p-3"> 
                <hr/>
                  {optional_languages.map(e=> 
                    <div key={e.language.id}>
                        <p className="m-0"  >{e.language.name}</p>
                    </div>
                  )}
                </td>
              </tr>

              <tr>
              <td colSpan='2' className="text-left pt-4">
                <span className="font-weight-bold text-secondary h5"> Licenses and Certifications </span>
                <button className="btn btn-info btn-sm h-25 m-2" 
                    onClick={(e)=>{
                      e.preventDefault();
                      set_show_certifications(true);
                    }}
                >Edit
                </button> 

                <hr style={{borderTop:'1px solid #a3bbca'}}/>
              </td>
              </tr>

              <tr>     
                <td className="text-left p-2 font-weight-bold text-secondary"> Required Certifications 
                </td>
                <td className="text-right p-3"> 
                <hr/>
                  {jobOpening?.certifications?.map(e=> 
                    <div key={e.id}>
                        <p className="m-0"  >{e.name}</p>
                    </div>
                  )}
                </td>
              </tr>

              <tr>
                <td className="text-left p-2 font-weight-bold text-secondary"> Required Licenses 
                </td>
                <td className="text-right p-3"> 
                <hr/>
                  {jobOpening?.licenses?.map(e=> 
                    <div key={e.id}>
                        <p className="m-0"  >{e.name}</p>
                    </div>
                  )}
                </td>
              </tr>

                {/* Orientation */}
              <tr>
                <td colSpan='2' className="text-left p-2 font-weight-bold text-secondary">Orientation<hr style={{borderTop:'1px solid #a3bbca'}}/></td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Required online orientation session before starting</td>
                <td className="text-right p-3"> {jobOpening.online_session ? 'Yes' : 'No'} </td>
              </tr>

              {/* Instructions and notes */}

              <tr>
              <td colSpan='2' className="font-weight-bold text-left text-secondary h5 pt-4">Other Notes and Instructions<hr style={{borderTop:'1px solid #a3bbca'}}/></td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Instructions</td>
                <td className="text-right p-3"> {jobOpening.instructions}</td>
              </tr>

              <tr >
                <td className="text-left p-2 font-weight-bold text-secondary">Other Notes</td>
                <td className="text-right p-3"> {jobOpening.other_notes}</td>
              </tr>

              <tr >
                <td colSpan={2} className="text-left p-2 font-weight-bold text-secondary">
                <hr style={{borderTop:'1px solid #a3bbca'}}/>

                 Accepted <a style={{color:'rgb(73,154,243)'}} rel="noopener noreferrer" target='_blank' href={`https://rapihire.com/official_docs/employer_terms&conditions.html`}> Employer Agreement </a> on 
                  {' '}{moment(jobOpening.created_at_timestamp).format('MMMM Do YYYY, h:mm:ss a')}
                <hr style={{borderTop:'1px solid #a3bbca'}}/>

                </td>
              </tr>

              {/* Buttons for delete and edit */}
              <tr>
                <td>
                    <button className="btn btn-info w-100" onClick={(e)=>{e.preventDefault();set_show_edit(true)}}  >
                      Edit
                    </button>
                </td>

                <td>
                    <button className="btn btn-danger w-100" onClick={handle_delete} >
                      Delete
                    </button>
                </td>
              </tr>

            </tbody>
          </table>
          </div>
  )
}



export default OpeningDetail;