import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import InfoIcon from "@material-ui/icons/Info";
import { job_listing } from "../../../theme";

const OptionButton = (props) => {
  return (
    <button
      className="option-button"
      style={{
        backgroundColor: props.checked ? "#249afa" : "white",
        color: props.checked ? "white" : "black",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const General = (props) => {

  const [positions, setPositions] = useState([]);
  const [valid, set_valid] = useState(true);
  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const positionsArray = general.positions.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setPositions(positionsArray);
    }
  }, [general]);

  const handleApproval = (e) => {
    props.set_approve_yourself(e.target.value === "True" ? true : false);
  };

  const handlePosition = (choice) => {
    props.set_position({ id: choice.value, name: choice.label });
  };

  const [job_type_error, set_job_type_error] = useState(false);
  const [nr_employees_error, set_nr_employees_error] = useState(false);
  const [position_error, set_position_error] = useState(false);
  const [jal_error, set_jal_error] = useState(false);

  const validate_form = () => {
    set_job_type_error(
      props.job_type.value === "Hire" || props.job_type.value === "Rent"
        ? false
        : true
    );
    set_position_error(props.position.id ? false : true);
    set_nr_employees_error(
      props.number_of_employees_wanted.value > 0 ? false : true
    );
    set_nr_employees_error(
      props.number_of_employees_wanted.value < 200 ? false : true
    );
    set_jal_error(
      props.is_remote === null
    );
    return (
      props.position.id &&
      props.job_type.value.length > 3 &&
      props.number_of_employees_wanted.value > 0 &&
      props.number_of_employees_wanted.value < 200
    );
  };

  return (
    <div>
      <form>
        <p className="font-weight-bold text-secondary">
          Job title * <InfoIcon className="float-right" />
        </p>
        <Select
          value={positions.find((pos) => pos.value === props.position.id)}
          onChange={handlePosition}
          options={positions}
        />
        {position_error ? <p className="invalid-feedback">Required</p> : null}
        <br />
        <p className="font-weight-bold text-secondary">
          Employment Type *
          <InfoIcon  className="float-right"/>
        </p>
        <div className="text-center">
          <OptionButton
            style={{ width: "33%" }}
            label={"Hire"}
            checked={props.job_type.value === "Hire"}
            onClick={(e) => props.job_type.onClick(e, "Hire")}
          />
          <OptionButton
            style={{ width: "33%", pointerEvents:'none', color: 'grey' }}
            label={"Rent"}
            checked={props.job_type.value === "Rent"}
            onClick={(e) => props.job_type.onClick(e, "Rent")}
          />
          <OptionButton
            style={{ width: "33%", pointerEvents:'none', color: 'grey' }}
            label={"Both"}
            checked={props.job_type.value === "Either Hire or Rent"}
            onClick={(e) => props.job_type.onClick(e, "Either Hire or Rent")}
          />
        </div>
        {job_type_error ? <p className="invalid-feedback">Required</p> : null}
        <br />
        <p className="font-weight-bold text-secondary">
          Can this job be performed remotely, meaning primarly from home? If so,
          we will add a 'Remote' tag to your post. *{" "}
          <InfoIcon className="float-right" style={{cursor:'pointer' }} />
        </p>
        <div className="text-center">
          <OptionButton
            style={{ width: "30%" }}
            label={"No"}
            checked={props.is_remote===false}
            onClick={(e) => {e.preventDefault();props.set_is_remote(false)}}
          />
          <OptionButton
            style={{ width: "30%" }}
            label={"Yes"}
            checked={props.is_remote}
            onClick={(e) => {e.preventDefault();props.set_is_remote(true)}}
          />
        </div>
        {jal_error ? <p className="invalid-feedback">Required</p> : null}
        <br />
        <div className="form-group" controlId="hmt">
          <label className="font-weight-bold text-secondary">
            How many people do you need to hire for this position? *
          </label>
          <InfoIcon className="float-right" />
          <input className="form-control"
            {...props.number_of_employees_wanted}
            placeholder="Number of hires"
          />
          {nr_employees_error ? (
            <p className="invalid-feedback">Should be greater than 0</p>
          ) : null}
        </div>
        <br />
        <p className="font-weight-bold text-secondary">
          Before accepting the candidates, you want to
          <InfoIcon className="float-right" />
        </p>
        <input
          style={job_listing.radio}
          type="radio"
          value="True"
          onChange={handleApproval}
          checked={props.app_yourself === true}
        />
        Approve yourself <br />
        <input
          style={job_listing.radio}
          type="radio"
          value="False"
          onChange={handleApproval}
          checked={props.app_yourself === false}
        />
        Let the platform approve <br />
      </form>

      {!valid ? (
        <p className="invalid-feedback bg-white mt-4">
          Please fill all the fields properly!
        </p>
      ) : null}

      <div>
        <button
          className="btn btn-primary w-50 mt-4"
          onClick={(e) => {
            if (validate_form()) {
              props.nextSection(e);
              set_valid(true);
            } else set_valid(false);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default General;
