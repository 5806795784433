import React, { useEffect, useState } from 'react'
import './messages.css'
import Sidepanel from './Sidepanel'
import Chat from './Chat'
import Profile from './Profile'
import matchService from '../../../services/match'
import { useSelector } from 'react-redux'
import SocketService from '../../../services/SocketService'
import { useRecoilState } from 'recoil'
import { messages_state } from '../../../recoil_state'

const Messages = () => {

  const [chatter, setChatter] = useState(null)
  const [messages, setMessages] = useState([])
  const [chatID, setChatID] = useState('')
  const [limit, set_limit] = useState(4)
  const user = useSelector( ({user}) => user)
  const [new_msg, set_new_msg] = useState('')
  const [msg_state, set_msg_state] = useRecoilState(messages_state)

  const fire_message = (payload) => {
    set_new_msg(payload)
    set_msg_state([...msg_state, payload.chat])
    set_new_msg('')
  }
  useEffect(()=>{
    let active = true
    if(chatID){
      matchService.getMessagesByChat(chatID, limit)
          .then(fetched_messages=>{
            setMessages(fetched_messages.reverse())
            set_msg_state( msg_state.filter(id => id !== chatID))
          })
          .catch(error=>{
            console.log("Error fetching messages");
          })
    }
    return () => { active = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chatID, limit])

  
  useEffect(()=>{
    if (new_msg) {
      setMessages([...messages, new_msg])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[new_msg])

  const [chats, setChats] = useState(null)
  const [curr_chat, set_curr_chat] = useState(null)

  useEffect(()=>{
    if(chatID){
      set_curr_chat(chats.find(chat => chat.id === chatID))
    }
  },[chatID])


  useEffect(()=>{
    matchService.getChats()
      .then(chats=>{
        const persons = chats.map(c=> c)
        setChats(persons)
      })
  },[] )
  return (
    <div className='logged-in-container' style={{minWidth:'500px'}}>

      <div className='message_div' >    
       <SocketService fire_message={fire_message}/>        
        <div id="frame">
       <Sidepanel set_curr_chat={set_curr_chat} chats={chats} chat_id={chatID} setChatID={setChatID} setChatter={setChatter} user={user} />
      {chatter &&  
        <div className="content">

          <Profile company={curr_chat?.company} position={curr_chat?.position} chatter={chatter}/>           
          <Chat 
            chat_id={chatID}  
            messages={messages} 
            set_messages={setMessages}
            user={user} 
            chatter={chatter}
            limit={limit}
            set_limit={set_limit}
          />
          
        </div> }
      </div>
      </div>
      </div>
  )
}

export default Messages;