import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import {
  BrowserRouter as Router,
} from "react-router-dom" 
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'

// import SocketService from './services/socket'
import {
  RecoilRoot
} from 'recoil'
 


const renderApp = () => {
  ReactDOM.render(

  <Provider store={store} >
    <RecoilRoot>
    <Router>
    <PersistGate persistor={persistor}>
      <App />   
    </PersistGate>

    </Router>
    </RecoilRoot>
  </Provider>,
  
  document.getElementById('root'))
}
renderApp()

// store.subscribe(()=> console.log(store.getState()))