import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import MenuButton from "../ReusableComponents/MenuButton";
import ListingRow from "./ListingRow";
import NewCandidates from "./MatchNotification/NewCandidates";
import { ProfileDeclinedJP, WaitForApprovalJP } from "../Shared/ApprovalMessage";
import Notifications from "../Shared/Notification/Notifications";


const ListOpenings = () => {
  const [filter, setFilter] = useState("Ongoing");
  const jobOpenings = useSelector(({ jobOpenings }) =>
    jobOpenings.filter((job) => {
      if (filter === "Ongoing") {
        return job.is_active;
      } else if (filter === "Finished") {
        return !job.is_active && !job.is_failed;
      } else if (filter === "Failed") {
        return job.is_failed
      } else return true;
    })
  );
  const number_of_jobs_posted = useSelector(({jobOpenings}) => 
    jobOpenings.length
  )
  const company = useSelector(({ company }) => company);
  const user_id = useSelector( ({ user }) => user.id)

  if (jobOpenings) {
    return (
      <div className="job-listings-page">
        {company?.is_approved === "Pending" && <WaitForApprovalJP />}
        {company?.is_approved === "Declined" && <ProfileDeclinedJP user_id={user_id} />}

        <MenuButton
          handle_click={(e) => {
            e.preventDefault();
            setFilter("Ongoing");
          }}
          is_active={filter === "Ongoing"}
          label={"Ongoing"}
        />
        <MenuButton
          handle_click={(e) => {
            e.preventDefault();
            setFilter("Finished");
          }}
          is_active={filter === "Finished"}
          label={"Finished"}
        />
        <MenuButton
          handle_click={(e) => {
            e.preventDefault();
            setFilter("Failed");
          }}
          is_active={filter === "Failed"}
          label={"Failed"}
        />
        <MenuButton
          handle_click={(e) => {
            e.preventDefault();
            setFilter("All");
          }}
          is_active={filter === "All"}
          label={"All"}
        />
        <br/><br/>
        {number_of_jobs_posted < 10 ? (
          <Link className="w-25" to="/jp/newopening">
            <button className="btn btn-primary w-25">Post new job</button>
          </Link>
        ) : (
          <div className="job-listings-page-button1 w-50">
              <button disabled className="btn btn-primary w-100">Max of 10 jobs posted</button>
          </div>
        )}

        <NewCandidates />
        <Notifications />
        <div className="custom-table-div">
          <table className="custom-table ">
            <thead>
              <tr>
                <td>
                  <b>Job Position</b>
                </td>
                <td>
                  <b>Total Matches</b>
                </td>
                <td>
                  <b>Active Matches</b>
                </td>
                <td>
                  <b>Sent out offers</b>
                </td>
                <td>
                  <b>Employes wanted</b>
                </td>
                <td className="text-right">
                  <b>Rate</b>
                </td>
                <td className="text-right">
                  <b>Start date</b>
                </td>
              </tr>
            </thead>

            <tbody>
              {jobOpenings.map((job) => (
                <ListingRow company={company} key={job.id} job={job} />
              ))}
            </tbody>
          </table>
        </div>

        <Link className="job-listings-page-button2" to="/jp/newopening">
        <button className="btn btn-primary w-50">Post new job</button>
        </Link>
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center", paddingTop: "100px" }}>
      <Spinner style={{}} animation="border" />
    </div>
  );
};

export default ListOpenings;
