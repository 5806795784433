import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import userService from "../../services/user";

const TwilioConfirmNumberModal = ({
        payload,
        show_phone_confirm,
        set_show_phone_confirm,
        handle_registration,
    }) => {
    const [code, set_code] = useState('');
    const [confirming, set_confirming] = useState(false);
    const [resending, set_resending] = useState(false);
    const [wrong_code, set_wrong_code] = useState(false);

    const resend_code = async (e) => {
        set_resending(true)
        try{
            const res = await userService.twilio_send_code(payload.phone_number);
            if(res.status === "pending"){
                set_show_phone_confirm(true);
                set_resending(false)
            }
        }catch(err){
            console.log(err);
            set_resending(false)
        }
    }

    const handle_confirm = async (e) => {
        e.preventDefault();
        set_confirming(true);
        try{
            //
            const res = await userService.twilio_confirm_code(code, payload.phone_number);

            if (res.status === "approved"){
                set_show_phone_confirm(false);
                await handle_registration(payload);

            }
            else{
                set_wrong_code(true);
            }
            set_confirming(false);
        }catch(err){
            console.log(err);
            set_confirming(false);
        }
    }
    const handle_close = () => {
        set_show_phone_confirm(false);
    }

    return (
        <Modal show={show_phone_confirm} onHide={()=>handle_close()}>
            <Modal.Header closeButton>
            <Modal.Title>Confirm Phone Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Plase enter the code you received in your phone:</p>

            <input value={code} onChange={e=>set_code(e.target.value)} className='form-control' type='text' />
                {wrong_code &&  <p className='invalid-feedback'>Incorrect code! Please check your phone number and try again!</p>}
            
            <br/>
            <button disabled={confirming} onClick={handle_confirm} className='btn btn-success'>
                {confirming ? "Confirming" : "Confirm"}
            </button>
            <button disabled={resending} onClick={resend_code} className='btn btn-info float-right'>
                {resending ? 'Resend Code' : 'Resend'}
            </button>
            </Modal.Body>
        </Modal>
    )
}

export default TwilioConfirmNumberModal;
