import React, { useEffect, useState } from 'react'
import Contact from './Contact'
import c1 from '../../../icons/avatar.png'
import { useDispatch } from 'react-redux'
import { set_loading_false, set_loading_true } from '../../../reducers/loadingReducer'

const Sidepanel = ({user, setChatter, setChatID, chats}) => {
   const dispatch = useDispatch()
   const [curr_chat, set_curr_chat] = useState(-1)

   if(!chats){
    dispatch(set_loading_true())
    return ''
  }

  if(chats){
    dispatch(set_loading_false())
  }
  return (
      <div id="sidepanel">
        <div id='contacts'>

         <ul style={{listStyle:'none'}} >
            {chats.map(chat=>{
               return <Contact 
                  key={chat.id}
                  person = {user.id !== chat.participants[0].id ? chat.participants[0]  : chat.participants[1]}
                  picURL={c1}
                  status="online"
                  setChatter={setChatter}
                  setChatID={setChatID}
                  chat_id={chat.id}
                  company = {chat.company}
                  position = {chat.position}
                  city = {chat.city}
                  last_msg = {chat.last_chat_message}
                  number_of_unread_messages = {chat.number_of_unread_messages}
                  curr_chat={curr_chat}
                  set_curr_chat={set_curr_chat}
               />
            })}
         </ul>

        </div>
        {/* <div id="bottom-bar">
        
        </div> */}
      </div>)
}

export default Sidepanel;