import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { job_listing } from "../../../theme";
import CreatableSelect from 'react-select/creatable'
import SingleSkill from "../../Shared/SingleSkill";
import jobService from '../../../services/jobs'
import { get_all_job_openings } from "../../../reducers/jobOpeningReducer";


const EditCertifications = ({ set_show, old_certifications, old_licenses, listing_id }) => {
  const handleClose = () => {
    set_show(false);
  };
  const dispatch = useDispatch()
  const [languages, set_languages] = useState([]);

  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const languages_array = general.languages.map((lan) => {
        return { value: lan.id, label: lan.name };
      });
      set_languages(languages_array);
    }
  }, [general]);

  const certificate_options = useSelector( ({general}) => general.certificates.map( cert => {
    return { value: cert.id, label: cert.name }
  }))

  const licenses_options = useSelector( ({general}) => general.licenses.map( lic => {
    return { value: lic.id, label: lic.name }
  }))

  const [required_certifications, set_required_certifications] = useState(old_certifications)
  const [required_licenses, set_required_licenses] = useState(old_licenses)

 
  const handleChooseCertification = (option) => {
      jobService.add_listing_to_certificate(listing_id, option.label)
        .then((data)=>{
            set_required_certifications([
                ...required_certifications,
                data
            ])
            dispatch(get_all_job_openings())
        })
        .catch(err=>console.log(err))
  }

  const handleChooseLicense = (option) => {
        jobService.add_listing_to_license(listing_id, option.label)
            .then((data)=>{
                set_required_licenses([
                    ...required_licenses,
                    data
                ])
                dispatch(get_all_job_openings())
            })
            .catch(err=>console.log(err))
    }


  const handleDeleteCertification = (e, listing_to_cert_id) => {
    e.preventDefault();
    jobService.delete_listing_to_certificate(listing_id, listing_to_cert_id)
        .then(()=>{
            set_required_certifications(
                required_certifications.filter((s) => s.id !== listing_to_cert_id)
            );
            dispatch(get_all_job_openings())
        })
        .catch(err=>console.log(err))
  };

  const handleDeleteLicense = (e, listing_to_license_id) => {
    e.preventDefault();
    jobService.delete_listing_to_license(listing_id, listing_to_license_id)
        .then(()=>{
            set_required_licenses(
                required_licenses.filter((s) => s.id !== listing_to_license_id)
            );
            dispatch(get_all_job_openings())
        })
  };


  const handleSaveCertifications = (e) => {
      e.preventDefault()
      set_show(false)
  }

  
  return (
    <Modal show={true} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title> Edit Certifications and Licenses </Modal.Title>
    </Modal.Header>
    <Modal.Body>

    <p className="font-weight-bold text-secondary"> Required Certifications </p>
      <CreatableSelect onChange={handleChooseCertification} options={certificate_options} />
      <div style={job_listing.skill_list}>
        {required_certifications.map((cert) => {
          return (
            <SingleSkill
              delete={(e)=>handleDeleteCertification(e, cert.id)}
              key={cert.id}
              label={cert.name}
            />
          );
        })}
      </div>

      <br/>

      <p className="font-weight-bold text-secondary"> Required Licenses </p>
      <CreatableSelect onChange={handleChooseLicense} options={licenses_options} />
      <div style={job_listing.skill_list}>
        {required_licenses.map((license) => {
          return (
            <SingleSkill
              delete={(e) => handleDeleteLicense(e, license.id)}
              key={license.id}
              label={license.name}
            />
          );
        })}
      </div>

      <br/>
      <button onClick={handleSaveCertifications} className="btn btn-success">
         Done
      </button>
            
    </Modal.Body>
    </Modal>
    );
};

export default EditCertifications;
