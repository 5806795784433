import React, { useRef, useState } from "react";
import Togglable from "../../../Togglable";
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from "react-redux";
import {
  add_certification,
  delete_certification,
} from "../../../../reducers/jsResume/certificationReducer";
import { resume } from "../../../../theme";
import { Formik, Form } from "formik";
import FormikTextInput from "../../../ReusableComponents/FormikTextInput";
import FormikCreatableSelect from "../../../ReusableComponents/FormikCreatableSelect";
import { resume_item } from "../../../../validations/resume";
import { DeleteOutline } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import UpdateCertification from "./UpdateResume/UpdateCertification";
import { format_file } from "./EditLicences";


const EditCertifications = () => {
  const dispatch = useDispatch();

  const all_certifications = useSelector(
    ({ certifications }) => certifications
  );

  const certificate_options = useSelector( ({general}) => general.certificates.map( cert => {
    return { value: cert.id, label: cert.name }
  }))

  const [file, set_file] = useState(null);


  const handle_delete = async (e, id) => {
    e.preventDefault();
    const ok = window.confirm("Are you sure you want to delete Certification?");
    if (ok) {
      dispatch(delete_certification(id));
    }
  };

  const [curr_certification, set_curr_certification] = useState(null);
  const [show_edit, set_show_edit] = useState(false);

  const handle_edit_click = (ev, e) => {
    ev.preventDefault();
    set_curr_certification(e);
    set_show_edit(true);
  };

  const [show_menu, set_show_menu] = useState(false);
  const addMenuRef = useRef()
  const handle_menu = (e) => {
    e.preventDefault()
    set_show_menu(!show_menu)
    setTimeout(()=>scrollToDiv(),100) 
  }
  const scrollToDiv = () => addMenuRef.current.scrollIntoView({ behavior: 'smooth' })

  const handle_file_select = (e) => {
    // Allow only files with .pdf and .jpg, .jpeg and .png extensions
    const file_extension = e.target.files[0].name.split(".").pop();
    if (
      file_extension === "pdf" ||
      file_extension === "jpg" ||
      file_extension === "jpeg" ||
      file_extension === "png"
    ) {
      set_file(e.target.files[0]);
    } else {
      alert("Only .pdf, .jpg, .jpeg and .png files are allowed");
    }
  };


  return (
    <div className="logged-in-container">
      {show_edit ? (
        <UpdateCertification
          set_show_edit={set_show_edit}
          certification={curr_certification}
        />
      ) : null}

      <div className="resume-item-page">
        <div>
          <p className="font-weight-bold text-secondary h5 mt-4"> My Certifications </p>

          {all_certifications.length > 0 ? (
            <div className="resume-item-page-added">
              {all_certifications.map((e) => (
                <div key={e.id}>
                  <hr />
                  <div className="mt-2 text-muted">
                    <p style={resume.titles}>
                      {e.title}
                      <button
                        onClick={(ev) => handle_delete(ev, e.id)}
                        style={resume.delete_button}
                      >
                        <DeleteOutline />
                      </button>
                      <button
                        onClick={(ev) => handle_edit_click(ev, e)}
                        style={resume.edit_button}
                      >
                        <EditIcon />
                      </button>
                    </p>
                    <p style={{ padding: 0, margin: 0 }}>{e.year_achieved}</p>
                    <p style={{ padding: 0 }}>
                      {e.institution}, {e.institution_location}
                      {e.document && (
                        <>
                          {" "}
                          <br />{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={e.document}
                          >
                            <span >{format_file(e.document)}</span>
                          </a>{" "}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <button onClick={handle_menu} className="btn btn-outline-primary mt-4">Add new certification</button> 
        <div ref={addMenuRef}  ></div>
        {show_menu && 
          <div className="resume-item-page-form shadow">
            <Formik
              initialValues={{
                title: undefined,
                year_achieved: "",
                institution: "",
                institution_location: "",
              }}
              validationSchema={resume_item}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // Reset Form
                resetForm();
                setSubmitting(false);

                const formData = new FormData();
                formData.append("file", file);


                const payload = {
                  title: values.title.name,
                  year_achieved: values.year_achieved,
                  institution: values.institution,
                  institution_location: values.institution_location,
                };
                dispatch(add_certification(payload, formData));
              }}
            >
              {(props) => (
                <Form className="text-left m-auto w-100">
                  <p className="font-weight-bold text-center text-secondary h6 mt-4"> Add new Certification </p>
                  <FormikCreatableSelect
                    // label='Country'
                    options={certificate_options}
                    name="title"
                    placeholder={"Title"}
                    value={certificate_options.find(
                      (pos) => pos.value === props.values.title?.id
                    )}
                  />
                  <FormikTextInput
                    name="year_achieved"
                    placeholder="Year Achieved"
                  />
                  <FormikTextInput
                    name="institution"
                    placeholder="Institution"
                  />
                  <FormikTextInput
                    name="institution_location"
                    placeholder="Institution Location"
                  />
                  <br />

                  <input
                    type="file"
                    name="file"
                    id="file-upload"
                    onChange={handle_file_select}
                    accept=".jpg, .jpeg, .png, .pdf"
                    // style={{ display: "none" }}
                  />
                  <br/>
                  <br/>
                  <div className="text-center">
                    <button
                      className="btn btn-outline-primary w-100"
                      type="submit"
                    >
                      {props.isSubmitting ? "Loading.." : "Add"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
      </div>
    </div>
  );
};

export default EditCertifications;
