import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import matchService from "../../../services/match";
import { Spinner } from "react-bootstrap";
import userService from "../../../services/user";
import PreviewCandidate from "./PreviewCandidate";
import { useDispatch } from "react-redux";
import {
  set_loading_false,
  set_loading_true,
} from "../../../reducers/loadingReducer";

const CandidateDetail = () => {
  const history = useHistory();
  const [filtered_js_data, set_filtered_js_data] = useState(null);
  const filtered_interested_position_ID = useParams().sugID;
  const job_listing_ID = useParams().listID;
  const dispatch = useDispatch();

  useEffect(() => {
    userService
      .getJSasCandidate(filtered_interested_position_ID)
      .then((new_filtered_interested) => {
        set_filtered_js_data(new_filtered_interested);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filtered_interested_position_ID]);

  const handleAccept = async (e) => {
    e.preventDefault();
    dispatch(set_loading_true());
    const payload = {
      interested_position_id: filtered_interested_position_ID,
      job_listing_id: job_listing_ID,
      company_approved: true,
    };
    try {
      const data = await matchService.makeOffer(payload);
      alert(
        `You accepted ${filtered_js_data.user.first_name} for the position.
        We just sent your job offer to ${filtered_js_data.user.first_name} 
        and we are waiting for his acceptance`
      );
      history.push(`/jp/opening/${job_listing_ID}/approved/${data.id}`);
      window.location.reload();
      dispatch(set_loading_false());
    } catch (error) {
      dispatch(set_loading_false());
      alert(`Problem accepting candidate!`);
    }
  };

  const handleDecline = async (e) => {
    e.preventDefault();
    dispatch(set_loading_true());

    const payload = {
      interested_position_id: filtered_interested_position_ID,
      job_listing_id: job_listing_ID,
      company_approved: false,
    };
    try {
      await matchService.makeOffer(payload);
      alert(`You declined ${filtered_js_data.user.first_name}`);
      history.push(`/jp/opening/${job_listing_ID}/filtered`);
      window.location.reload();
      dispatch(set_loading_false());
    } catch (error) {
      dispatch(set_loading_false());
      alert(`Problem accepting candidate!`);
    }
  };

  if (!filtered_js_data) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  } else
    return (
      <div className="logged-in-container">
        {/* <div style={{width:'90%', margin:'auto', padding:20, minWidth:'800px'}} >             */}

        <PreviewCandidate
          js_data={filtered_js_data}
          filtered_position_id={filtered_interested_position_ID}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
        />
      </div>
      // </div>
    );
};

export default CandidateDetail;
