import userService from '../../services/user'
import { errorAlert, successAlert } from '../alertReducer'
import { set_loading_false, set_loading_true } from '../loadingReducer'


const certificationReducer = (state=[], action) => {
    switch (action.type){

        case 'INIT_CERTIFICATIONS':
            return action.data

        case 'ADD_NEW_CERTIFICATION':
            return [...state, action.data]
            
        case 'DELETE_CERTIFICATION':
            const new_state = state.filter(c => c.id.toString() !== action.id.toString())
            return new_state
        default:
            return state
    }
}

export const init_certifications = () => {
    return async dispatch => {
        try{
            const data = await userService.getCertifications()
            dispatch({
                type:'INIT_CERTIFICATIONS',
                data
            })
        }
        catch(exception){
            console.log("Error fetching Certification");
        }
    }
}


export const add_certification= (payload, formData) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await userService.addCertification(payload)

            dispatch({
                type:'ADD_NEW_CERTIFICATION',
                data
            })
            try{
                await userService.uploadCertificate(formData, data.id)
                dispatch(successAlert(`Certificate added successfully!`));
            }
            catch(exception){
                dispatch(errorAlert(`Certificate added successfully, but you didn't upload any document!`));

            }
            dispatch(init_certifications())

            dispatch(set_loading_false())
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(errorAlert(`Cannot add Certificate!`));
            dispatch(set_loading_false())

            // setTimeout(()=>dispatch(errorAlert("")),3000);
        }
    }
}

export const delete_certification = (id) => {
    return async dispatch => {
        try{
            await userService.deleteCertification(id)

            dispatch({
                type:'DELETE_CERTIFICATION',
                id
            })
        }
        catch(exception){
            console.log("Error deleting Certification");
        }
    }
}




export default certificationReducer;