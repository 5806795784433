import { DeleteOutline } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resume } from "../../../theme";
import AddPosition from "./AddPositionJS";
import EditIcon from "@material-ui/icons/Edit";
import UpdateInterestedPosition from "./UpdateInterestedPosition";



const ResumeAddPositions = () => {
  const dispatch = useDispatch();
  const interestedJobs = useSelector(({ interestedJobs }) => interestedJobs);

  const [position, set_position] = useState(null);
  const [show_menu, set_show_menu] = useState(false);
  const [show_edit, set_show_edit] = useState(false);
  const addMenuRef = useRef()
  const handle_menu = (e) => {
    e.preventDefault()
    set_show_menu(!show_menu)
    setTimeout(()=>scrollToDiv(),100) 
  }
  const scrollToDiv = () => addMenuRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <div className="logged-in-container">
        {(show_edit && position) ? (
        <UpdateInterestedPosition
          set_show_edit={set_show_edit}
          position={position}
        />
      ) : null}
      <div className="resume-item-page">
      <p className="font-weight-bold text-secondary h5 mt-4"> My Interested Positions </p>
      <div className="text-left">

      {interestedJobs.length > 0 ? (
            <div className="resume-item-page-added">
              {interestedJobs.map((e) => (
                <div key={e.id}>
                  <table style={resume.experience_table}>
                    <tbody>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ ...resume.titles, marginTop: "10px" }}
                        >
                          {e.position.name}
                          <button
                            onClick={(ev) => {
                                ev.preventDefault();
                                set_position(e);
                                set_show_edit(true);
                            }}
                            style={resume.edit_button}
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                   

                      <tr>
                        <td>Start Date</td>
                        <td style={resume.e_table_info}>
                          {e.start_date
                            ? new Intl.DateTimeFormat("en-US").format(
                                Date.parse(e.start_date)
                              )
                            : "Not provided"}
                        </td>
                      </tr>

                      <tr>
                        <td>End Date</td>
                        <td style={resume.e_table_info}>
                          {e.end_date
                            ? new Intl.DateTimeFormat("en-US").format(
                                Date.parse(e.end_date)
                              )
                            : "Current"}
                        </td>
                      </tr>

                      <tr>
                        <td>Daily Desired hours</td>
                        <td style={resume.e_table_info}>{e.daily_desired_hours}</td>
                      </tr>

                      <tr>
                        <td>Desired hourly rate</td>
                        <td style={resume.e_table_info}>{e.desired_rate_hour}</td>
                      </tr>

                      <tr>
                        <td>Job length</td>
                        <td style={resume.e_table_info}> {e.job_time_length_quantity} {e.job_time_length}(s)</td>
                      </tr>
                      <tr>
                        <td>Job type</td>
                        <td style={resume.e_table_info}>{e.job_all_length}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : null}

        <div className="text-center">
            <button onClick={handle_menu} className="btn btn-outline-primary mt-4">Add new position</button> 
        </div>
        <div ref={addMenuRef}></div>

        {show_menu && <AddPosition/> }
      </div>

      </div>
    </div>
  );
};

export default ResumeAddPositions;
