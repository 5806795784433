import userService from '../services/user'

const languagesReducer = (state=[], action) => {
    switch (action.type){

        case 'GET_JOB_SEEKER_LANGUAGES':
            return action.data
        
        case 'ADD_JOB_SEEKER_LANGUAGES':
            return [...state, action.data]
        
        case 'DELETE_JOB_SEEKER_LANGUAGE':
            const new_state = state.filter(p => p.id.toString() !== action.data.id.toString())
            return new_state

        default:
            return state
    }
}

export const get_seeker_languages = () => {
    return async dispatch => {
        try{
           
            const data = await userService.getUserLanguages()
            console.log(data);
            dispatch({
                type:'GET_JOB_SEEKER_LANGUAGES',
                data
            })
        }
        catch(exception){
            console.log(exception);
            console.log("Error getting job seeker languages");
        }
    }
}


export const add_job_seeker_languages = (payload) => {
    return async dispatch => {
        try{
            await userService.addUserLanguages(payload)
            dispatch(get_seeker_languages())
        }
        catch(exception){
            // console.log(exception);
            console.log("Error getting job seeker languages");
        }
    }
}



export const delete_job_seeker_language = (id) => {
    return async dispatch => {
        try{
           
            await userService.deleteUserLanguage(id)
            const data = {id}
            dispatch({
                type:'DELETE_JOB_SEEKER_LANGUAGE',
                data
            })
        }
        catch(exception){
            // console.log(exception);
            console.log("Error deleting language");
        }
    }
}



export default languagesReducer;