import React, { useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import SingleSkill from "../../../Shared/SingleSkill";
import { Button } from "react-bootstrap";
import { add_job_seeker_languages, delete_job_seeker_language } from "../../../../reducers/languagesReducer";

const styles = {
  skill_list: {
    display: "flex",
    flexWrap: "wrap",
  },
  year_input: {
    width: "60px",
    background: "none",
    border: "2px solid green",
    borderRadius: "8px",
    paddingLeft: "7px",
    height: "40px",
  },
};

const EditLanguages = () => {
  const dispatch = useDispatch();

  const languages = useSelector(({ general }) =>
    general.languages.map((language) => {
      return { value: language.id, label: language.name };
    })
  );

  const my_languages = useSelector(({ job_seeker_languages }) => job_seeker_languages);

  // Make sure user doesn't choose the same skill twice
  const handleLanguage = (choice) => {
    const nxt_language = choice.value;
    if (
      !my_languages.some((language_info) => language_info.language.id === nxt_language)
    ) {
      add_language(choice.value)
    } else {
      alert(`You already chose the language ${choice.label}`);
    }
  };

  const delete_your_language = (e, language_id) => {
    e.preventDefault();
    dispatch(delete_job_seeker_language(language_id));
  };

  const add_language = (language_id) => {
    const languages_to_add = [{language_id}]
    dispatch(add_job_seeker_languages(languages_to_add));
  };


  return (
    <div className="logged-in-container">
      <div className="resume-item-page">

      <p className="font-weight-bold text-secondary h5">
          My Languages
        </p>
        <div className="text-left">
          <Select onChange={handleLanguage} options={languages} />
          <br />
        </div>
      
        <div style={styles.skill_list}>
          {my_languages.map((language_info) => {
            return (
              <SingleSkill
                key={language_info.id}
                label={language_info.language.name}
                delete={(e) => delete_your_language(e, language_info.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EditLanguages;
