import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userReducer from './reducers/userReducer'
import jobOpeningReducer from './reducers/jobOpeningReducer'
import companyReducer from './reducers/companyReducer'
import interestedJobsReducer from './reducers/interestedJobsReducer'
import alertReducer from './reducers/alertReducer'
import autoReducer from './reducers/autocompleteReducer'
import jsReducer from './reducers/jsReducer'
import experienceReducer from './reducers/jsResume/experienceReducer'
import educationReducer from './reducers/jsResume/educationReducer'
import licenseReducer from './reducers/jsResume/licenseReducer'
import certificationReducer from './reducers/jsResume/certificationReducer'
import sidebarReducer from './reducers/sidebarReducer'
import skillReducer from './reducers/skillReducer'
import languagesReducer from './reducers/languagesReducer'
import loadingReducer from './reducers/loadingReducer'


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['general','interestedJobs', 
              'job_seeker_info', 'work_experiences',
              'education', 'licenses', 'certifications',
              'sidebar_size','job_seeker_skills', 'job_seeker_languages']
}

const rootReducer = combineReducers({
  user: userReducer,
  jobOpenings: jobOpeningReducer,
  company: companyReducer,
  interestedJobs: interestedJobsReducer,
  alert: alertReducer,
  general: autoReducer,
  job_seeker_info: jsReducer,
  work_experiences: experienceReducer,
  education: educationReducer,
  licenses: licenseReducer,
  certifications: certificationReducer,
  sidebar_size: sidebarReducer,
  job_seeker_skills: skillReducer,
  job_seeker_languages: languagesReducer,
  is_loading:loadingReducer
})


// Store below

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(
    applyMiddleware(thunk)  )
)

const persistor = persistStore(store)

export  {store, persistor}