import React from 'react'
import { useField} from 'formik'

const FormikDateInput = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return(
      <div className='text_input__group'>
        <p className='text-input-label'>{label}</p>
        <input type='date' className='form-control' label={label}  {...field} {...props} />
        {/* <br/> */}
        {meta.touched && meta.error ? (<div className='invalid-feedback'>{meta.error}</div> ): null}
      </div>
    );
  }

export default FormikDateInput;