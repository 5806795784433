import React, { useEffect, useState } from "react";
import EditCertifications from "./ManageProfile/Resume/EditCertifications";
import EditEducation from "./ManageProfile/Resume/EditEducation";
import EditLicences from "./ManageProfile/Resume/EditLicences";
import EditWorkExperiences from "./ManageProfile/Resume/EditWorkExperiences";
import EditSkills from "./ManageProfile/Resume/EditSkills";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { update_js_info } from "../../reducers/jsReducer";
import { useHistory } from "react-router";
import {
  set_loading_false,
  set_loading_true,
} from "../../reducers/loadingReducer";
import { sidebar_change_size } from "../../reducers/sidebarReducer";
import EditLanguages from "./ManageProfile/Resume/EditLanguages";
import ResumeAddPositions from "./InterestedPosition/ResumeAddPositions";

const sections = [
  "Education",
  "Certification",
  "Licence",
  "Experience",
  "Skills",
  "Languages",
  "Interested Positions",
  "Preview",
];
const CreateResume = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [section_number, set_section_number] = useState(0);
  const [section, set_section] = useState(sections[section_number]);
  const profile_completed = useSelector(
    ({ job_seeker_info }) => job_seeker_info.completed_profile
  );
  const interestedJobs = useSelector(({ interestedJobs }) => interestedJobs);

  useEffect(() => {
    dispatch(set_loading_true());
    if (profile_completed === true) {
      dispatch(set_loading_false());
      dispatch(sidebar_change_size(200));
      history.push("/js");
    }
    dispatch(set_loading_false());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_completed]);

  const nextSection = (e) => {
    e.preventDefault();
    set_section(sections[section_number + 1]);
    set_section_number(Math.min(section_number + 1, sections.length));
  };
  const prevSection = (e) => {
    e.preventDefault();
    set_section(sections[section_number - 1]);
    set_section_number(Math.max(section_number - 1, 0));
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const payload = {
      completed_profile: true,
    };
    console.log(payload);
    dispatch(update_js_info(payload));
  };

  return (
    <div className="logged-in-container">
      <ProgressBar
        now={(100 / (sections.length - 1)) * section_number}
        style={{ marginBottom: "30px" }}
      />

      {section === "Preview" ? (
        <p style={{ textAlign: "center" }} className="font-weight-bold text-secondary h5 mt-4">
          RESUME OVERVIEW
        </p>
      ) : null}
      <hr />
      {section === "Education" || section === "Preview" ? (
        <EditEducation />
      ) : null}
      {section === "Certification" || section === "Preview" ? (
        <EditCertifications />
      ) : null}
      {section === "Licence" || section === "Preview" ? <EditLicences /> : null}
      {section === "Experience" || section === "Preview" ? (
        <EditWorkExperiences />
      ) : null}
      {section === "Skills" || section === "Preview" ? <EditSkills /> : null}
      {section === "Languages" || section === "Preview" ? <EditLanguages /> : null}
      {section === "Interested Positions" || section === "Preview" ? <ResumeAddPositions /> : null}

      

      <div style={{ textAlign: "center" }}>
        {section_number > 0  ? (
          <button onClick={prevSection} className="btn btn-light w-25">
            back
          </button>
        ) : null}

        {section_number < sections.length - 1 ? (
          (interestedJobs.length <1 && section === "Interested Positions") 
          ? <p className="text-info">You need at least one interested position to continue!</p>
          :
          <button onClick={nextSection} className="btn btn-primary w-25">
            next
          </button>
        ) : null}

        {section === "Preview" ? (
        <button onClick={handleConfirm} className="btn btn-success w-25">
          Confirm
        </button>
        ) : null}
      </div>
    </div>
  );
};

export default CreateResume;
