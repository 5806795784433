import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import matchService from "../../../services/match";
import { Spinner } from "react-bootstrap";
import {
  set_loading_true,
  set_loading_false,
} from "../../../reducers/loadingReducer";
import Congrats from "./Congrats";
import { useDispatch } from "react-redux";

import { errorAlert } from "../../../reducers/alertReducer";
import OfferForm from "./OfferForm";

const OfferDetail = () => {
  const [offer, setOffer] = useState(null);
  const [listing, setListing] = useState(null);
  const [accepted, set_accepted] = useState(false);
  const dispatch = useDispatch();
  const offerID = useParams().offerID;

  useEffect(() => {
    matchService
      .getOfferByID(offerID)
      .then((offer) => {
        setOffer(offer);
        setListing(offer.job_listing);
        // alert(JSON.stringify(offer.job_seeker_approved))
        set_accepted(offer.job_seeker_approved);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [offerID]);

  if (!offer || !listing) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  }

  const handleAccept = async (e) => {
    e.preventDefault();
    dispatch(set_loading_true());

    const payload = {
      participants: [
        offer.job_listing.company.jobprovider,
        offer.interested_position.job_seeker.id,
      ],
      match_id: offer.id,
    };

    try {
      await matchService.approveSuggestionFromJS(offer.id);
      await matchService.createChat(payload);
      alert(`You accepted the Job offer from ${listing.company.name}`);

      window.location.reload();
      dispatch(set_loading_false());
    } catch (error) {
      dispatch(set_loading_false());
      dispatch(errorAlert("This offer is expired, please refresh the page!"));
    }
  };

  const handleDecline = async (e) => {
    e.preventDefault();
    dispatch(set_loading_true());

    try {
      await matchService.declineSuggestionFromJS(offer.id);
      alert(`You declined the Job offer from ${listing.company.name}`);

      window.location.reload();
      dispatch(set_loading_false());
    } catch (error) {
      dispatch(set_loading_false());
      alert(
        `There was a problem declining the offer from ${listing.company.name}`
      );
    }
  };

  return (
    <div
      className="logged-in-container text-center w-100"
      style={{
        height: "200vh",
        backgroundImage: "linear-gradient(to left, #00cbf8, #009cee)",
      }}
    >
      {offer.match_status === "Accepted" ? (
        <Congrats listing={listing} />
      ) : (
        <p style={{ color: "#ffffff", fontWeight: 700 }}>
          Company {listing.company.name} is happy to offer you the job position{" "}
          {listing.position.name}
        </p>
      )}

      {accepted ? null : (
        <OfferForm
          handleAccept={handleAccept}
          handleDecline={handleDecline}
          listing={listing}
        />
      )}
    </div>
  );
};

export default OfferDetail;
