import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { resume_item } from "../../../../../validations/resume";
import FormikTextInput from "../../../../ReusableComponents/FormikTextInput";
import FormikCreatableSelect from "../../../../ReusableComponents/FormikCreatableSelect";
import userService from "../../../../../services/user";
import { useDispatch, useSelector } from "react-redux";
import { init_licenses } from "../../../../../reducers/jsResume/licenseReducer";
import { errorAlert, successAlert } from "../../../../../reducers/alertReducer";
import { format_file } from "../EditLicences";

const UpdateLicence = ({ licence, set_show_edit }) => {
  const dispatch = useDispatch();
  const [file, set_file] = useState(null);

  const handleClose = () => {
    set_show_edit(false);
  };
  const handle_file_select = (e) => {
    set_file(e.target.files[0]);
  };

  const licenses_options = useSelector( ({general}) => general.licenses.map( lic => {
    return { value: lic.id, label: lic.name }
  }))

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit licence</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            title: {name:licence.title},
            year_achieved: licence.year_achieved,
            institution: licence.institution,
            institution_location: licence.institution_location,
          }}
          validationSchema={resume_item}
          onSubmit={async (values, { setSubmitting }) => {
            // Reset Form
            setSubmitting(false);
            const payload = {
              title: values.title.name,
              year_achieved: values.year_achieved,
              institution: values.institution,
              institution_location: values.institution_location,
            };
            const formData = new FormData();
            formData.append("file", file);

            userService
              .updateLicence(payload, licence.id)
              .then((data) => {
                handleClose();
                dispatch(init_licenses());
                dispatch(successAlert("Updated Successfully"));
                setTimeout(() => {
                  dispatch(errorAlert(""));
                }, 2000);
              })
              .catch((error) => dispatch(errorAlert("Problem updating")));

            userService
              .uploadLicense(formData, licence.id)
              .then((data) => dispatch(init_licenses()))
              .catch((err) => console.log("Error uploading!"));
          }}
        >
          {(props) => (
              <Form className="text-left m-auto" >
              <FormikCreatableSelect
                  // label='Country'
                  options={licenses_options}
                  name="title"
                  placeholder={"Title"}
                  value={{label:props.values.title.name, value:props.values.title.name}}
              />
              <FormikTextInput
                name="year_achieved"
                placeholder="Year Achieved"
              />
              <FormikTextInput name="institution" placeholder="Institution" />
              <FormikTextInput
                name="institution_location"
                placeholder="Institution Location"
              />
              <br />
              {licence.document && (
                <>
                  {" "}
                  <br />
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={licence.document}
                  >
                    {format_file(licence.document)}
                  </a>
                  <br />{" "}
                </>
              )}

              <input
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={handle_file_select}
                type="file"
              />

              <br />
              <br />
              <div className="text-center">
                <button
                    className="btn btn-outline-primary w-100"
                    type="submit"
                  >
                    {props.isSubmitting ? "Loading.." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateLicence;
