import React from 'react'
import {Modal, Button} from 'react-bootstrap'

const NotesModal = ({notes, ok, show}) => {

   if (!notes) return null
    return (
      <Modal
        style={{width:'100%',margin:'auto'}}
        show={show}
      >
        <Modal.Header>
          <Modal.Title>Reasons declined</Modal.Title>
        </Modal.Header>

        <div className='custom-modal-content'>
          {!notes.length && <p>No notes</p>}
          <ul>
              {notes.map((r,idx) => 
                <li style={{fontWeight: 600}} key={idx}>
                  {r.title}<br/>
                  <span style={{fontWeight: 300}} > - {r.reason}</span>
              </li>
            )}
          </ul>
        </div>

        <Modal.Footer>
          
          <Button onClick={ok} variant="primary">Ok</Button>
        </Modal.Footer>
      </Modal>
      )
}

export default NotesModal;