import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import RangeSlider from "../../../ReusableComponents/RangeSlider";

const styles = {
  slide: {
    width: "470px",
    backgroundColor: "#249afa",
  },
};

const RequiredSkillsExperience = (props) => {
  const handle_range = (value, id) => {
    const updated_skill = props.required_skills.find((s) => s.id === id);

    const updated_skill_payload = {
      years_of_experience: value,
      id: updated_skill.id,
      name: updated_skill.name,
      importance: updated_skill.importance,
    };
    // const new_years_of_experience = e.target.value

    const updated_skills = props.required_skills.map((s) =>
      s.id === id ? updated_skill_payload : s
    );

    props.set_required_skills(updated_skills);
  };

  return (
    <div>
      <p className="font-weight-bold text-secondary mb-6">
        {/* Assign Years of experience per required skills */}
        Assign Years of experience per required experiences
        <InfoIcon className="float-right" />
      </p>

      {props.required_skills.map((s) => (
        <div key={s.id}>
          <p className="font-weight-bold text-secondary">{s.name}</p>
          <RangeSlider
            style={styles.slide}
            maxValue={20}
            minValue={0}
            step={1}
            value={s.years_of_experience}
            onChange={(value) => handle_range(value, s.id)}
            formatLabel={(value) => `${value} Years`}
          />
          <br />
          <br />
        </div>
      ))}
      <div>
        {props.sectionNumber > 0 ? (
          <button className="btn btn-light w-50" onClick={props.prevSection}>
            Previous
          </button>
        ) : null}
        {props.sectionNumber < props.sections.length - 1 ? (
          <button className="btn btn-primary w-50" onClick={props.nextSection}>
            Next
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default RequiredSkillsExperience;
