import React  from 'react'
import {Link} from 'react-router-dom'

const styles = {

    label:{
        margin:'20px',
        fontSize: '16px',
        color: '#445566',
        marginBottom:'5px'
    },
    value:{
        color: '#839eaf',
        fontSize: '16px',
        lineHeight: 1.5,
        marginLeft:'20px',
    },
    delete_label:{
        color:'#ee5566',
        fontSize: '16px',
        marginLeft:'20px'
    }
}


const DocumentTab = () => {

    
    return (
            <form className='home-tab'>

                <div> 
                    <p style={styles.label}>Payment Info</p> 
                    <p style={styles.value}>Bank Details</p>
                </div>
                <hr/>

                <div> 
                    <Link to='/js/edit/education' style={styles.label}>Education</Link> 
                    <p style={styles.value}>Education Details</p>
                </div>
                <hr/>

                <div> 
                    <Link to='/js/edit/certifications' style={styles.label}>Certifications</Link> 
                    <p style={styles.value}>Certifications Details</p>
                </div>
                <hr/>

                <div> 
                    <Link to='/js/edit/licences' style={styles.label}>Licenses</Link> 
                    <p style={styles.value}>Licenses Details</p>
                </div>
        
                <hr/>

                <div> 
                    <Link to='/js/legal' style={styles.label}>Legal Agreements</Link> 
                    <p style={styles.value}>The following terms and conditions...</p>
                </div>
          
                
            </form>
    )
}

export default DocumentTab
