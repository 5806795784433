import React, { useEffect, useState } from "react";
import userService from "../../../services/user";
import SingleNotification from "./SingleNotification";

const Notifications = () => {
  const [notifications, set_notifications] = useState([]);

  useEffect(() => {
    userService
      .get_notifications()
      .then((data) => {
        set_notifications(data);
    })
      .catch((error) => {
        console.log("Error");
      });

  }, []);

  return (
    <div>
      <div>
        {notifications.map(
          (notification) => 
            <SingleNotification 
                notification={notification} 
                set_notifications={set_notifications}
                notifications={notifications}
                key={notification.id}
            />)}
      </div>
    </div>
  );
};

export default Notifications;
