import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import ListOpenings from "./ListOpenings";


const LandingOpenings = () => {
  return (
    <div style={{width:'100%'}} className='logged-in-container'>
      <Tabs
        style={{backgroundColor: "white"}}
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
      >
        <Tab eventKey="profile" title="Job Listing">
          <ListOpenings />
        </Tab>

        <Tab eventKey="preferences" title="Summary"></Tab>
      </Tabs>
    </div>
  );
};

export default LandingOpenings;
