import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import FormikTextInput from "../../ReusableComponents/FormikTextInput";
import FormikDropDown from "../../ReusableComponents/FormikDropDown";
import { update_listing } from "../../../validations/company";
import FormikTextArea from "../../ReusableComponents/FormikTextArea";
import FormikDate from "../../ReusableComponents/FormikDate";
import { update_job_listing } from "../../../reducers/jobOpeningReducer";
import {
  validate_canada_zip,
  validate_us_zip,
} from "../../../validations/GenralValidations";



const JAL_CHOICES = [
  { value: "Permanent", label: "Permanent" },
  { value: "Temporary", label: "Temporary" },
  {
    value: "Either Permanent or Temporary",
    label: "Either Permanent or Temporary",
  },
];

const JOB_TYPE_CHOICES = [
  { value: "Rent", label: "Rent" , isDisabled: true},
  { value: "Hire", label: "Hire" },
  { value: "Either Rent or Hire", label: "Either Rent or Hire", isDisabled: true },
];

const JTL_CHOICES = [
  { value: "Hour", label: "Hour" },
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Piece of work", label: "Piece of work" },
  { value: "Permanent", label: "Permanent" },
];

const SHIFT_CHOICES = [
  { value: "Shift 1", label: "Shift 1" },
  { value: "Shift 2", label: "Shift 2" },
  { value: "Shift 3", label: "Shift 3" },
];

const REMOTE_CHOICES = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const EditOpening = ({ set_show_edit, posting }) => {
  const dispatch = useDispatch();
  const general = useSelector(({ general }) => general);

  const [countries, setCountries] = useState([]);

  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [zip_error, set_zip_error] = useState(false);
  const [show_error, set_show_error] = useState(false);
  const [address_error, set_address_error] = useState(false);

  const validate_address = (country, province, city) => {
    const country_id = general.countries.find(c => Number(c.id) === Number(country.id)).id
    const province_id = general.provinces.find(c => Number(c.id) === Number(province.id)).id
    const province_country_id = general.provinces.find(c => Number(c.id) === Number(province.id)).country.id
    const city_province_id = general.cities.find(c => Number(c.id) === Number(city.id)).province.id
    if(country_id === province_country_id && province_id === city_province_id){
        set_address_error(0)
        return true
    }
    set_address_error(1)
    return false
}

  useEffect(() => {
    if (general.length !== 0) {
      const countryArray = general.countries.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setCountries(countryArray);
      const cityArray = general.cities.map((pos) => {
        return { value: pos.id, label: pos.name, province: pos.province };
      });
      setCities(cityArray);
      const provinceArray = general.provinces.map((pos) => {
        return { value: pos.id, label: pos.name, country: pos.country };
      });
      setProvinces(provinceArray);
    }
  }, [general]);

  const handleClose = () => {
    set_show_edit(false);
  };

  if (posting)
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Listing - {posting.position.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              job_all_length: posting.job_all_length,
              job_type: posting.job_type,
              is_remote: posting.is_remote,
              job_time_length: posting.job_time_length,
              job_time_length_quantity: posting.job_time_length_quantity,
              nr_employees_wanted: posting.nr_employees_wanted,
              daily_hours: posting.daily_hours,
              rate_per_hour: posting.rate_per_hour,
              shift: posting.shift,
              address: posting.address,
              zip_code: posting.zip_code,
              city: posting.city,
              start_date: posting.start_date ? posting.start_date : new Date(),
              province: posting.province,
              country: posting.country,
              approve_yourself: posting.approve_yourself,
              instructions: posting.instructions,
              other_notes: posting.other_notes,
            }}
            validationSchema={update_listing}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // resetForm()
              setSubmitting(false);

              set_zip_error(
                values.country.name === "USA"
                  ? !validate_us_zip(values.zip_code)
                  : !validate_canada_zip(values.zip_code)
              );
              const payload = {
                job_all_length: values.job_all_length.name,
                job_type: values.job_type.name,
                is_remote: values.is_remote.id,
                job_time_length: values.job_time_length.name,
                job_time_length_quantity: values.job_time_length_quantity,
                nr_employees_wanted: values.nr_employees_wanted,
                daily_hours: values.daily_hours,
                rate_per_hour: values.rate_per_hour,
                shift: values.shift.name,
                start_date: values.start_date,
                address: values.address,
                zip_code: values.zip_code,
                city_id: values.city.id,
                province_id: values.province.id,
                country_id: values.country.id,
                approve_yourself: values.approve_yourself,
                instructions: values.instructions || 'No',
                other_notes: values.other_notes || 'No'
              };

              if (
                validate_address(values.country, values.province, values.city) &&
                (
                  (values.country.name === "USA" && validate_us_zip(values.zip_code)) ||
                  (values.country.name === "Canada" && validate_canada_zip(values.zip_code))
                )
              ) {
                dispatch(update_job_listing(payload, posting.id));
                set_show_edit(false);
                set_show_error(false);
              } else {
                set_show_error(true);
              }
            }}
          >
            {(props) => (
              <Form>
                <FormikDropDown
                  info={1}
                  label={"Permanent/Temporary"}
                  options={JAL_CHOICES}
                  name="job_all_length"
                  value={JAL_CHOICES.find(
                    (c) => c.value === props.values.job_all_length
                  )}
                />

                <FormikDropDown
                  info={1}
                  label={"Employment type"}
                  options={JOB_TYPE_CHOICES}
                  name="job_type"
                  value={JOB_TYPE_CHOICES.find(
                    (c) => c.value === props.values.job_type
                  )}
                  disabled
                />

                <FormikDropDown
                  info={1}
                  label={"Can be performed remotely"}
                  options={REMOTE_CHOICES}
                  name="is_remote"
                  value={REMOTE_CHOICES.find(
                    (c) => c.value === props.values.is_remote
                  )}
                />

                <FormikDropDown
                  info={1}
                  label={"Job Period"}
                  options={JTL_CHOICES}
                  name="job_time_length"
                  value={JTL_CHOICES.find(
                    (c) => c.value === props.values.job_time_length
                  )}
                />

                <FormikTextInput
                  info={1}
                  label={"Unit"}
                  type="number"
                  name="job_time_length_quantity"
                />

                <FormikDate name="start_date" label="Start Date" />

                <FormikDropDown
                  info={1}
                  label={"Shift"}
                  options={SHIFT_CHOICES}
                  name="shift"
                  value={SHIFT_CHOICES.find(
                    (c) => c.value === props.values.shift
                  )}
                />

                <FormikTextInput
                  info={1}
                  label={"Number of employees wanted"}
                  type="number"
                  name="nr_employees_wanted"
                />

                <div className="d-flex justify-content-between">
                  <FormikTextInput
                    name="daily_hours"
                    type="number"
                    label="Daily hours"
                  />
                  <FormikTextInput
                    name="rate_per_hour"
                    type="number"
                    label="Compensation $/h"
                  />
                </div>
                <br />
                <hr />

                <FormikDropDown
                  info={1}
                  label="Address"
                  options={countries}
                  name="country"
                  placeholder={"Country"}
                  value={countries.find(
                    (pos) => pos.value === props.values.country?.id
                  )}
                />
                <FormikDropDown
                  options={provinces.filter(
                    (province) =>
                      province.country.id === props.values.country?.id
                  )}
                  name="province"
                  placeholder={"Province"}
                  value={provinces.find(
                    (pos) => pos.value === props.values.province?.id
                  )}
                />
                <FormikDropDown
                  options={cities.filter(
                    (city) => city.province.id === props.values.province?.id
                  )}
                  name="city"
                  placeholder={"City"}
                  value={cities.find(
                    (pos) => pos.value === props.values.city?.id
                  )}
                />
                <div className="d-flex justify-content-between">
                  <FormikTextInput name="address" placeholder="Address" />
                  <FormikTextInput name="zip_code" placeholder="ZIP/Postal" />
                </div>
                {zip_error && show_error && (
                  <p className="invalid-feedback text-right">
                    Invalid ZIP for {props.values.country.name}
                  </p>
                )}
                { address_error ? <p className='invalid-feedback text-right'>Please check Country, Province and City </p> : null}

                <FormikTextInput
                  info={1}
                  type="checkbox"
                  name="approve_yourself"
                  label="Approve youself?"
                />

                <FormikTextArea
                  info={1}
                  label={"Instructions"}
                  name="instructions"
                />

                <FormikTextArea
                  info={1}
                  label={"Other Notes"}
                  name="other_notes"
                />

                <br />
                <br />
                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100">
                    {props.isSubmitting ? "Loading..." : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  else return "loading...";
};

export default EditOpening;
