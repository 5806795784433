import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import jobService from "../../../services/jobs";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import {successAlert, errorAlert} from '../../../reducers/alertReducer'
import { delete_interested_position } from "../../../reducers/interestedJobsReducer";
import UpdateInterestedPosition from "./UpdateInterestedPosition";

const styles = {
  td: {
    padding: "7px",
    fontWeigth: "bold",
    textAlign: "left",
  },

  header: {
    textAlign: "center",
  },
  edit: {
    padding: "10px",
    width: "90%",
    backgroudColor: "rgb(55,55,55)",
  },
  delete: {
    padding: "10px",
    width: "60%",
  },
  info: {
    textAlign: "right",
  },
};

const InterestedPositionDetail = () => {
  const [position, set_position] = useState(null);
  const pos_id = useParams().posID;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    jobService
      .getInterestedById(pos_id)
      .then((pos) => {
        set_position(pos);
      })
      .catch((error) => {
        console.log("Error");
      });
  }, [pos_id]);

  const handle_delete = (e) => {
    e.preventDefault();
    const ok = window.confirm("Are you sure?");
    if (ok) {
      dispatch(delete_interested_position(pos_id));
      history.push("/js/mypositions");
    }
  };

  const [show_edit, set_show_edit] = useState(false);
  const handle_edit_click = (ev, e) => {
    ev.preventDefault();
    set_show_edit(true);
  };

  if (!position) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  }

  return (
    <div className="logged-in-container">
      {show_edit ? (
        <UpdateInterestedPosition
          set_show_edit={set_show_edit}
          position={position}
        />
      ) : null}

      <div
        style={{
          textAlign: "center",
          width: "40%",
          margin: "auto",
          minWidth: "400px",
          padding: 20,
        }}
      >
        <table style={{ minWidth: "400px" }}>
          <tbody>
            <tr>
              <td style={styles.td}>Position</td>
              {/* <input value={position.position} /> */}
              <td style={styles.info}> {position.position.name}</td>
            </tr>

            <tr>
              <td style={styles.td}>Start date</td>
              <td style={styles.info}>
                {position.start_date
                  ? new Intl.DateTimeFormat("en-US").format(
                      Date.parse(position.start_date)
                    )
                  : "Not provided"}
              </td>
            </tr>

            <tr>
              <td style={styles.td}>Finish date</td>
              <td style={styles.info}>
                {position.end_date
                  ? new Intl.DateTimeFormat("en-US").format(
                      Date.parse(position.end_date)
                    )
                  : "Not provided"}
              </td>
            </tr>

            <tr>
              <td style={styles.td}>Work is</td>
              <td style={styles.info}> {position.job_all_length}</td>
            </tr>

            <tr>
              <td style={styles.td}>Job Time Length</td>
              <td style={styles.info}> {position.job_time_length}</td>
            </tr>

            <tr>
              <td style={styles.td}>Desired daily hours</td>
              <td style={styles.info}> {position.daily_desired_hours}</td>
            </tr>

            <tr>
              <td style={styles.td}>Desired rate per hour</td>
              <td style={styles.info}> ${position.desired_rate_hour}</td>
            </tr>

            <div className="text-center">
              <button onClick={handle_delete} className="btn btn-light w-50">
                Delete
              </button>
              <button onClick={handle_edit_click} className="btn btn-primary w-50">
                Edit
              </button>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InterestedPositionDetail;
