import userService from '../../services/user'
import { errorAlert, successAlert } from '../alertReducer'
import { set_loading_false, set_loading_true } from '../loadingReducer'


const educationReducer = (state=[], action) => {
    switch (action.type){

        case 'INIT_EDUCATIONS':
            return action.data

        case 'ADD_NEW_EDUCATION':
            return [...state, action.data]
            
        case 'DELETE_EDUCATION':
            const new_state = state.filter(e => e.id.toString() !== action.id.toString())
            return new_state
        default:
            return state
    }
}

export const init_educations = () => {
    return async dispatch => {
        try{
            const data = await userService.getEducations()
            dispatch({
                type:'INIT_EDUCATIONS',
                data
            })
        }
        catch(exception){
            console.log("Error fetching Education");
        }
    }
}


export const add_education = (payload, formData) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await userService.addEducation(payload)

            dispatch({
                type:'ADD_NEW_EDUCATION',
                data
            })

            // dispatch(upload_education(formData, data.id))
            try{
                await userService.uploadEducation(formData, data.id)
                dispatch(successAlert(`Education added successfully!`));
            }
            catch(exception){
                dispatch(errorAlert(`Education added successfully, but you didn't upload any document!`));
            }
            dispatch(init_educations())
            dispatch(set_loading_false())
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert(`Cannot add Education!`));
            // setTimeout(()=>dispatch(errorAlert("")),3000);
        }
    }
}

export const delete_education = (id) => {
    return async dispatch => {
        try{
            await userService.deleteEducation(id)

            dispatch({
                type:'DELETE_EDUCATION',
                id
            })
        }
        catch(exception){
            console.log("Error deleting Education");
        }
    }
}


export default educationReducer;