import React, { useState } from "react";
import Address from "./Sections/Address";
import General from "./Sections/General";
import Length from "./Sections/Length";
import { useSelector } from "react-redux";
import OpeningSummary from "./OpeningSumary";
import Skills from "./Sections/Skills";
import { ProgressBar } from "react-bootstrap";
import StartCompany from "../JobProvider/StartCompany";
import Instructions from "./Sections/Instructions";
import RequiredSkillsExperience from "./Sections/SkillsInfo/RequiredSkillsExperience";
import OptionalSkillsExperience from "./Sections/SkillsInfo/OptionalSkillsExperience";
import RequiredSkillsImportance from "./Sections/SkillsInfo/RequiredSkillsImportance";
import OptionalSkillsImportance from "./Sections/SkillsInfo/OptionalSkillsImportance";
import Certifications from "./Sections/Certifications";

const sections = [
  "General",
  "Job Location Address *",
  "Length",
  "Experiences & Skills",
  "Required Experiences",
  "Rate required experiences",
  "Skills",
  "Rate Skills",
  "Certifications & Licenses",
  "Other Notes and Instructions",
  // "Terms and Conditions",
];

const styles = {
  info: {
    float: "right",
    fontSize: 20,
  },
};

const useField = (type) => {
  const [value, setValue] = useState("");

  const onChange = (event) => {
    setValue(event.target.value);
  };

  return {
    type,
    value,
    setValue,
    onChange,
  };
};

const useOptionButton = (type) => {
  const [value, setValue] = useState("");

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onClick = (e, new_val) => {
    e.preventDefault();
    setValue(new_val);
  };
  return {
    type,
    value,
    onChange,
    onClick,
  };
};

const CreateJobOpening = () => {
  const [sectionNumber, setSectionNumber] = useState(0);
  const [section, setSection] = useState(sections[sectionNumber]);

  const company = useSelector(({ company }) => company);

  const job_all_length = useOptionButton("text"); // permanent/temporary
  const job_type = useOptionButton("text"); // hire/rent
  const number_of_employees_wanted = useField("number");
  const address = useField("text");
  const zip_code = useField("text");
  const [position, set_position] = useState({ id: null, name: null });

  const [city, set_city] = useState({ id: null, name: null });
  const [province, set_province] = useState({ id: null, name: null });
  const [country, set_country] = useState({ id: null, name: null });

  const [app_yourself, set_approve_yourself] = useState(false);
  const [is_remote, set_is_remote] = useState(null);

  const [required_skills, set_required_skills] = useState([]);
  const [optional_skills, set_optional_skills] = useState([]);
  const [required_languages, set_required_languages] = useState([]);
  const [optional_languages, set_optional_languages] = useState([]);

  const [certifications, set_certifications] = useState([])
  const [licenses, set_licenses] = useState([])

  const daily_hours = useField("number");
  const rate_per_hour = useField("number");
  const instructions = useField("text");
  const other_notes = useField("text");
  const [online_session, set_online_session] = useState(false);

  const [shift, set_shift] = useState("");
  const [job_time_length, set_job_time_length] = useState(0); // hour, day, week etc.
  const job_time_length_quantity = useField("number"); // quantity of hours, days, weeks etc
  const [start_date, set_start_date] = useState(new Date());

  const [showSummary, setShowSummary] = useState(false);

  const nextSection = (e = 0) => {
    if (e) e.preventDefault();

    if (section === "Rate required experiences" && optional_skills.length === 0) {
      setSection(sections[sectionNumber + 3]);
      setSectionNumber(Math.min(sectionNumber + 3, sections.length));
    } else {
      setSection(sections[sectionNumber + 1]);
      setSectionNumber(Math.min(sectionNumber + 1, sections.length));
    }
  };

  const prevSection = (e) => {
    e.preventDefault();
    // If optional skills is empty, then move to required skills
    if (section === "Instructions" && optional_skills.length === 0) {
      setSection(sections[sectionNumber - 3]);
      setSectionNumber(Math.max(sectionNumber - 3, 0));
    } else {
      setSection(sections[sectionNumber - 1]);
      setSectionNumber(Math.max(sectionNumber - 1, 0));
    }
  };

  const handleApprove = (e) => {
    e.preventDefault();
    setShowSummary(true);
  };

  if (!company) {
    return (
      <div>
        <StartCompany />
      </div>
    );
  }

  if (showSummary) {
    return (
      <OpeningSummary
        position={position}
        job_all_length={job_all_length}
        is_remote={is_remote}
        job_type={job_type}
        number_of_employees_wanted={number_of_employees_wanted}
        app_yourself={app_yourself}
        address={address}
        city={city}
        province={province}
        country={country}
        shift={shift}
        zip_code={zip_code}
        daily_hours={daily_hours}
        rate_per_hour={rate_per_hour}
        instructions={instructions}
        job_time_length={job_time_length}
        job_time_length_quantity={job_time_length_quantity}
        setShowSummary={setShowSummary}
        required_skills={required_skills}
        optional_skills={optional_skills}
        required_languages={required_languages}
        optional_languages={optional_languages}
        certifications={certifications}
        licenses={licenses}
        online_session={online_session}
        start_date={start_date}
        other_notes={other_notes}
      />
    );
  }

  return (
    <div className="logged-in-container">
      <ProgressBar now={(100 / sections.length) * sectionNumber}/>
      <br/>

      <div className="job-opening-add-div shadow">
        <p className="font-weight-bold text-secondary h5 mt-4 mb-4">{section}</p>

        {/* General section */}
        {section === "General" ? (
          <General
            position={position}
            set_position={set_position}
            job_type={job_type}
            job_all_length={job_all_length}
            is_remote={is_remote}
            set_is_remote={set_is_remote}
            number_of_employees_wanted={number_of_employees_wanted}
            app_yourself={app_yourself}
            set_approve_yourself={set_approve_yourself}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {/* Address section */}
        {section === "Job Location Address *" ? (
          <Address
            country={country}
            set_country={set_country}
            city={city}
            set_city={set_city}
            province={province}
            set_province={set_province}
            address={address}
            zip_code={zip_code}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {/* Job Length section */}
        {section === "Length" ? (
          <Length
            shift={shift}
            set_shift={set_shift}
            daily_hours={daily_hours}
            rate_per_hour={rate_per_hour}
            job_time_length={job_time_length}
            set_job_time_length={set_job_time_length}
            job_time_length_quantity={job_time_length_quantity}
            start_date={start_date}
            set_start_date={set_start_date}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {section === "Experiences & Skills" ? (
          <Skills
            required_skills={required_skills}
            set_required_skills={set_required_skills}
            optional_skills={optional_skills}
            set_optional_skills={set_optional_skills}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
            required_languages={required_languages}
            set_required_languages={set_required_languages}
            optional_languages={optional_languages}
            set_optional_languages={set_optional_languages}
          />
        ) : null}

        {section === "Required Experiences" ? (
          <RequiredSkillsExperience
            required_skills={required_skills}
            set_required_skills={set_required_skills}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {section === "Rate required experiences" ? (
          <RequiredSkillsImportance
            required_skills={required_skills}
            set_required_skills={set_required_skills}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {section === "Skills" ? (
          <OptionalSkillsExperience
            optional_skills={optional_skills}
            set_optional_skills={set_optional_skills}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {section === "Rate Skills" ? (
          <OptionalSkillsImportance
            optional_skills={optional_skills}
            set_optional_skills={set_optional_skills}
            styles={styles}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            nextSection={nextSection}
          />
        ) : null}

        {/* Instructions */}
        {section === "Other Notes and Instructions" ? (
          <Instructions
            instructions={instructions}
            other_notes={other_notes}
            sectionNumber={sectionNumber}
            setSectionNumber={setSectionNumber}
            sections={sections}
            prevSection={prevSection}
            online_session={online_session}
            set_online_session={set_online_session}
            handleApprove={handleApprove}
          />
        ) : null}

        {section === "Certifications & Licenses" ? (
          <Certifications
            certifications = {certifications}
            set_certifications = {set_certifications}
            licenses={licenses}
            set_licenses={set_licenses}
            prevSection={prevSection}
            nextSection={nextSection}
            /> 
        ): null}

        {/* Terms and Conditions section */}
        {/* {section === "Terms and Conditions" ? (
          <TandC prevSection={prevSection} handleApprove={handleApprove} />
        ) : null} */}

      </div>
    </div>
  );
};

export default CreateJobOpening;
