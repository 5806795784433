import { Formik, Form } from "formik";
import React, { useState } from "react";
import FormikTextInput from "../ReusableComponents/FormikTextInput";

const OptionButton = (props) => {
  return (
    <button
      className="option-button"
      style={{
        backgroundColor: props.checked ? "#249afa" : "white",
        color: props.checked ? "white" : "black",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const PayInvoice = () => {
  const [method, set_method] = useState("");

  return (
    <div className="logged-in-container">
      <br/>
      <div className="form-width-size m-auto">
        <Formik
          initialValues={{
            card_number: "",
            name_on_card: "",
            exp_date: "",
            ccv: "",
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            resetForm();
            setSubmitting(false);
            const payload = {
              card_number: values.card_number,
              name_on_card: values.name_on_card,
              exp_date: values.exp_date,
              ccv: values.ccv,
            };
            console.log(payload);
          }}
        >
          {(props) => (
            <Form>
              <p className="text-input-label">Payment method</p>
              <div style={{ textAlign: "center" }}>
                <OptionButton
                  style={{ width: "33%" }}
                  label={"Credit Card"}
                  checked={method === "Credit Card"}
                  onClick={(e) => {
                    e.preventDefault();
                    set_method("Credit Card");
                  }}
                />
                <OptionButton
                  style={{ width: "33%" }}
                  label={"Debit Card"}
                  checked={method === "Debit Card"}
                  onClick={(e) => {
                    e.preventDefault();
                    set_method("Debit Card");
                  }}
                />
                <OptionButton
                  style={{ width: "33%" }}
                  label={"Cheque"}
                  checked={method === "Cheque"}
                  onClick={(e) => {
                    e.preventDefault();
                    set_method("Cheque");
                  }}
                />
              </div>
              <FormikTextInput name="card_number" label="Card Number" />
              <FormikTextInput name="name_on_card" label="Name on card" />
              <div className="multiple-form-in-row">
                <FormikTextInput name="exp_date" label="Exp. date" />
                <FormikTextInput name="ccv" label="CCV" />
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <button className="btn btn-primary w-50" type="submit">
                  {props.isSubmitting ? "Loading..." : "Pay now"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PayInvoice;
