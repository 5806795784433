import React from 'react'
import {useField} from 'formik'
import InfoIcon from '@material-ui/icons/Info'

const FormikTextArea = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return(
      <div className='text_input__group'>
        <p className='text-input-label'>{label} {props.info ? <InfoIcon style={{float:'right'}}/> : null}</p>
        <textarea type='text' className='form-control' label={label}  {...field} {...props}></textarea>
        {/* <br/> */}
        {meta.touched && meta.error ? (<div className='invalid-feedback'>{meta.error}</div> ): null}
      </div>
    );
  }

export default FormikTextArea;