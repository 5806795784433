import React from 'react'
import {Form} from 'react-bootstrap'

const style = {
    // width: '500px',
    height: '48px',
    fontFamily:'Helvetica Neue',
    backgroundColor: '#ffffff',
    // padding: '12px 27px 12px 15px',
    fontSize:'16px'
}

const TextInput = (props) => (

    <Form.Group controlId={props.label}>
        <Form.Label style={props.label_style}>{props.label}</Form.Label>
        <Form.Control disabled={props.disabled?true:false} style={style} type={props.type} value={props.value} onChange={props.handleChange} placeholder={props.placeholder} />
    </Form.Group>

)

export default TextInput;