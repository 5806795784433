import React from "react";
import { Link } from "react-router-dom";

const MenuJS = () => {
  return (
    <div className="logged-in-container">
      <div>
        <div id="flex-container">
          <div id="flex-div">
            <h3>
              {" "}
              <Link to="/messages">Messages</Link>{" "}
            </h3>
          </div>

          <div id="flex-div">
            <h3>Documents</h3>
          </div>
        </div>

        <div id="flex-container">
          <div id="flex-div">
            <h3>Earnings</h3>
          </div>

          <div id="flex-div">
            <h3>Help</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuJS;
