import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { job_listing } from "../../../theme";
import Select from "react-select";
import SingleSkill from "../../Shared/SingleSkill";
import jobService from '../../../services/jobs'
import { get_all_job_openings } from "../../../reducers/jobOpeningReducer";

const EditLanguages = ({ set_show, old_required_languages, old_optional_languages, listing_id }) => {
  const handleClose = () => {
    set_show(false);
  };
  const dispatch = useDispatch()
  const [languages, set_languages] = useState([]);

  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const languages_array = general.languages.map((lan) => {
        return { value: lan.id, label: lan.name };
      });
      set_languages(languages_array);
    }
  }, [general]);

  const [required_languages, set_required_languages] = useState(old_required_languages)
  const [optional_languages, set_optional_languages] = useState(old_optional_languages)

  const handleRequiredLanguage = (choice) => {
    const nxt_language = choice.value;

    if (
      !required_languages.some((lan) => lan.language.id === nxt_language) &&
      !optional_languages.some((lan) => lan.language.id === nxt_language)
    ){
      jobService.addListingToLanguage({
          language_id: nxt_language,
          listing_id: Number(listing_id),
          required:true
      }).then(data => {
          set_required_languages([...required_languages, data])
          dispatch(get_all_job_openings());
        })
        .catch(err=> console.log(err))
    }
    else alert(`You already picked language ${choice.label}`);
  }

  const handleOptionalLanguage = (choice) => {
    const nxt_language = choice.value;

    if (
      !required_languages.some((lan) => lan.language.id === nxt_language) &&
      !optional_languages.some((lan) => lan.language.id === nxt_language)
    ){
        jobService.addListingToLanguage({
            language_id: nxt_language,
            listing_id: Number(listing_id),
            required:false
        }).then(data => {
            set_optional_languages([...optional_languages, data])
            dispatch(get_all_job_openings());
          })
        .catch(err=> console.log(err))
    }
    else alert(`You already picked language ${choice.label}`);
  }


  const handleDeleteOptionalLanguage = (e, lang_id) => {
    e.preventDefault();
    
    jobService.deleteListingToLanguage(lang_id)
        .then(()=>{
            set_optional_languages(
                optional_languages.filter((s) => s.id !== lang_id)
              );
        })
  };


  const handleDeleteRequiredLanguage = (e, lang_id) => {
    e.preventDefault();
 
    jobService.deleteListingToLanguage(lang_id)
        .then(()=>{
            set_required_languages(
                required_languages.filter((s) => s.id !== lang_id)
              );
        })

  };


  const handleSaveLanguages = (e) => {
      e.preventDefault()
      set_show(false)
  }

  
  return (
    <Modal show={true} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title> Edit Listing Languages </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <p className="font-weight-bold text-secondary"> Required Languages </p>
      <Select onChange={handleRequiredLanguage} options={languages} />
      <div style={job_listing.skill_list}>
        {required_languages.map((language) => {
          return (
            <SingleSkill
              delete={handleDeleteRequiredLanguage}
              key={language.id}
              label={language.name ? language.name : language.language.name}
              language_id={language.id}
            />
          );
        })}
      </div>

      <br/>

      <p className="font-weight-bold text-secondary"> Optional Languages </p>
      <Select onChange={handleOptionalLanguage} options={languages} />
      <div style={job_listing.skill_list}>
        {optional_languages.map((language) => {
          return (
            <SingleSkill
              delete={handleDeleteOptionalLanguage}
              key={language.id}
              label={language.name ? language.name : language.language.name}
              language_id={language.id}
            />
          );
        })}
      </div>

      <button onClick={handleSaveLanguages} className="btn btn-success">
         Done
      </button>
            
    </Modal.Body>
    </Modal>
    );
};

export default EditLanguages;
