import * as Yup from 'yup'

export const register_validation = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().strict(false).trim().email('Please enter a valid email').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
    confirm_email: Yup.string().strict(false).trim().email().required('Required'),
    phone_number: Yup
        .string('Required')
        .required('Required')
        .matches(/^[0-9]*$/, 'Should contain only digits!')
        .length(10, 'Should be exactly 10 digits!'),
})

export const register_validation_js = Yup.object({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().strict(false).trim().email('Please enter a valid email').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
    confirm_email: Yup.string().strict(false).trim().email().required('Required'),
    phone_number: Yup
        .string('Required')
        .required('Required')
        .matches(/^[0-9]*$/, 'Should contain only digits!')
        .length(10, 'Should be exactly 10 digits!'),

})