import generalService from '../services/general'


const autoReducer = (state=[], action) => {
    switch (action.type){

        case 'FETCH_INFO':
            return action.data
            
        default:
            return state
    }
}

export const init_autocomplete = () => {
    return async dispatch => {
        try{
        
            const countries = await generalService.listCountries()
            const cities = await generalService.listCities()
            const skills = await generalService.listSkills()
            const positions = await generalService.listPositions()
            const provinces = await generalService.listProvinces()
            const languages = await generalService.listLanguages()
            const licenses = await generalService.listLicenses()
            const certificates = await generalService.listCertificates()

            const data = {
                countries,
                cities,
                skills,
                positions,
                provinces,
                languages,
                licenses,
                certificates
            }

            dispatch({
                type:'FETCH_INFO',
                data
            })
        }
        catch(exception){
            console.log("Error fetching countries and cities");
        }
    }
}


export default autoReducer;