import React from "react";
import { useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';

import { job_listing } from "../../../theme";
import SingleSkill from "../../Shared/SingleSkill";

const Certifications = (props) => {

  const certificate_options = useSelector( ({general}) => general.certificates.map( cert => {
    return { value: cert.id, label: cert.name }
  }))

  const licenses_options = useSelector( ({general}) => general.licenses.map( lic => {
    return { value: lic.id, label: lic.name }
  }))

  const handleChooseCertification = (choice) => {
    const nxt_certification = choice.value;

    if (
      !props.certifications.some((cert) => cert.id === nxt_certification)
    )
      props.set_certifications([
        ...props.certifications,
        {
          id: choice.value,
          name: choice.label
        }
      ]);
    else alert(`You already picked certification ${choice.label}`);
  }


  const handleDeleteCertification = (e, cert) => {
    e.preventDefault();
    props.set_certifications(
      props.certifications.filter((s) => s.name !== cert)
    );
  };


  const handleChooseLicense = (choice) => {
    const nxt_license = choice.value;

    if (
      !props.licenses.some((cert) => cert.id === nxt_license)
    )
      props.set_licenses([
        ...props.licenses,
        {
          id: choice.value,
          name: choice.label
        }
      ]);
    else alert(`You already picked license ${choice.label}`);
  }

  const handleDeleteLicense = (e, license) => {
    e.preventDefault();
    props.set_licenses(
      props.licenses.filter((s) => s.name !== license)
    );
  };


  return (
    <div>
  

      <p className="font-weight-bold text-secondary"> Required Certifications </p>
      <CreatableSelect onChange={handleChooseCertification} options={certificate_options} />
      <div style={job_listing.skill_list}>
        {props.certifications.map((cert) => {
          return (
            <SingleSkill
              delete={handleDeleteCertification}
              key={cert.id}
              label={cert.name}
            />
          );
        })}
      </div>

      <br/>

      <p className="font-weight-bold text-secondary"> Required Licenses </p>
      <CreatableSelect onChange={handleChooseLicense} options={licenses_options} />
      <div style={job_listing.skill_list}>
        {props.licenses.map((license) => {
          return (
            <SingleSkill
              delete={handleDeleteLicense}
              key={license.id}
              label={license.name}
            />
          );
        })}
      </div>



      <div className='mt-4'>
        <button className="btn btn-light w-50" onClick={props.prevSection}>
          Previous
        </button>
        <button
          className="btn btn-primary w-50"
          onClick={props.nextSection}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Certifications;
