import React, { useEffect, useState } from "react";
import userService from "../../../services/user";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { errorAlert, successAlert } from "../../../reducers/alertReducer";
import {
  set_loading_true,
  set_loading_false,
} from "../../../reducers/loadingReducer";
import ExtensionOfferTabs from "../Offers/Extension/ExtensionOfferTabs";
import Notifications from "../../Shared/Notification/Notifications";

const PassiveIncome = () => {
  const dispatch = useDispatch();
  const [referrals, set_referrals] = useState([]);

  useEffect(() => {
    userService.get_referrals().then((referrals) => {
      set_referrals(referrals);
    });
  }, []);

  const handle_resend = async (e, id) => {
    e.preventDefault();
    dispatch(set_loading_true());
    try {
      await userService.resend_email_referral(id);
      dispatch(successAlert("Successfully resend referral"));
      dispatch(set_loading_false());
    } catch (err) {
      dispatch(errorAlert("Error while resending referral"));
      dispatch(set_loading_false());
    }
  };

  return (
    <div className="logged-in-container">
      <ExtensionOfferTabs/>
      <Notifications/>
      <div>
      <p className="font-weight-bold h5 text-secondary mt-4">
        Passive income
      </p>
     <p className="font-weight-bold text-secondary mt-4">
        By inviting other people, you’ll earn 10% of the profit that Rapihire will get from the projects of your invited person for lifetime
      </p>
        <Link to="/js/invitereferral">
          <button
            className="btn btn-primary float-left"
          >
            Invite
          </button>
        </Link>
      </div>
      <br/>
      
      <div className="custom-table-div ">
        <table className="custom-table">
          <thead>
            <tr>
              <td>
                <b>Email referred</b>
              </td>
              <td>
                <b>Status</b>
              </td>
              <td>
                <b>Invitation Date</b>
              </td>
              <td>
                <b></b>
              </td>
              <td className="text-right">
                <b>Bonus</b>
              </td>
              <td className="text-right">
                <b>Bonus Earned</b>
              </td>
            </tr>
          </thead>

          <tbody>
            {referrals.map((referral) => {
              return (
                <tr key={referral.id}>
                  <td>{referral.email}</td>
                  <td>
                    {referral.user ? (
                      <p style={{ color: "green" }}>Joined </p>
                    ) : (
                      <p style={{ color: "orange" }}>Sent</p>
                    )}
                  </td>
                  <td>
                    {new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(Date.parse(referral.date))}
                  </td>
                  <td>
                    {" "}
                    {!referral.user && (
                      <button
                        onClick={(e) => handle_resend(e, referral.id)}
                        style={{ width: 120 }}
                        className="btn btn-primary"
                      >
                        Resend
                      </button>
                    )}
                  </td>
                  <td className="text-right">
                    {referral.referral_type}
                  </td>
                  <td className="text-right">
                    {referral.total_earned.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PassiveIncome;
