import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { job_listing } from "../../../../../theme";
import { work_experiences } from "../../../../../validations/resume";
import FormikTextInput from "../../../../ReusableComponents/FormikTextInput";
import FormikDropDown from "../../../../ReusableComponents/FormikDropDown";
import userService from "../../../../../services/user";

import { useDispatch, useSelector } from "react-redux";
import FormikDateInput from "../../../../ReusableComponents/FormikDate";
import { errorAlert, successAlert } from "../../../../../reducers/alertReducer";
import { init_work_experiences } from "../../../../../reducers/jsResume/experienceReducer";

const OptionButton = (props) => {
  return (
    <button
      style={{
        ...job_listing.button,
        backgroundColor: props.checked ? "#249afa" : "white",
        color: props.checked ? "white" : "black",
        width: "50%",
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const UpdateExperience = ({ experience, set_show_edit }) => {
  const dispatch = useDispatch();
  const [is_current_job, set_is_current_job] = useState(
    experience.is_current_job
  );
  const user_email = useSelector( ({user})=> user.email)
  const [positions, setPositions] = useState([]);
  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const positionsArray = general.positions.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setPositions(positionsArray);
    }
  }, [general]);

  const handleClose = () => {
    set_show_edit(false);
  };

  if (experience && positions)
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              company_name: experience.company_name,
              position: experience.position,
              contact_name: experience.contact_name,
              contact_email: experience.contact_email,
              contact_phone: experience.contact_phone,
              start_date: experience.start_date.substring(0, 10),
              end_date: experience.end_date
                ? experience.end_date.substring(0, 10)
                : undefined,
            }}
            validationSchema={work_experiences}
            onSubmit={async (values, { setSubmitting }) => {
              // resetForm()
              setSubmitting(false);
              const payload = {
                company_name: values.company_name,
                position_id: values.position.id,
                contact_name: values.contact_name,
                contact_email: values.contact_email,
                contact_phone: values.contact_phone,
                start_date: values.start_date,
                end_date: !is_current_job ? values.end_date : null,
                is_current_job,
              };
            
              userService
                .updateExperience(payload, experience.id)
                .then((data) => {
                  dispatch(init_work_experiences());
                  dispatch(successAlert("Updated Successfully"));
                  setTimeout(() => {
                    dispatch(errorAlert(""));
                  }, 2000);
                  handleClose();
                })
                .catch(
                  (error) =>
                    dispatch(errorAlert("Problen updating experience")),
                  setTimeout(() => {
                    dispatch(errorAlert(""));
                  }, 2000)
                );
              }
            }
          >
            {(props) => (
              <Form className="text-left m-auto" >
                <FormikTextInput
                  name="company_name"
                  placeholder="Company Name"
                />
                <FormikDropDown
                  // label='Country'
                  options={positions}
                  name="position"
                  placeholder={"Position"}
                  value={positions.find(
                    (pos) => pos.value === experience.position?.id
                  )}
                />

                <br />
                <p className="text-input-label">Current Job?</p>
                <div style={{ textAlign: "center" }}>
                  <OptionButton
                    label={"No"}
                    checked={is_current_job === false}
                    onClick={(e) => {
                      e.preventDefault();
                      set_is_current_job(false);
                    }}
                  />
                  <OptionButton
                    label={"Yes"}
                    checked={is_current_job === true}
                    onClick={(e) => {
                      e.preventDefault();
                      set_is_current_job(true);
                    }}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <FormikDateInput name="start_date" label="Start date" />
                  {!is_current_job ? (
                    <FormikDateInput name="end_date" label="End date" />
                  ) : null}
                </div>

                <FormikTextInput
                  name="contact_name"
                  placeholder="Reference Name"
                />
                <FormikTextInput
                  name="contact_email"
                  placeholder="Reference Email"
                />
                {user_email === props.values.contact_email && <label className="invalid-feedback"> This cannot be your email! </label>}
                <FormikTextInput
                  name="contact_phone"
                  placeholder="Reference Phone"
                />
                <br />

                <div className="text-center">
                  <button
                      className="btn btn-outline-primary w-100"
                      type="submit"
                      disabled={user_email===props.values.contact_email}
                    >
                      {props.isSubmitting ? "Loading.." : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  else return "loading...";
};

export default UpdateExperience;
