import * as Yup from 'yup'

const  is_number = (char) => !isNaN(parseInt(char));

export const job_seeker_create_profile = Yup.object({
    
    telephone: Yup
        .string()
        .required('Required')
        .matches(/^[0-9]*$/, 'Should contain only digits!')
        .length(10, 'Should be exactly 10 digits!'),
    address: Yup.string().required('Required'),
    // zip_code: Yup.string().required('Required')
    //     .test(value => {
    //         if(!value)return false
    //         return (value.length === 6 && /^\d+$/.test(value) )
    //                     ||
    //         (value.length===6 && !is_number(value[0]) && is_number(value[1])
    //                           && !is_number(value[2]) && is_number(value[3]) 
    //                           && !is_number(value[4]) && is_number(value[5])  )
    //     }),
    city: Yup.object().required('Required').nullable(),
    country: Yup.object().required('Required').nullable(),
    province: Yup.object().required('Required').nullable(),

})

const today = new Date()

export const interested_position = Yup.object({
    
    job_time_length_quantity: Yup.number('Enter a number').required('Required')
        .min(1,'Should be more than 0')
        .max(200, 'Enter a lower number'),

    desired_rate_hour: Yup.number('Enter a number')
        .required('Required')
        .min(1,'Should be more than 0')
        .max(1000, 'Enter a lower number'),

    daily_desired_hours: Yup.number('Enter a number')
        .required('Required')
        .min(1,'Should be more than 0')
        .max(24,'Enter a lower number'),
    start_date: Yup.date().required('Required').min(today, 'Start date cannot be in the past'),

    position: Yup.object().required('Required').nullable(),
    job_time_length: Yup.object().required('Required').nullable(),
})

export const interested_position_permanent = Yup.object({

    desired_rate_hour: Yup.number('Enter a number')
        .required('Required')
        .min(1,'Should be more than 0')
        .max(1000, 'Enter a lower number'),

    daily_desired_hours: Yup.number('Enter a number')
        .required('Required')
        .min(1,'Should be more than 0')
        .max(24,'Enter a lower number'),
    start_date: Yup.date().required('Required').min(today, 'Start date cannot be in the past'),

    position: Yup.object().required('Required').nullable(),
    // job_time_length: Yup.object().required('Required').nullable()
})


export const change_password_validator = Yup.object({
    old_password: Yup.string().required('Required'),
    new_password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match').required('Required')
})