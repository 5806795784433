import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";


const styles = {

    form:{
        width:'500px',
        borderRadius: '4px',
        border: 'solid 1px #ccd7df',
        backgroundColor:'#ffffff'
    },
    label:{
        margin:'20px',
        fontSize: '16px',
        color: '#445566',
        marginBottom:'5px'
    },
    value:{
        color: '#839eaf',
        fontSize: '16px',
        lineHeight: 1.5,
        marginLeft:'20px',
    },
    delete_label:{
        color:'#ee5566',
        fontSize: '16px',
        marginLeft:'20px'
    }
}



const Documents = () => {
  
    const company = useSelector( ({company}) => company )


    return (
        <form className='home-tab' >
           
                
                <div>
                    <p style={styles.label}>Business Number
                    <Link to='/jp/managecompany'>
                        <button className='float-right btn btn-primary'>Edit</button>
                    </Link></p>
                    <p style={styles.value}>{company.business_number}</p>
                </div>
                <hr/>
                <div> 
                <Link to='/jp/legal' style={styles.label}> Terms and Conditions</Link> 
                <p style={styles.value}>         
                  The following terms and conditions...
                </p>
        
                {/* <p style={styles.value}>NDAs, CDAs, MSAs etc</p> */}
                </div>
                
        </form>
    )
}

export default Documents