import axios from 'axios'
import {url} from './utils.js'
let token = null


const baseURL = url

const setToken = (newToken) => {
    token = `token ${newToken}`
}


// OK
const getCandidates = async (position_name) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}matches/candidates/${position_name}`, config)
    return response.data
}

// OK
const getCandidateByID = async (pk) => {
    const response = await axios.get(`${baseURL}matches/candidate/${pk}`,)
    return response.data
}

// OK
const makeOffer = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}matches/makeoffer/`,payload, config)
    return response.data
}

const listApprovedCandidates = async (pk) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}matches/approvedcandidates/${pk}`, config)
    return response.data
}

// OK
const getOfferByID = async (pk) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}matches/offerdetail/${pk}`, config)
    return response.data
}

// OK
const getOffersForJS = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}matches/seeoffers/`, config)
    return response.data
}

// OK
const approveSuggestionFromJS = async (pk) => {
    const config = {
        headers: { Authorization: token},
    }
    const payload = {job_seeker_approved:true, match_status:'Accepted'}
    const response = await axios.patch(`${baseURL}matches/offerdetail/${pk}`, payload, config)
    return response.data
}


// OK
const declineSuggestionFromJS = async (pk) => {
    const config = {
        headers: { Authorization: token},
    }
    const payload = {job_seeker_approved:false, match_status:'Declined'}
    const response = await axios.patch(`${baseURL}matches/offerdetail/${pk}`, payload, config)
    return response.data

}

const createChat = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}messages/chats/`, payload, config)
    return response.data
}


const getChats = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}messages/chats/`, config)
    return response.data
}


const getMessagesByChat = async (chatID, limit) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}messages/lists/${chatID}?limit=${limit}`, config)
    return response.data.results
}

const sendMessage = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.post(`${baseURL}messages/new/`,payload, config)
    return response.data
}

const get_company_suggestions = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const response = await axios.get(`${baseURL}matches/allcandidates/${id}`, config)
    return response.data
}

const stop_contract_jp = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.patch(`${baseURL}matches/stop-contract-jp/`, payload, config)
    return data
}

const stop_contract_js = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.patch(`${baseURL}matches/stop-contract-js/`, payload, config)
    return data
}

const extend_contract_jp = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.post(`${baseURL}matches/extend-contract-jp/`, payload, config)
    return data
}

const approve_contract_extension_js = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.patch(`${baseURL}matches/extend-contract-js-approve/`, payload, config)
    return data
}

const decline_contract_extension_js = async (payload) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.patch(`${baseURL}matches/extend-contract-js-decline/`, payload, config)
    return data
}

const get_extension_offers = async () => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.get(`${baseURL}matches/extension-offers-js/`, config)
    return data
}

const get_extension_detail = async (id) => {
    const config = {
        headers: { Authorization: token},
    }
    const {data} = await axios.get(`${baseURL}matches/extension-offer-js/${id}`, config)
    return data
}


export default {
    getCandidates, getCandidateByID, makeOffer, 
    listApprovedCandidates, setToken, getOfferByID, 
    getOffersForJS, approveSuggestionFromJS, createChat,
    getChats, declineSuggestionFromJS, getMessagesByChat,
    sendMessage, get_company_suggestions, get_extension_offers,
    stop_contract_jp, extend_contract_jp, approve_contract_extension_js,
    get_extension_detail, decline_contract_extension_js, stop_contract_js
}

