import React, { useState } from 'react'
import {Button} from 'react-bootstrap'
import matchService from '../../../services/match'
import moment from 'moment'
import { useRecoilState } from 'recoil'
import {messages_state} from '../../../recoil_state'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const styles = {
  form:{
    width:'100%',
  },
  load:{
    width:'30%',
    margin:'auto'
  }
}

const Chat = ({user, chatter, messages, set_messages, chat_id, limit, set_limit}) => { 

  const [curr_message, set_curr_message] = useState('')
  const [num_of_mess, set_num_of_mess] = useState(4)
  const [msg_state, set_msg_state] = useRecoilState(messages_state)

  const sendMessage = async (e) => {
    e.preventDefault()
    set_num_of_mess(4)

    const payload = {
      chat_id,
      sender_id:user.id,
      receiver_id:chatter.id,
      content: curr_message
    }
    // alert(JSON.stringify(payload))

    try {
      if(curr_message.length > 0){
        const data = await matchService.sendMessage(payload)
        set_messages( [...messages,data] )
        set_curr_message('')
        set_msg_state(msg_state.filter(id => id !== chat_id))

      }
    }
    catch(error){
      console.log("Error sending message!");
    }
  }

  if (!messages) return <div>Loading...</div>


  const renderMessages = () => {

    return(
      <ul id="chat-log">
        {messages.slice(messages.length-num_of_mess,messages.length).map(m=>(
          <li key={m.id} className={chatter.id !== m.receiver.id ? 'sent' :'replies'}>
           <img 
               src={chatter.id === m.receiver.id ? user.profile_image : chatter.profile_image}
               alt="profile-pic" 
          />
          <p>
          {m.content}
            <br />
            <small>{moment(m.ceated_at).fromNow()}</small>
          </p>
          </li>
        )) }
      </ul>
    )
  }

  return (
      <>
     {chatter ?  <div style={styles.load}>
       <Button 
          onClick={ () => 
              {
              set_limit(limit+5)
              set_num_of_mess(Math.min(num_of_mess+5,messages.length))
              }
          } 
          variant='link'
          style={{color:'grey'}}
        >
         <ExpandLessIcon />
        </Button>
      </div>
    :null}
      <div  className="messages">
        {renderMessages()}
        <div style={{ float:"left", clear: "both", padding:'5%' }}
            //  ref={(el) => { setMessagesEnd(el) }}
        >
        </div>
      </div>

    {
      chatter ?
      <div className="message-input">
  
         <form onSubmit={sendMessage} style={styles.form} className="mb-3">
            <input
              style={{width:'80%',height:'40px',borderRadius:'5px',border:'2px'}}
              placeholder="Type your message"
              aria-label="Type your message"
              aria-describedby="basic-addon2"
              value={curr_message}
              onChange={(e)=>{
                set_curr_message(e.target.value)
              }}
              />
          <Button style={{width:'20%'}} type='submit' variant="outline-secondary">Send</Button>
        </form>
      </div>
      : null
    }
      </>
    )
}

export default Chat;