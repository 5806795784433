import axios from 'axios'
import {url} from './utils.js'

const baseURL = url


const listCountries = async () => {
    const response = await axios.get(`${baseURL}countries/`)
    return response.data
}

const listAllCountries = async () => {
    const response = await axios.get(`${baseURL}all_countries/`)
    return response.data
}

const listCities = async () => {
    const response = await axios.get(`${baseURL}cities/`)
    return response.data
}

const listProvinces = async () => {
    const response = await axios.get(`${baseURL}provinces/`)
    return response.data
}

const listSkills = async () => {
    const response = await axios.get(`${baseURL}skills/`)
    return response.data
}

const listPositions = async () => {
    const response = await axios.get(`${baseURL}positions/`)
    return response.data
}

const listLanguages = async () => {
    const response = await axios.get(`${baseURL}languages/`)
    return response.data
}

const listCertificates = async () => {
    const response = await axios.get(`${baseURL}certifcate-autofill/`)
    return response.data
}


const listLicenses = async () => {
    const response = await axios.get(`${baseURL}license-autofill/`)
    return response.data
}

const get_experience_to_rate = async (id) => {
    const response = await axios.get(`${baseURL}getexperiencetorate/${id}`)
    return response.data
}

const reference_rate_job_seeker = async (payload) => {
    const response = await axios.post(`${baseURL}rateexperience/`,payload)
    return response.data
}


export default {listCountries, listAllCountries, listProvinces, listCities, listSkills, listPositions, listCertificates,
        get_experience_to_rate,reference_rate_job_seeker, listLanguages, listLicenses};