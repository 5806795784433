import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import matchService from "../../../../services/match";
import { Spinner } from "react-bootstrap";
import moment from 'moment'
import {
  set_loading_true,
  set_loading_false,
} from "../../../../reducers/loadingReducer";
import { useDispatch } from "react-redux";

import { errorAlert, successAlert } from "../../../../reducers/alertReducer";

const ExtensionDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [extension_detail, set_extension_detail] = useState(null);

  const extension_id = useParams().extension_id;
  useEffect(() => {
    matchService
      .get_extension_detail(extension_id)
      .then((data) => {
        set_extension_detail(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [extension_id]);

  if (!extension_detail) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  }

  const handleAccept = async (e) => {
    e.preventDefault();
    dispatch(set_loading_true())
    try{
      const payload = {
        proposal_id: extension_detail.id
      }
      await matchService.approve_contract_extension_js(payload)
      dispatch(set_loading_false())
      dispatch(successAlert('Successfully approved contract extension!'))
      setTimeout(() => {
        dispatch(successAlert(''))
      }, 4000);
      history.push('/')
    }
    catch(err){
      dispatch(errorAlert('Problem approving! Please try again later!'))
      dispatch(set_loading_false())
    }
  };

  const handleDecline = async (e) => {
    e.preventDefault();
    dispatch(set_loading_true())
    try{
      const payload = {
        proposal_id: extension_detail.id
      }
      await matchService.decline_contract_extension_js(payload)
      dispatch(set_loading_false())
      dispatch(successAlert('Successfully declined contract extension!'))
      setTimeout(() => {
        dispatch(successAlert(''))
      }, 4000);
      history.push('/')
    }
    catch(err){
      dispatch(set_loading_false())
      dispatch(errorAlert('Problem declining! Please try again later!'))
    }
  };

  if(extension_detail){
    if (extension_detail.response_time){
      return <Redirect push to='/'/>
    }
  }

  return (
    <div
      className="logged-in-container"
      style={{
        width: "100%",
        height: "200vh",
        textAlign: "center",
        backgroundImage: "linear-gradient(to left, #00cbf8, #009cee)",
      }}
    >
   
      <p style={{ color: "#ffffff", fontWeight: 700 }}>
        Company {extension_detail?.company_name} is happy to offer an extension for the job position{" "}
        {extension_detail?.position}
      </p>

      <div className="offer-detail-div">

        <p className='profile-create-header'>
          Extension Offer Details
        </p>

        <p style={{color:'gray', fontSize:14}}>
          All terms and conditions stated in the original contract will remain 
          the same for the new dates of this agreement extension except for the
          below changes:
        </p>


        <table className='custom-table'>

          <thead>
            <tr className='align-left-row'>
              <td></td>
              <td className='text-input-label'>Was</td>
              <td className='text-input-label'>Is</td>
            </tr>
          </thead>

          <tbody>
            {extension_detail?.offer.initial_net_payment !== extension_detail?.initial_net_payment &&
            <tr className='align-left-row'>
              <td className='text-input-label'>Rate</td>
              <td>${extension_detail.offer.initial_net_payment?.toFixed(2)}/h</td>
              <td>${extension_detail.initial_net_payment?.toFixed(2)}/h</td>
            </tr>
            }

            {extension_detail?.offer.daily_hours !== extension_detail?.daily_hours &&
            <tr className='align-left-row'>
              <td className='text-input-label'>Daily Hours</td>
              <td>{extension_detail.offer.daily_hours}</td>
              <td>{extension_detail.daily_hours}</td>
            </tr>
            }

            {extension_detail?.offer.end_date !== extension_detail?.end_date &&
            <tr className='align-left-row'>
              <td className='text-input-label'>End Date</td>
              <td>{moment(extension_detail.offer.end_date).format('LL')}</td>
              <td>{moment(extension_detail.end_date).format('LL')}</td>
            </tr>
            }

            {extension_detail?.offer.city.province.country.name !== extension_detail?.country &&
            <tr className='align-left-row'>
              <td className='text-input-label'>Country</td>
              <td>{extension_detail.offer.city.province.country.name}</td>
              <td>{extension_detail.country}</td>
            </tr>
            }

            {extension_detail?.offer.city.province.name !== extension_detail?.province &&
            <tr className='align-left-row'>
              <td className='text-input-label'>Province</td>
              <td>{extension_detail.offer.city.province.name}</td>
              <td>{extension_detail.province}</td>
            </tr>
            }

            {extension_detail?.offer.city.name !== extension_detail?.city &&
            <tr className='align-left-row'>
              <td className='text-input-label'>City</td>
              <td>{extension_detail.offer.city.name}</td>
              <td>{extension_detail.city}</td>
            </tr>}

            {extension_detail?.offer.address !== extension_detail?.address &&
            <tr className='align-left-row'>
              <td className='text-input-label'>Address</td>
              <td>{extension_detail.offer.address}</td>
              <td>{extension_detail.address}</td>
            </tr>}

            {extension_detail?.offer.zip_code!== extension_detail?.zip_code &&
            <tr className='align-left-row'>
              <td className='text-input-label'>ZIP code</td>
              <td>{extension_detail.offer.zip_code}</td>
              <td>{extension_detail.zip_code}</td>
            </tr>}
      
          </tbody>

        </table>

        <div className="mt-4 mb-3 text-center">
          <button onClick={handleDecline} className="btn btn-light w-50">
            Decline
          </button>

          <button onClick={handleAccept} className="btn btn-primary w-50">
            Accept
          </button>
        </div>
        
        <Link to={"/js/"}>View later</Link>

      </div>

    </div>
  );
};

export default ExtensionDetail;
