import React, { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import FormikTextInput from '../../../ReusableComponents/FormikTextInput'

import userService from '../../../../services/user'
import { errorAlert, successAlert } from '../../../../reducers/alertReducer'
import { useDispatch } from 'react-redux'
import { set_loading_false, set_loading_true } from '../../../../reducers/loadingReducer'
import { change_password_validator } from '../../../../validations/seeker_profile'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const ChangePassword = ({set_show_edit}) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        set_show_edit(false)
    }
    const [show_password, set_show_password] = useState(false)
    const tooglePassword = (e) => {
        e.preventDefault()
        set_show_password(!show_password)
    }
    return (
        <Modal
        show={true}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik

            initialValues={{
                old_password:'',
                new_password:'',
                confirm_new_password:'',
            }}

            validationSchema  = {change_password_validator}
            
            onSubmit={async (values, {setSubmitting}) => {

                // resetForm()
                setSubmitting(false)
                dispatch(set_loading_true())
                const payload ={
                    old_password: values.old_password,
                    new_password: values.new_password,
                }
                
                userService.changePassword(payload)
                    .then(data => {
                        dispatch(set_loading_false())
                        handleClose()
                        dispatch(successAlert("Successfully changed password!"))
                    })
                    .catch(err => {
                        dispatch(set_loading_false())
                        handleClose()
                        dispatch(errorAlert("Incorrect old password!"))

                    })
                
            }}
            >
        {props => (
            <Form className="text-left m-auto w-75">
                <div className="pwd-container">
                {   show_password ? <VisibilityOffIcon onClick={tooglePassword} className='icon' /> : <VisibilityIcon onClick={tooglePassword} className='icon'/>}
                </div>
                <FormikTextInput type={ show_password ? 'text' : 'password'} name='old_password' placeholder='Old password'  />
                

                <FormikTextInput type={ show_password ? 'text' : 'password'} name='new_password' placeholder='New password'  />
                <FormikTextInput type={ show_password ? 'text' : 'password'} name='confirm_new_password' placeholder='Confirm new password'  />
                <br />
                <div className="text-center">
                <button variant="link" className="btn btn-primary w-100">
                    {props.isSubmitting ? 'Loading..' : 'Change Password'}
                </button> 
                </div>
            </Form>
        )}

        </Formik>
        </Modal.Body>

      </Modal>
   
    )
}

export default ChangePassword;