import * as Yup from 'yup'

export const resume_item = Yup.object({
    title: Yup.object()
        .required('Required'),
    year_achieved: Yup.string()
        .min(4, 'Enter a valid Year')
        .max(4, 'Enter a valid Year')
        .required('Required'),
    institution: Yup.string()
        .min(4, 'Institution name can\'t be shorter than 5 characters.')
        .max(30, 'Institution name can\'t be longer than 30 characters.')
        .required('Required'),
    institution_location: Yup.string()
        .min(5, 'Institution location can\'t be shorter than 5 characters.')
        .max(30, 'Institution location can\'t be longer than 30 characters.')
        .required('Required'),
})

export const resume_education = Yup.object({
    title: Yup.string()
        .required('Required'),
    year_achieved: Yup.string()
        .min(4, 'Enter a valid Year')
        .max(4, 'Enter a valid Year')
        .required('Required'),
    institution: Yup.string()
        .min(4, 'Institution name can\'t be shorter than 5 characters.')
        .max(30, 'Institution name can\'t be longer than 30 characters.')
        .required('Required'),
    institution_location: Yup.string()
        .min(5, 'Institution location can\'t be shorter than 5 characters.')
        .max(30, 'Institution location can\'t be longer than 30 characters.')
        .required('Required'),
})



const today = new Date()
export const work_experiences =  Yup.object({
    company_name: Yup.string()
        .required('Required')
        .max(30,'Should be less than 30 chars'),

    position: Yup.object()
        .required('Required'),

    contact_name: Yup.string()
        .required('Required')
        .max(30,'Should be less than 30 chars'),

    contact_email: Yup.string()
        .email('Enter a valid email')
        .required('Required'),

    contact_phone: Yup
        .string()
        .required('Required')
        .matches(/^[0-9]*$/, 'Should contain only digits!')
        .length(10, 'Should be exactly 10 digits!'),

    start_date: Yup.date()
        .required('Required')
        .max(today, 'Start date cannot be in the future'),
    
    end_date: Yup.date().default(null)
        .when("start_date",
            (start_date, yup) => start_date && yup.min(start_date, "End time cannot be before start time")).nullable()
})