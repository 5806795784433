import React, { useRef, useState } from "react";
import Togglable from "../../../Togglable";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  add_education,
  delete_education,
} from "../../../../reducers/jsResume/educationReducer";
import { resume } from "../../../../theme";

import FormikTextInput from "../../../ReusableComponents/FormikTextInput";
import { resume_education } from "../../../../validations/resume";
import { DeleteOutline } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import UpdateEducation from "./UpdateResume/UpdateEducation";
import { format_file } from "./EditLicences";

const EditEducation = () => {
  const dispatch = useDispatch();
  const all_educations = useSelector(({ education }) => education);
  const [curr_education, set_curr_education] = useState(null);
  const [show_edit, set_show_edit] = useState(false);

  const [file, set_file] = useState(null);

  const handle_delete = async (e, id) => {
    e.preventDefault();
    const ok = window.confirm("Are you sure you want to delete Education?");
    if (ok) {
      dispatch(delete_education(id));
    }
  };

  const [show_menu, set_show_menu] = useState(false);
  const addMenuRef = useRef()
  const handle_menu = (e) => {
    e.preventDefault()
    set_show_menu(!show_menu)
    setTimeout(()=>scrollToDiv(),100) 
  }
  const scrollToDiv = () => addMenuRef.current.scrollIntoView({ behavior: 'smooth' })

  const handle_file_select = (e) => {
    // Allow only files with .pdf and .jpg, .jpeg and .png extensions
    const file_extension = e.target.files[0].name.split(".").pop();
    if (
      file_extension === "pdf" ||
      file_extension === "jpg" ||
      file_extension === "jpeg" ||
      file_extension === "png"
    ) {
      set_file(e.target.files[0]);
    } else {
      alert("Only .pdf, .jpg, .jpeg and .png files are allowed");
    }
  };

  const handle_edit_click = (ev, e) => {
    ev.preventDefault();
    set_curr_education(e);
    set_show_edit(true);
  };

  return (
    <div className="logged-in-container">
      {show_edit ? (
        <UpdateEducation
          set_show_edit={set_show_edit}
          education={curr_education}
        />
      ) : null}
      <div className="resume-item-page">
        <p className="font-weight-bold text-secondary h5 mt-4"> My Educations </p>

        {all_educations.length > 0 ? (
          <div className="resume-item-page-added">
            {all_educations.map((e) => (
              <div key={e.id}>
                <hr />
                <div className="mt-2 text-muted">
                  <p style={resume.titles}>
                    {e.title}
                    <button
                      onClick={(ev) => handle_delete(ev, e.id)}
                      style={resume.delete_button}
                    >
                      <DeleteOutline />
                    </button>
                    <button
                      onClick={(ev) => handle_edit_click(ev, e)}
                      style={resume.edit_button}
                    >
                      <EditIcon />
                    </button>
                  </p>
                  <p className="p-0 m-0">{e.year_achieved}</p>
                  <p className="p-0">
                    {e.institution}, {e.institution_location},
                    {e.document && (
                      <>
                        {" "}
                        <br />{" "}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={e.document}
                        >
                          {format_file(e.document)}
                        </a>{" "}
                      </>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <button onClick={handle_menu} className="btn btn-outline-primary mt-4">Add new education</button> 
        <div ref={addMenuRef}  ></div>
        {show_menu && 
          <div className="resume-item-page-form shadow">
            <Formik
              initialValues={{
                title: "",
                year_achieved: "",
                institution: "",
                institution_location: "",
              }}
              validationSchema={resume_education}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // Reset Form
                resetForm();
                setSubmitting(false);
                const formData = new FormData();
                formData.append("file", file);
                const payload = {
                  title: values.title,
                  year_achieved: values.year_achieved,
                  institution: values.institution,
                  institution_location: values.institution_location,
                };
                dispatch(add_education(payload, formData));
              }}
            >
              {(props) => (
                <Form className="text-left m-auto w-100">
                   <p className="font-weight-bold text-center text-secondary h6 mt-4"> Add new Education </p>
                  <FormikTextInput name="title" placeholder="Title" />
                  <FormikTextInput
                    name="year_achieved"
                    placeholder="Year Achieved"
                  />
                  <FormikTextInput
                    name="institution"
                    placeholder="Institution"
                  />
                  <FormikTextInput
                    name="institution_location"
                    placeholder="Institution Location"
                  />
                  <br />
                  <input
                    onChange={handle_file_select}
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                  />
                  <br />
                  <br />

                  <div className="text-center">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                    >
                      {props.isSubmitting ? "Loading.." : "Add"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>}
      </div>
    </div>
  );
};

export default EditEducation;
