import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import matchService from "../../../../services/match";

const ExtensionOfferTabs = () => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    matchService
      .get_extension_offers()
      .then((data) => {
        setOffers(data);
    })
      .catch((error) => {
        console.log("Error");
      });

  }, []);

  return (
    <div>
      <div>
        {offers.map(
          (offer) =>
              <div className="new-candidate-card" style={{backgroundColor:'#60c1b1'}} key={offer.id}>
                <div className="new-candidate-card-text">
                  {" "}
                  Company {offer?.company_name} offers you an extension for the position {offer?.position}
                  <Link
                    className="new_candidate-view-button"
                    to={`/js/extensions/${offer?.id}`}
                    style={{color:'#60c1b1'}} 
                  >
                    View Offer
                  </Link>
                </div>
              </div>
        )}
      </div>
    </div>
  );
};

export default ExtensionOfferTabs;
