import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import congrats_icon from "../../../icons/CONGRATS.png";
import Popup from "../../CoreComponents/Popup";
import OfferForm from "./OfferForm";

const Congrats = ({ listing }) => {
  const [show_instructions, set_show_instructions] = useState(false);
  const search = useLocation().search;
  const first_time = new URLSearchParams(search).get("detail");

  if (first_time === "yes") return <OfferForm listing={listing} />;
  else
    return (
      <div className="logged-in-container">
        <Popup
          show={show_instructions}
          title={`Job Instructions`}
          text={listing.instructions}
          ok={(e) => {
            e.preventDefault();
            set_show_instructions(false);
          }}
        />
        <div>
          <img style={{ width: 80 }} alt="congrats" src={congrats_icon} />

          <p
            style={{
              color: "#ffffff",
              fontSize: "20px",
              fontWeight: "bold",
              paddingTop: 60,
              paddingBottom: 60,
            }}
          >
            Congratulations! You accepted this Job Offer at{" "}
            {listing.company.name}
          </p>

          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "#ffffff",
              maxWidth: "600px",
              margin: "auto",
            }}
          >
            Now you can view the job instructions provided by the employer.
            These instructions are sent to your email as well.
            <br />
            Employer might send you messages as well so make sure you reply him
          </p>

          <button
            onClick={(e) => {
              e.preventDefault();
              set_show_instructions(true);
            }}
            style={{
              borderRadius: "4px",
              color: "#249afa",
              fontWeight: "bold",
              width: "280px",
              height: "48px",
              marginTop: "30px",
              border: "none",
            }}
          >
            View Instructions
          </button>
        </div>
      </div>
    );
};

export default Congrats;
