import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import userService from "../../services/user";
import { Form } from "react-bootstrap";
import TextInput from "../ReusableComponents/TextInput";
import { sign_in_up } from "../../theme.js";
import { errorAlert, successAlert } from "../../reducers/alertReducer";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, set_email] = useState("");

  const handleReset = (e) => {
    e.preventDefault();
    const payload = {
      email,
    };

    dispatch(
      successAlert(
        `If the email you entered is correct, an email with instructions will be sent to ${email}.`
      )
    );
    setTimeout(() => dispatch(errorAlert("")), 5000);
    userService
      .forgotPassword(payload)
      .then((ok) => {
        console.log("ok");
      })
      .catch((err) => {
        dispatch(errorAlert(`Problem reseting the password for ${email}.`));
      });
  };

  return (
    <div className="text-right mt-4">

      <Link to="/login" className=" p-5 ">
        Login
      </Link>
      
      <div style={{marginTop:'15vh'}}/>

      <div className="form-width-size m-auto shadow p-4 text-left" >

        <p className="font-weight-bold text-secondary h5">Reset your password</p>

        <Form onSubmit={handleReset}>
          <TextInput
            label={"Enter Email"}
            type={"text"}
            value={email}
            handleChange={(e) => set_email(e.target.value)}
            placeholder={"name@example.com"}
          />

          <div className="text-center mt-4">
            <button type="submit" className="btn btn-primary w-50 font-weight-bold">Reset password</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
