import React from 'react'
import { useField } from "formik";
import CreatableSelect from 'react-select/creatable';
import InfoIcon from '@material-ui/icons/Info'


const DropDown = ({label,...props}) => {

  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
    console.log(field.name);
  return (
    <div className='text_input__group' >
      <p className='text-input-label'>{label} { props.info ?<InfoIcon style={{float:'right'}}/>: null }</p>
      <CreatableSelect
        // isClearable
        options={props.options}
        name={field.name}
        onChange={(option) => setValue({name:option.label,id: option.value})}
        {...props}
      />
    {meta.touched && meta.error ? (<div className='invalid-feedback'>{meta.error}</div> ): null}
    </div>
  );
};

export default DropDown;