import jobService from '../services/jobs'
import { errorAlert, successAlert } from './alertReducer'
import { set_loading_false, set_loading_true } from './loadingReducer'


const jobOpeningReducer = (state=[], action) => {
    switch (action.type){

        // case 'ADD_NEW_JOB_LISTING':
        //     return [...state, action.data]

        case 'INIT_JOBS':
            return action.data

        case 'DELETE_JOB_OPENING_ID':
            const new_state = state.filter(j => j.id.toString() !== action.data.toString())
            return new_state
            
        default:
            return state
    }
}

export const get_all_job_openings = () => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await jobService.listOpenings()
            dispatch({
                type:'INIT_JOBS',
                data
            })
            dispatch(set_loading_false())

        }
        catch(exception){
            // console.log(exception);
            console.log("Error listing job openings");
            dispatch(set_loading_false())
        }
    }
}

export const update_job_listing = (id, payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            await jobService.updateListOpeningById(id, payload)

            dispatch(get_all_job_openings())

            dispatch(set_loading_false())
            dispatch(successAlert('Listing updated successfully!'))
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert(`Cannot update listing!`));
        }
    }
}

export const add_job_listing = (payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            await jobService.createListOpening(payload)

            dispatch(get_all_job_openings())

            dispatch(set_loading_false())
            dispatch(successAlert(`Open Position added successfully!`));
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false());
            dispatch(errorAlert(`There is a problem adding this job position!`));
        }
    }
}


export const delete_job_opening= (id) => {
    return async dispatch => {
        try{
           
            await jobService.deleteListOpeningById(id)
            const data = id
            dispatch({
                type:'DELETE_JOB_OPENING_ID',
                data
            })
        }
        catch(exception){
            // console.log(exception);
            console.log("Error listing interested jobs");
        }
    }
}


export default jobOpeningReducer