import React, { useState } from "react";
import RangeSlider from "../../ReusableComponents/RangeSlider";
import jobsService from "../../../services/jobs";
import { useDispatch } from "react-redux";
import { errorAlert, successAlert } from "../../../reducers/alertReducer";
import { get_all_job_openings } from "../../../reducers/jobOpeningReducer";

const EditSingleSkill = ({ skill }) => {
  const [years_of_experience, set_years_of_experience] = useState(
    skill.years_of_experience
  );
  const [importance, set_importance] = useState(skill.importance);
  const dispatch = useDispatch();

  const handle_update = async (e) => {
    e.preventDefault();
    const payload = {
      years_of_experience,
      importance,
    };
    try {
      await jobsService.updateListingSkills(payload, skill.id);
      dispatch(successAlert("Skill updated successfully!"));
      setTimeout(() => {
        dispatch(successAlert());
      }, 3000);
      dispatch(get_all_job_openings());
    } catch (err) {
      console.log(err);
      dispatch(errorAlert("Problem updating skill! Please try again later!"));
    }
  };

  const handle_delete = async (e) => {
    e.preventDefault();
    const ok = window.confirm("Are you sure you want to delete this skill?");
    if (ok) {
      try {
        await jobsService.deleteListingSkill(skill.id);
        dispatch(successAlert("Skill deleted successfully!"));
        setTimeout(() => {
          dispatch(successAlert());
        }, 3000);
        dispatch(get_all_job_openings());
      } catch (err) {
        console.log(err);
        dispatch(errorAlert("Problem deleting skill! Please try again later!"));
      }
    }
  };

  return (
    <div>
      <label key={skill.id} className="text-input-label">
        {skill.skill.name}
      </label>
      <br />
      <label>
        Years of Experience
      </label>
      <div style={{ width: 300, margin: "auto" }}>
        <RangeSlider
          maxValue={20}
          minValue={0}
          step={1}
          value={years_of_experience}
          onChange={(value) => set_years_of_experience(value)}
          formatLabel={(value) => `${value} Years`}
        />
      </div>
      <br />
      <label>Importance</label>
      <div className='m-auto' style={{ width: 300 }}>
        <RangeSlider
          maxValue={20}
          minValue={0}
          step={1}
          value={importance}
          onChange={(value) => set_importance(value)}
          formatLabel={(value) => `${value}`}
        />
      </div>

    <div className="mt-5">
      <button
        onClick={handle_update}
        className="btn btn-success h-100"
      >
        Update
      </button>
      <button
        onClick={handle_delete}
        className="btn text-danger h-100"
      >
        Delete
      </button>
      </div>
      <hr />
    </div>
  );
};

export default EditSingleSkill;
