import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import matchService from "../../../services/match";

const OfferTabs = () => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    matchService
      .getOffersForJS()
      .then((data) => {
        setOffers(data);
      })
      .catch((error) => {
        console.log("Error");
      });

  }, []);
  return (
    <div>
      <div>
        {offers.map(
          (offer) =>
            offer.match_status === "Pending" && (
              <div className="new-candidate-card" key={offer.id}>
                <div className="new-candidate-card-text">
                  {" "}
                  You have an offer as {offer.interested_position.position.name}
                  <Link
                    className="new_candidate-view-button"
                    to={`/js/offers/${offer.id}`}
                  >
                    View Offer
                  </Link>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default OfferTabs;
