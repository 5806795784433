import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import FormikTextInput from "../../ReusableComponents/FormikTextInput";
import FormikDropDown from "../../ReusableComponents/FormikDropDown";

import { useDispatch, useSelector } from "react-redux";
import FormikDateInput from "../../ReusableComponents/FormikDate";
import { interested_position, interested_position_permanent } from "../../../validations/seeker_profile";
import InfoIcon from "@material-ui/icons/Info";
import { delete_interested_position, update_interested_position } from "../../../reducers/interestedJobsReducer";



const JTL_CHOICES = [
  { value: "Hour", label: "Hour" },
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Piece of Work", label: "Piece of Work" },
];

const UpdateInterestedPosition = ({ position, set_show_edit }) => {
  const dispatch = useDispatch();
  const [positions, setPositions] = useState([]);
  const general = useSelector(({ general }) => general);

  const [job_all_length, set_job_all_length] = useState(
    position.job_all_length
  ); // permanent/temporary

  const my_positions = useSelector(({ interestedJobs }) =>
    interestedJobs.map((job) => job.position.id)
  );

  useEffect(() => {
    if (general.length !== 0) {
      const positionsArray = general.positions.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setPositions(positionsArray);
    }
  }, [general]);

  const handleJobAllLength = (e) => {
    set_job_all_length(e.target.value);
  };

  const handleClose = () => {
    set_show_edit(false);
  };

  const handle_delete = () => {
    dispatch(delete_interested_position(position.id))
    set_show_edit(false);
  }

  const [is_remote, set_is_remote] = useState(position.is_remote)

  const handleRemote = (e) => {
    set_is_remote(e.target.value == 'true');
  };

  if (position)
    return (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Position - {position.position.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p style={job_listing.header}>Add Interested Positions </p> */}

          <Formik
            initialValues={{
              position: {
                value: position.position.id,
                label: position.position.name,
              },
              start_date: position?.start_date?.substring(0, 10),
              // end_date: position?.end_date?.substring(0, 10),
              job_all_length: position.job_all_length,
              daily_desired_hours: position.daily_desired_hours,
              desired_rate_hour: position.desired_rate_hour,
              job_time_length: {
                value: position.job_time_length,
                label: position.job_time_length,
              },
              job_time_length_quantity: position.job_time_length_quantity,
            }}
            validationSchema={job_all_length !== 'Permanent' ? interested_position : interested_position_permanent }
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // resetForm()
              setSubmitting(false);
              console.log(values);
              const payload = {
                position_id: values.position.id,
                start_date: values.start_date,
                // end_date: values.end_date,
                job_time_length: values.job_time_length.name,
                daily_desired_hours: values.daily_desired_hours,
                desired_rate_hour: values.desired_rate_hour,
                job_all_length: job_all_length,
                job_time_length_quantity: values.job_time_length_quantity,
                is_remote
              };

              dispatch(update_interested_position(position.id, payload));
              handleClose();
            }}
          >
            {(props) => (
              <Form>
                <FormikDropDown
                  label={"Position you are interested in"}
                  options={ position.has_matches ? [] : positions.filter(
                    (pos) => !my_positions.includes(pos.value)
                  )}
                  name="position"
                  placeholder={"Position"}
                  value={positions.find((pos) => {
                    return (
                      Number(pos.value) === Number(props.values.position.value)
                    );
                  })}
                />
                <FormikDateInput name="start_date" label="Start Date" />
                <br />
                <div>
                <p className="text-input-label">
                  Employment type?
                  <InfoIcon onClick={()=>alert('Employment Type')} style={{ float: "right", cursor:'pointer' }} />
                </p>
                <input
                  type="radio"
                  value="Permanent"
                  onChange={handleJobAllLength}
                  checked={job_all_length === "Permanent"}
                />{" "}
                Permanent <br />
                <input
                  type="radio"
                  value="Temporary"
                  onChange={handleJobAllLength}
                  checked={job_all_length === "Temporary"}
                />{" "}
                Temporary <br />
                <input
                  type="radio"
                  value="Either Permanent or Temporary"
                  onChange={handleJobAllLength}
                  checked={job_all_length === "Either Permanent or Temporary"}
                />{" "}
                Either Permanent or Temporary <br />
              </div>
              <br/>
              <div>
                <p className="text-input-label">
                    Can the position be performed remotely?
                    <InfoIcon onClick={()=>alert('Can the position be performed remotely?')} style={{ float: "right", cursor:'pointer' }} />
                  </p>
                  <input
                    type="radio"
                    value={true}
                    onChange={handleRemote}
                    checked={is_remote === true}
                  />{" "}
                  Yes <br />
                  <input
                    type="radio"
                    value={false}
                    onChange={handleRemote}
                    checked={is_remote === false}
                  />{" "}
                  No <br />
              </div>
                {
                job_all_length !== 'Permanent' &&
              <>
                <FormikDropDown
                  info={1}
                  label={"Job Period"}
                  options={JTL_CHOICES}
                  name="job_time_length"
                  value={JTL_CHOICES.find((c) => {
                    return c.value === props.values.job_time_length.value;
                  })}
                />
                <FormikTextInput
                  info={1}
                  label={"Unit"}
                  name="job_time_length_quantity"
                />
                </>
              }
                <div className="d-flex justify-content-between">
                  <FormikTextInput
                    name="daily_desired_hours"
                    type="number"
                    label="Daily hours"
                  />
                  <FormikTextInput
                    name="desired_rate_hour"
                    type="number"
                    label="Desired rate $/h"
                  />
                </div>
                <br />
                <br />
                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100">
                    {props.isSubmitting ? "Loading..." : "Update"}
                  </button>
                  
                </div>
              </Form>
            )}
          </Formik>
          <br/>
          <button disabled={position.has_matches} className="btn btn-danger w-100" onClick={handle_delete}>
             {position.has_matches ? 'Cannot delete as this position is matched' : 'Delete' }
          </button>
        </Modal.Body>
      </Modal>
    );
  else return "loading...";
};

export default UpdateInterestedPosition;
