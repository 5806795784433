import React from "react";
import Alert from "react-bootstrap/Alert";

import logo from "../../icons/logo_blue.png";

function RegisterFeedBackPage() {
  return (
    <>
      <Alert show={1} variant="primary">
        <Alert.Heading>
          <a class="navbar-brand" href="https://rapihire.com/">
          <img
            className="w-75"
            alt="raihire logo"
            src={logo}
          />
          </a>
        </Alert.Heading>

        <div class="jumbotron text-center">
          <h1 class="display-3">Thank You!</h1>
          <p class="lead">
            You successfully registered! Please check the confirmation email we
            sent to your email address! <br/>

            If you do not see the validation email, please check your JUNK (or SPAM) folder on your email and click the validation link. If you still do not see it, please contact us at: info@rapihire.com.
          </p>
          <hr></hr>
          <p>
            Having trouble? <a href="https://rapihire.com/#footer">Contact us</a>
          </p>
          <p class="lead">
            <a
              class="btn btn-primary btn-sm"
              href="https://app.rapihire.com/"
              role="button"
            >
              Continue to homepage
            </a>
          </p>
        </div>
      </Alert>
    </>
  );
}

export default RegisterFeedBackPage;
