
export const sign_in_up={
 
    form_title:{
        color:'#445566',
        fontSize:'20px',
        paddingTop:'5%',
        paddingBottom:'5%',
    },
    login_form_title:{
        color:'#445566',
        fontSize:'20px',
        paddingTop:'35%',
        paddingBottom:'5%'
    },
}


export const logged_in = {
    container:{
        width:'80%', 
        margin:'auto', 
        padding:20,
        marginTop:'55px',
    }
}


export const resume = {
    container:{
        width:'80%', 
        margin:'auto', 
        padding:20, 
        // minWidth:'400px'
    },
    titles:{
        fontSize:'16px',
        color:'#445566',
        fontWeight:'bold',
        padding:0,
        marginBottom:0
    },
    experience_table:{
        width:'100%',
        marginTop:'20px'
    },
    e_table_info:{
        textAlign:'right',
        fontSize:'16px',
        fontWeight:500,
        color: '#36454f'
    },
    delete_button:{
        color:'red',
        float:'right',
        border:'none',
        background:'none'
    },
    edit_button:{
        color: '#36454f',
        float:'right',
        border:'none',
        background:'none'
    },
    add_button:{
        width:'100%',
        maxWidth:'800px',
        outlineStyle: 'dotted',
        color:'#249afa'
    }
}


export const job_listing = {
    info_icon:{
        width:'20px',
        height:'20px',
        float:'right'
    },
    text:{
        fontSize:'16px',
        fontWeight:500,
        color:'#36454f'
    },
    radio:{
        weight:'160px'
    },
    skill_list:{
        display:'flex',
        flexWrap:'wrap'
    },
    header:{
        fontSize:'20px',
        fontWeight:'bold',
        color:'#445566'
    },
    button:{
        width:'198px',
        height:'48px',
        borderRadius: '4px',
        border:' solid 1px #a3bbca',
        backgroundColor: '#ffffff',
    },
    textarea:{
        width: '100%',
        height: '200px',
        objectFit: 'contain',
        borderRadius: '4px',
        border: 'solid 1px #a3bbca',
        backgroundColor:' #ffffff',
        padding: '5px'
    },
    three_button:{
        width:'152px',
        height:'48px',
        borderRadius: '4px',
        border:' solid 1px #a3bbca',
        backgroundColor: '#ffffff'
    },
}


export const posting_preview = {
    td:{
        padding:'10px',
        fontWeight:'bold',
        textAlign:'left',
        color:'#445566',
        fontSize: '16px',
    },

    header:{
        textAlign:'center'
    },
    edit_button:{
        padding:'10px',
        width:'230px',
        backgroundColor:'#445566',        
        border:'none',
        color:'#ffffff',
        borderRadius:'4px'

    },
    delete_button:{
        padding:'10px',
        width:'170px',
        border:'none',
        backgroundColor:'#f84071',
        color:'#ffffff',
        borderRadius:'4px'
    }
    ,
    info:{
      textAlign:'right',
      padding:'10px',

    },
}