import React from 'react'
import {useSelector} from 'react-redux'
import { Spinner} from 'react-bootstrap'
import CustomButtom from '../../ReusableComponents/CustomButton'
import {Link} from 'react-router-dom'
import {logged_in} from '../../../theme'
import userService from '../../../services/user'
import { logout } from '../../../reducers/userReducer'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

const styles = {

    delete_label:{
        color:'#ee5566',
        fontSize: '16px',
        marginLeft:'20px'
    }
}

const PreviewCompany = () => {
   
    const company = useSelector( ({company}) => company )
    const history = useHistory()
    const dispatch = useDispatch()

    const onDelete = async (e) => { 
        e.preventDefault()

        const ok = window.confirm("Are you sure? This cannot be undone.");
        if (ok){

          try{
            await userService.delete_account()
            dispatch(logout())
            history.push('/login')
          }
          catch(err){
            console.log(err);
            alert("Problem deactivating account! Please try again later!")
          }
          
        }
    }

    if(company){

        return (
            <div style={{...logged_in.container,width:'90%'}}>
            <div className='company-preview-form'  >            
                <p className="font-weight-bold text-secondary h5 mt-4">{company.name}</p>

                <div> 
                    <p className='company-preview-label'>Name</p> 
                    <p className='company-preview-value'>{company.name}</p>
                </div>
                <hr/>
                <div> 
                    <p className='company-preview-label'>Address</p> 
                    <p className='company-preview-value'>{company.address}, {company.city.name}, {company.province.name}, {company.zip_code}, {company.country.name} </p>
                </div>
                <hr/>
                <div> 
                    <p className='company-preview-label'>Contact Full Name</p> 
                    <p className='company-preview-value'>{company.contact_name}</p>
                </div>
                <hr/>
                <div> 
                    <p className='company-preview-label'>Contact Phone</p> 
                    <p className='company-preview-value'>{company.contact_phone}</p>
                </div>
                <hr/>
                <div> 
                    <p className='company-preview-label'>Contact Email</p> 
                    <p className='company-preview-value'>{company.contact_email}</p>
                </div>
            
                <div style={{textAlign:'center',marginTop:'40px'}}>
                    <Link to='/jp/managecompany'>
                     <CustomButtom label='Update Info' type='submit' />
                    </Link>
                </div>


                <div className='mt-4'> 
                    <p style={styles.delete_label}>
                    <b onClick={onDelete} style={{ cursor:'pointer'}}>Delete Account</b>
                </p>
                </div>

            </div>
            </div>
        )}

        return  (
            <div style={{textAlign:'center', paddingTop:'100px'}}>
                <Spinner animation="border" /> 
            </div>
        )
}

export default PreviewCompany