import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { add_job_listing } from "../../reducers/jobOpeningReducer";

const OpeningSummary = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleConfirm = async (e) => {
    e.preventDefault();

    const payload = {
      position_id: props.position.id,
      job_all_length:
        props.job_all_length === "Temporary" ? "Temporary" : "Permanent",
      job_type: props.job_type.value,
      is_remote: props.is_remote,
      nr_employees_wanted: props.number_of_employees_wanted.value,
      approve_yourself: props.app_yourself,
      address: props.address.value,
      city_id: props.city.id,
      province_id: props.province.id,
      country_id: props.country.id,
      daily_hours: props.daily_hours.value,
      rate_per_hour: props.rate_per_hour.value,
      shift: props.shift,
      zip_code: props.zip_code.value,
      online_session: props.online_session,
      instructions: props.instructions.value,
      other_notes: props.other_notes.value,
      job_time_length: props.job_time_length,
      job_time_length_quantity: props.job_time_length_quantity.value || 1,
      required_skills: props.required_skills.map((s) => s),
      optional_skills: props.optional_skills.map((s) => s),
      start_date: props.start_date,
      required_languages: props.required_languages.map(l => Number(l.id)),
      optional_languages: props.optional_languages.map(l => Number(l.id)),
      required_certifications: props.certifications.map(l => l.id),
      required_licenses: props.licenses.map(l => l.id)
    };
    // console.log(payload);
    dispatch(add_job_listing(payload));
    history.push("/jp/jobs");
    props.setShowSummary(false);
  };

  const handlePrev = e => {
    e.preventDefault();
    props.setShowSummary(false);
  }

  const [accepted_terms, set_accepted_terms] = useState(false)

  return (
    <div className="logged-in-container">
      <p className="text-center h3 text-secondary font-weight-bold">Summary of Job Listing</p>

      <table className="job-opening-table">
        <tbody>
          {/* Job Posting */}
          <tr>
            <td colSpan="2" className="font-weight-bold text-left text-secondary h4 pt-4">
              Overview: {props.position.name}{" "}
            </td>
          </tr>

          <tr>
            <td colSpan="2" className="font-weight-bold text-left text-secondary h5 pt-4">
              Job Posting <hr />
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Position</td>
            <td className="text-right p-3"> {props.position.name}</td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Permanent/Temporary</td>
            <td className="text-right p-3">{props.job_all_length.value}</td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Employment type </td>
            <td className="text-right p-3"> {props.job_type.value}</td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Can be performed remotely </td>
            <td className="text-right p-3"> {props.is_remote ? "Yes" : "No"}</td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Number of employees wanted</td>
            <td className="text-right p-3">
              {props.number_of_employees_wanted.value}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Approval</td>
            <td className="text-right p-3">
              {" "}
              {props.app_yourself ? "By Company" : "By Platform"}{" "}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Address</td>
            <td className="text-right p-3">
              {" "}
              {props.address.value} {props.zip_code.value}
              <br />
              {props.city.name},{props.province.name}, {props.country.name}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Shift</td>
            <td className="text-right p-3">{props.shift}</td>
          </tr>

          {/* Job Time Length */}
          <tr>
            <td colSpan="2" className="font-weight-bold text-left text-secondary h5 pt-4">
              Job Time Length <hr />
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Daily Hours</td>
            <td className="text-right p-3"> {props.daily_hours.value} </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Rate per Hour</td>
            <td className="text-right p-3"> ${props.rate_per_hour.value} </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Job length</td>
            <td className="text-right p-3"> {props.job_time_length}</td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">How many times</td>
            <td className="text-right p-3">
              {" "}
              {props.job_time_length_quantity?.value || 1}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Start date</td>
            <td className="text-right p-3">
              {" "}
              {new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
                Date.parse(props.start_date)
              )}
            </td>
          </tr>

          <tr>
            {/* Skills */}
            <td colSpan="2" className="font-weight-bold text-left text-secondary h5 pt-4">
              {/* Skills <hr /> */}
              Experiences <hr />
            </td>
          </tr>
          <tr>
            {/* <td className="text-left p-2 font-weight-bold text-secondary"> Required Skills </td> */}
            <td className="text-left p-2 font-weight-bold text-secondary"> Required Experiences </td>
            <td className="text-right p-3">
              {props.required_skills.map((skill) => (
                <div key={skill.id}>
                  <p className="m-0">{skill.name}</p>
                  <p className="text-muted">
                    {skill.importance} pts; {skill.years_of_experience} years of
                    experience
                  </p>
                </div>
              ))}
            </td>
          </tr>

          <tr>
            {/* <td className="text-left p-2 font-weight-bold text-secondary"> Optional Skills </td> */}
            <td className="text-left p-2 font-weight-bold text-secondary"> Skills </td>
            <td className="text-right p-3">
              <hr />
              {props.optional_skills.map((skill) => (
                <div key={skill.id}>
                  <p className="m-0">{skill.name}</p>
                  <p className="text-muted">
                    {skill.importance} pts; {skill.years_of_experience} years of
                    experience
                  </p>
                </div>
              ))}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary"> Required Languages </td>
            <td className="text-right p-3">
              <hr />
              {props.required_languages.map((lan) => (
                <div key={lan.id}>
                  <p className="m-0">{lan.name}</p>
                </div>
              ))}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary"> Optional Languages </td>
            <td className="text-right p-3">
              <hr />
              {props.optional_languages.map((lan) => (
                <div key={lan.id}>
                  <p className="m-0">{lan.name}</p>
                </div>
              ))}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary"> Required Certifications </td>
            <td className="text-right p-3">
              <hr />
              {props.certifications.map((lan) => (
                <div key={lan.id}>
                  <p className="m-0">{lan.name}</p>
                </div>
              ))}
            </td>
          </tr>


          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary"> Required Licenses </td>
            <td className="text-right p-3">
              <hr />
              {props.licenses.map((lan) => (
                <div key={lan.id}>
                  <p className="m-0">{lan.name}</p>
                </div>
              ))}
            </td>
          </tr>

          {/* Orientation */}
          <tr>
            <td colSpan="2" className="font-weight-bold text-left text-secondary h5 pt-4">
              Orientation
              <hr />
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">
              Required online orientation session before starting
            </td>
            <td className="text-right p-3">
              {" "}
              {props.online_session ? "Yes" : "No"}{" "}
            </td>
          </tr>

          {/* Instructions */}

          <tr>
            <td colSpan="2" className="font-weight-bold text-left text-secondary h5 pt-4">
              Other Notes and Instructions
              <hr />
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">
              Required job instructions for the winning cadidate
            </td>
            <td className="text-right p-3">
              {" "}
              {props.instructions.value.length === 0 ? "No" : "Yes"}{" "}
            </td>
          </tr>

          <tr>
            <td className="text-left p-2 font-weight-bold text-secondary">Instructions</td>
            <td className="text-right p-3"> {props.instructions.value}</td>
          </tr>

          <tr >
            <td className="text-left p-2 font-weight-bold text-secondary">Other Notes</td>
            <td className="text-right p-3"> {props.other_notes.value}</td>
          </tr>

          <tr className="text-center">
            <td colSpan="2">
              <input type='checkbox' className="m-1" value={accepted_terms} onChange={(e)=>{set_accepted_terms(!accepted_terms)}} />
              I Accept {' '}<a style={{color:'rgb(73,154,243)'}} rel="noopener noreferrer" target='_blank' href={`https://rapihire.com/official_docs/employer_terms&conditions.html`}> Employer Agreement </a>

                <span className="invalid-feedback mt-4 mb-2">
                    * Please accept Terms and Conditions to register!
                </span>
            </td>
          </tr>


          <tr>
            <td className="text-center">
              <button className="btn btn-light w-100" onClick={handlePrev}>
                Previous
              </button>
            </td>

            <td>
              <button disabled={!accepted_terms} className="btn btn-success w-100" onClick={handleConfirm}>
                Confirm and publish
              </button>
            </td>
          </tr>

        </tbody>
      </table>

    </div>
  );
};

export default OpeningSummary;
