import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { user_login } from '../../reducers/userReducer'
import { Link, Redirect } from "react-router-dom"

import {Form} from 'react-bootstrap'
import TextInput from '../ReusableComponents/TextInput'
import CustomButton from '../ReusableComponents/CustomButton'
import {sign_in_up} from '../../theme.js'

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LandingLoading from '../Skeletons/LandingLoading'
import { sidebar_change_size } from '../../reducers/sidebarReducer'

const Login = () => {
    const dispatch = useDispatch()
    const [email, set_email] = useState("")
    const [password, set_password] = useState("")
    const [show_password, set_show_password] = useState(false)

    const user = useSelector( ({user}) => user )
    const js_data = useSelector( ({job_seeker_info})=>job_seeker_info)
    
    if (user) {
        if(user.is_job_seeker && js_data){
     
            if (js_data.completed_profile===false){
                // dispatch(successAlert('You can start by creating your profile!'))
                return <Redirect push to="/js/createprofile"/> 
            }

            if (js_data.completed_profile===true){
                dispatch(sidebar_change_size(200))
                return <Redirect push to="/js/"/> 
            }

            else return <LandingLoading/>
        }

        else if (user.has_company === true){
            dispatch(sidebar_change_size(200))
            return <Redirect push to="/jp"/> 
        } 
        else if (user.has_company === false) {
            return <Redirect push to="/jp/newcompany"/>
        }

        return <LandingLoading/>
    }

    
    const handleLogin = async (e) => {
        e.preventDefault()
        const payload = {email, password}
        dispatch(user_login(payload))
    }

    const tooglePassword = (e) => {
        e.preventDefault()
        set_show_password(!show_password)
    }

    return (
        <div className="text-right mt-4">

        <a href="/" className=" p-5 ">
            Sign up
        </a>
        <div style={{marginTop:'15vh'}}/>

        <div className="form-width-size m-auto p-4 shadow text-left" >   

            <p className="font-weight-bold text-secondary h5 mb-4">
                Sign in to your account
            </p>

            <Form  onSubmit={handleLogin} >

            <TextInput
                label={'Enter Email *'}
                type={'text'}
                value={email}
                handleChange={(e)=>set_email(e.target.value)}
                placeholder={'name@example.com'}
            />
            <div className="pwd-container">
            <TextInput
                label={'Enter Password *'}
                type={ show_password ? 'text' : 'password'}
                value={password}
                handleChange={(e)=>set_password(e.target.value)}
            />
            {   show_password ? <VisibilityOffIcon onClick={tooglePassword} className='icon' /> : <VisibilityIcon onClick={tooglePassword} className='icon'/>}
            </div>

            <Link to='/forgotpassword' className="float-right">Forgot Password?</Link>
            <br/>

            <div className="text-center mt-4">
                <button className="btn btn-primary w-50 font-weight-bold mb-4" type="submit"> Login </button>
                <br/>
                <br/>
                <a style={{fontWeight: 400}} target="_blank" href='https://www.youtube.com/watch?v=jOUi6s9KlXQ&t=73s'>JOB SEEKER HELP?</a>
                <br/>
                <br/>
                
                <a style={{fontWeight: 400}} target="_blank"  href='https://www.youtube.com/watch?v=dQ1wjB4m1rA&t=6s' >JOB PROVIDER HELP?</a>
            </div>
            </Form>
            
        </div>
        </div>
    )

    
}

export default Login
