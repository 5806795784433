import React from 'react'

const styles = {
    skill:{
        // backgroundColor:'rgba(64,153,231)',
        padding:'7px',
        paddingLeft:'10px',
        margin:'5px',
        // width:'25%',
        color:'white',
        borderRadius:'8px'
    }
    ,
    button:{
        marginTop:'-9px',
        color:'white',
        border:'none',
        background:'none',
        marginLeft:'7px'
    }
    ,
    skill_list:{
        display:'flex',
        flexWrap:'wrap'
    }
}

const SingleSkill = (props) => {

    return (
        <div  style={{...styles.skill, backgroundColor:props.green?'green':'rgba(64,153,231)'}} 
        key={props.value}>
            {props.label} 
            {props.readonly ? null :
            <button 
                variant='link'
                style={styles.button}
                onClick={(e)=> props.language_id ? props.delete(e,props.language_id): props.delete(e,props.label)}
            >
            X
            </button> 
            }
        </div>
    )
}


export default SingleSkill;