import React from 'react'
import {useField} from 'formik'
import InfoIcon from '@material-ui/icons/Info'


const FormikTextInput = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return(
      <div className='text_input__group'>
        <p className='text-input-label'>
          {label} 
          {props.info ?  <InfoIcon onClick={()=>alert(props.info_text)} style={{float:'right', cursor:'pointer'}}/>: null }
        </p>
        <input type='text' className='form-control' label={label}  {...field} {...props} />
        {/* <br/> */}
        {meta.touched && meta.error ? (<div className='invalid-feedback'>{meta.error}</div> ): null}
      </div>
    );
  }

export default FormikTextInput;