import React from 'react'
import {useField} from 'formik'
import InfoIcon from '@material-ui/icons/Info'


const FormikCheckBox = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return(
        <>

        <p className='text-input-label'>{label} {props.info ? <InfoIcon style={{float:'right'}}/> : null} </p>
        <input type='checkbox'  label={label}  {...field} {...props} />
        {/* <br/> */}
        {meta.touched && meta.error ? (<div className='invalid-feedback'>{meta.error}</div> ): null}
      </>
    );
  }

export default FormikCheckBox;