import React, { useState } from 'react';
import moment from 'moment';
import matchService from '../../../../services/match'
import { useDispatch } from 'react-redux';
import { set_loading_false, set_loading_true } from '../../../../reducers/loadingReducer';
import { errorAlert, successAlert } from '../../../../reducers/alertReducer';
import { useHistory } from 'react-router-dom';


const StopContractJP = ({contract, set_menu}) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const today = moment().format().substr(0,10)

    const [end_date, set_end_date] = useState(today)
    const [reason_stopped, set_reason] = useState('')
    const handle_stop = async (e) => {
        e.preventDefault()

        const payload = {
            end_date,
            contract_id: contract.id,
            reason_stopped
        }

        dispatch(set_loading_true())
        try{
            await matchService.stop_contract_jp(payload)
            dispatch(set_loading_false())
            dispatch(successAlert('Success!'))
            setTimeout(() => {
                dispatch(successAlert(''))
            }, 4000);

            set_menu('')
            history.push('/')
        }
        catch(err){
            dispatch(set_loading_false())
            dispatch(errorAlert('Problem terminating contract! Please try again later!'))

        }
    }

    return (
        <div style={{backgroundColor:'rgba(255,0,0,0.3)'}} className='resume-item-page-form'>

            <label className='text-input-label'>Date to stop</label><br/>
            <input  
              value={end_date}
              onChange={e=>set_end_date(e.target.value)}
              type='date' 
              className='text-input normal-size-input'
              min={today}
              max={contract.end_date}
            />

            <label className='text-input-label'>Reason for stopping contract</label>
            <textarea 
              className='text-input normal-size-input' 
              placeholder='Type here' 
              value={reason_stopped}
              onChange={e=>set_reason(e.target.value)}
            /> <br/>

            <button 
              onClick={handle_stop} 
              style={{backgroundColor:'red'}} 
              className='btn btn-success w-100 mt-4'
            >Confirm</button>

        </div>
    )
}

export default StopContractJP;