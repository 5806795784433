import React, { useEffect, useRef, useState } from "react";

import { Link, useParams } from "react-router-dom";
import matchService from "../../../services/match";
import { Badge, Spinner } from "react-bootstrap";
import userService from "../../../services/user";
import PreviewCandidate from "../Candidates/PreviewCandidate";
import StopContractJP from "./Contract/StopContractJP";
import ExtendContractJP from "./Contract/ExtendContractJP";


const ApprovedDetail = () => {
  const experience_ref = useRef()

  const [approved_offer, set_approved_offer] = useState(null);
  const [approved_interested_position, set_approved_interested_position] =
    useState(null);
  const [approved_candidate, set_approved_candidate] = useState(null);
  const offer_ID = useParams().sugID;
  const listing_ID = useParams().listID

  const [menu_state, set_menu_state] = useState('')

  useEffect(() => {
    matchService
      .getOfferByID(offer_ID)
      .then((approved_offer_from_jp) => {
        set_approved_offer(approved_offer_from_jp);
        set_approved_interested_position(
          approved_offer_from_jp.interested_position
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [offer_ID]);

  useEffect(() => {
    if (approved_interested_position) {
      userService
        .getJSasCandidate(approved_interested_position.id)
        .then((approved_candidate_from_id) => {
          set_approved_candidate(approved_candidate_from_id);
        });
    }
  }, [approved_interested_position]);

  if (!approved_offer || !approved_interested_position || !approved_candidate) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  }

  const set_menu = (e, state) => {
    e.preventDefault();
    if (menu_state === state){
      set_menu_state('');
    }
    else set_menu_state(state);
  }


  return (
    <div className="logged-in-container">

      <div className='text-center'>
        <p className="h4">Time you matched</p>
        <p className="mt-2 text-muted" >
          {" "}
          {new Intl.DateTimeFormat("en-US", {
            dateStyle: "full",
            timeStyle: "long",
          }).format(Date.parse(approved_offer.match_time))}{" "}
        </p>

        <Link className="btn btn-primary mt-4 mb-4" to={`/jp/opening/${listing_ID}/filtered`}>See next candidates!</Link>

      </div>

      
      <div className='text-center'>
      {approved_offer.match_status === "Accepted" ? (
        <div className='m-auto' style={{width:300}}>

          <a ref={experience_ref} style={{display:'none'}} target= "_blank" href={`http://${window.location.host}/reference/rate/${approved_offer.experience_related}?js=${approved_interested_position.job_seeker.first_name}`}/>

          <button 
            disabled={!approved_offer.can_company_rate} 
            className='btn btn-primary w-100 mt-3'
            onClick={e=>{
              e.preventDefault()
              experience_ref.current.click()
            }}
          >  
          Rate</button>
          
          <button 
            disabled={!approved_offer.contract_active } 
            onClick={e=>set_menu(e, 'stop')} 
            className='btn btn-danger w-100 mt-3' 
  
          >
          Stop Contract</button>
          { menu_state === 'stop' && <StopContractJP set_menu={set_menu_state} contract={approved_offer}/>}

          { approved_offer.contract_active &&  <button disabled={approved_offer?.job_listing.job_type === 'Hire'} onClick={e=>set_menu(e, 'extend')} className='btn btn-success w-100 mt-3'>Extend Contract</button> }
          { menu_state === 'extend' && <ExtendContractJP set_menu={set_menu_state} contract={approved_offer}/>}

        </div>
      ) : (
        <b >Waiting for the Job Seeker response</b>
      )}
      </div>

      <PreviewCandidate
        js_data={approved_candidate}
        filtered_position_id={approved_interested_position.id}
        readonly={true}
      />

      <div className='text-center w-50 m-auto p-4'>
        {approved_offer.match_status === "Declined" && (
          <Badge style={{ fontSize: "18px" }} variant="danger">
            {" "}
            {approved_interested_position.job_seeker.first_name} declined your
            offer.
          </Badge>
        )}
      </div>
    </div>
  );
};

export default ApprovedDetail;
