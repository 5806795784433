import React, { useEffect, useState } from "react";
import { Spinner, Badge } from "react-bootstrap";
import userService from "../../../services/user";
import { format_file } from "../../JobSeeker/ManageProfile/Resume/EditLicences";

const styles = {
  titles: {
    fontSize: "16px",
    color: "#445566",
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    padding: 0,
    marginBottom: 0,
  },
  experience_table: {
    width: "100%",
    marginTop: "20px",
  },
  e_table_info: {
    textAlign: "right",
    fontSize: "16px",
    fontWeight: 500,
    color: "#36454f",
  },
  buttons: {
    textAlign: "center",
  },
  single_button_d: {
    margin: "10px",
    width: "250px",
    height: "48px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#249afa",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    border: "solid 1px #a3bbca",
  },
  single_button_a: {
    margin: "10px",
    width: "250px",
    height: "48px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff",
    backgroundColor: "#249afa",
    borderRadius: "4px",
    border: "solid 1px #a3bbca",
  },
};

const PreviewCandidate = ({ js_data, filtered_position_id, ...props }) => {
  const [all_experiences, set_all_experiences] = useState([]);
  const [all_educations, set_all_educations] = useState([]);
  const [all_licenses, set_all_licenses] = useState([]);
  const [all_certifications, set_all_certifications] = useState([]);
  const [all_skills, set_all_skills] = useState([]);

  useEffect(() => {
    if (js_data && filtered_position_id) set_all_skills(js_data.skills);

    userService
      .getCandidateExperiences(filtered_position_id)
      .then((data) => set_all_experiences(data))
      .catch((err) => {
        console.log("Error fetching Candidate Experiences!");
      });
    userService
      .getCandidateLicenses(filtered_position_id)
      .then((data) => set_all_licenses(data))
      .catch((err) => {
        console.log("Error fetching Candidate Experiences!");
      });

    userService
      .getCandidateEducations(filtered_position_id)
      .then((data) => set_all_educations(data))
      .catch((err) => {
        console.log("Error fetching Candidate Experiences!");
      });
    userService
      .getCandidateCertifications(filtered_position_id)
      .then((data) => set_all_certifications(data))
      .catch((err) => {
        console.log("Error fetching Candidate Experiences!");
      });
  }, [js_data, filtered_position_id]);

  if (!js_data || !filtered_position_id) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <div>
      <div className="resume-item-page-added">
        <img className="profile-pic float-left" style={{marginRight:15}} alt="avatar" src={js_data.user.profile_image} />
        <p style={{ fontWeight: 700 }}>
          {js_data.user.first_name} {js_data.user.last_name}{" "}
        </p>
        <span>
          {js_data.address}, {js_data.province.name}, {js_data.city.name},{" "}
          {js_data.country.name}
        </span>

      </div>

      <div className="resume-item-page-added">
        <p style={styles.titles}>EDUCATION </p>
        {all_educations.map((e) => (
          <div style={{ marginTop: "20px", color: "grey" }} key={e.id}>
            <p style={styles.titles}>{e.title}</p>
            <p style={{ padding: 0, margin: 0 }}>{e.year_achieved}</p>
            <p style={{ padding: 0, margin: 0 }}>
              {e.institution}, {e.institution_location}{" "}
            </p>
            {/* open a new window when clicking in the a tag */}
            {/* <a href={e.url}  style={{color:'#445566',fontSize:'16px',fontWeight:'bold'}}> {e.url} </a> */}
            {e.document && (
              <>
                {" "}
                <a rel="noopener noreferrer" target="_blank" href={e.document}>
                  {format_file(e.document)}
                </a>{" "}
              </>
            )}
          </div>
        ))}
      </div>

      <div className="resume-item-page-added">
        <p style={styles.titles}>WORK EXPERIENCES</p>

        {all_experiences.map((e) => (
          <div key={e.id}>
            <table style={styles.experience_table}>
              <tbody>
                <tr>
                  <td style={{ ...styles.titles, marginBottom: "7px" }}>
                    {e.position.name}
                  </td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td style={styles.e_table_info}>{e.company_name}</td>
                </tr>
                <tr>
                  <td>Start Date</td>
                  <td style={styles.e_table_info}>
                    {e.start_date
                      ? new Intl.DateTimeFormat("en-US").format(
                          Date.parse(e.start_date)
                        )
                      : "No date provided"}
                  </td>
                </tr>

                <tr>
                  <td>End Date</td>
                  <td style={styles.e_table_info}>
                    {e.end_date
                      ? new Intl.DateTimeFormat("en-US").format(
                          Date.parse(e.end_date)
                        )
                      : "Current Job"}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        ))}
      </div>

      <div className="resume-item-page-added">
        <p style={styles.titles}>CERTIFICATIONS</p>
        {all_certifications.map((e) => (
          <div style={{ marginTop: "20px", color: "grey" }} key={e.id}>
            <p style={styles.titles}>{e.title}</p>
            <p style={{ padding: 0, margin: 0 }}>{e.year_achieved}</p>
            <p style={{ padding: 0 }}>
              {e.institution}, {e.institution_location}{" "}
            </p>
            {e.document && (
              <>
                {" "}
                <a rel="noopener noreferrer" target="_blank" href={e.document}>
                  {format_file(e.document)}
                </a>{" "}
              </>
            )}
          </div>
        ))}
      </div>

      <div className="resume-item-page-added">
        <p style={styles.titles}>LICENSES</p>
        {all_licenses.map((e) => (
          <div style={{ marginTop: "20px", color: "grey" }} key={e.id}>
            <p style={styles.titles}>{e.title}</p>
            <p style={{ padding: 0, margin: 0 }}>{e.year_achieved}</p>
            <p style={{ padding: 0 }}>
              {e.institution}, {e.institution_location}{" "}
            </p>
            {e.document && (
              <>
                {" "}
                <a rel="noopener noreferrer" target="_blank" href={e.document}>
                  {format_file(e.document)}
                </a>{" "}
              </>
            )}
          </div>
        ))}
      </div>

      <div className="resume-item-page-added">
        <p style={styles.titles}>SKILLS</p>
        {all_skills.map((s, it) => (
          <Badge
            pill
            key={it}
            style={{ fontSize: "14px", margin: "2px" }}
            variant="primary"
          >
            {s.name}
          </Badge>
        ))}
      </div>

      {!props.readonly ? (
        <div style={styles.buttons}>
          <button onClick={props.handleDecline} style={styles.single_button_d}>
            Decline
          </button>
          <button onClick={props.handleAccept} style={styles.single_button_a}>
            Accept
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PreviewCandidate;
