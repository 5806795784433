import React, { useEffect, useState }  from 'react'
import {
    Switch,
    Route,
    useHistory,
    Redirect
} from "react-router-dom" 
import Landing from './components/Shared/Landing'
import Login from './components/Shared/Login'
// DEVELOP BRANCH
import Navigation from './components/CoreComponents/Navigation'
import HomeJP from './components/JobProvider/HomeJP'
import LegalPreview from './components/JobProvider/Profile/Legal.js'
import HomeJS from './components/JobSeeker/HomeJS'
import RegisterJS from './components/JobSeeker/RegisterJS'
import CreateCompany from './components/Company/CreateCompany'
import RegisterJP from './components/JobProvider/RegisterJP'
import OpeningDetail from './components/JobOpening/OpeningDetail.js'
import CreateJobOpening from './components/JobOpening/CreateJobOpening'
import EditProfile from './components/JobSeeker/ManageProfile/EditProfile'

// import MyProfileJS from './components/JobSeeker/ManageProfile/MyProfileJS'
import EditCertifications from './components/JobSeeker/ManageProfile/Resume/EditCertifications'
import EditLicences from './components/JobSeeker/ManageProfile/Resume/EditLicences'
import EditEducation from './components/JobSeeker/ManageProfile/Resume/EditEducation'
import EditSkills from './components/JobSeeker/ManageProfile/Resume/EditSkills'
import EditWorkExperiences from './components/JobSeeker/ManageProfile/Resume/EditWorkExperiences'
import AddPosition from './components/JobSeeker/InterestedPosition/AddPositionJS'
import CustomAlert from './components/Alert'
import { useDispatch, useSelector } from 'react-redux'
import {  logout } from './reducers/userReducer'
import {get_companies} from './reducers/companyReducer'
import {get_all_interestedJobs} from './reducers/interestedJobsReducer'
import {get_all_job_openings} from './reducers/jobOpeningReducer'
import ListOpenings from './components/JobOpening/ListOpenings'
import ListedPositions from './components/JobSeeker/InterestedPosition/ListedPositions'
// import EditAccount from './components/JobSeeker/ManageProfile/EditAccount'
import 'bootstrap/dist/css/bootstrap.min.css';
import Message from './components/Shared/MessageComponents/Message'
import FilteredList from './components/JobOpening/Candidates/CandidateList'
import FilteredDetail from './components/JobOpening/Candidates/CandidateDetail'
import SuggestionList from './components/JobOpening/ApprovedCandidates/ApprovedList'
import SuggestionDetail from './components/JobOpening/ApprovedCandidates/ApprovedDetail'
import OfferList from './components/JobSeeker/Offers/OfferList'
import OfferDetail from './components/JobSeeker/Offers/OfferDetail'
import ManageCompany from './components/Company/ManageCompany/ManageCompany'
import Sidebar from './components/CoreComponents/Sidebar/Sidebar'
import InterestedPositionDetail from './components/JobSeeker/InterestedPosition/InterestedPositionDetail'
import LandingOpenings from './components/JobOpening/LandingOpenings'
import { init_autocomplete } from './reducers/autocompleteReducer'
import { get_js_info } from './reducers/jsReducer'
import { init_work_experiences } from './reducers/jsResume/experienceReducer'
import { init_educations } from './reducers/jsResume/educationReducer'
import { init_licenses } from './reducers/jsResume/licenseReducer'
import { init_certifications } from './reducers/jsResume/certificationReducer'
import ProfilePreviewJS from './components/JobSeeker/ManageProfile/ProfilePreview'
import InvoicesList from './components/JobProvider/InvoicesList'
import SidePhoto from './components/CoreComponents/SidePhoto/SidePhoto'
import PreviewCompany from './components/Company/ManageCompany/PreviewCompany'
import { sidebar_change_size } from './reducers/sidebarReducer'
import PageNotFound from './components/Shared/PageNotFound'
import MenuJS from './components/JobSeeker/MenuJS'
import RateExperience from './components/Shared/RateExperience'
import CreateProfile from './components/JobSeeker/CreateProfile'
import { get_seeker_skills } from './reducers/skillReducer'
import CreateResume from './components/JobSeeker/CreateResume'
import { set_loading_false, set_loading_true } from './reducers/loadingReducer'
import LoadingSpinner from './components/ReusableComponents/LoadingSpinner'
import Cookies from 'js-cookie'
import PayInvoice from './components/JobProvider/PayInvoice'
import ForgotPassword from './components/Shared/ForgotPassword'
import PassiveIncome from './components/JobSeeker/PassiveIncome/PassiveIncome'
import InviteReferral from './components/JobSeeker/PassiveIncome/InviteReferral'
import SocketService from './services/SocketService'
import ExtensionDetail from './components/JobSeeker/Offers/Extension/ExtensionDetail'
import ApprovalHistoryJP from './components/Company/ApprovalHistoryJP'
import RegisterFeedBackPage from './components/JobProvider/RegisterFeedBackPage.js'
import './styles/index.css'
import './styles/menu.css'
import './styles/offer.css'
import './styles/profile.css'
import './styles/job-opening.css'
import './styles/candidates.css'
import './styles/navigation.css'
import './styles/company-preview.css'
import './styles/tables.css'
import './styles/resume-js.css'
import './styles/home-js.css'
import './styles/modal.css'
import EditLanguages from './components/JobSeeker/ManageProfile/Resume/EditLanguages'
import { get_seeker_languages } from './reducers/languagesReducer'


const role = () => {
  return window.localStorage.getItem('user_role')
}

const jp_has_company = () => {
  return window.localStorage.getItem('has_company') && role()==='jp'
}

const jp_has_not_company = () => {
  return !window.localStorage.getItem('has_company') && role()==='jp'
}

const JOB_PROVIDER_ROUTE_NO_COMPANY = ({path,component}) => {

  if (jp_has_not_company())
    return <Route exact path={path} component={component}  />
  return <Redirect to='/jp' />
}


const JOB_PROVIDER_ROUTE = ({path,component}) => {
  if (role()==='jp')
    return <Route exact path={path} component={component}  />
  else return <Redirect to='/404' />
}


const JOB_SEEKER_ROUTE = ({path,component}) => {

  if (role()==='js')
    return <Route exact path={path} component={component} />
  return <Redirect to='/404' />
}

const SHARED_LOGGED_IN_ROUTE = ({path,component}) => {

  if (role())
    return <Route exact path={path} component={component} />
  return <Redirect to='/login' />
}

const LOGGED_OUT_ROUTE = ({path,component,user}) => {
  if (!user)  
    return <Route exact path={path} component={component} />
  return <Redirect to='/login' />
}

const loader = document.querySelector(".preloader");

const showLoader = () => loader.classList.remove("preloader");
const addClass = () => loader.classList.add("loader-hide");


const App = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  
  const user = useSelector( ({user}) => user )
  const is_loading = useSelector( ({is_loading}) => is_loading)
  const job_seeker_info = useSelector( ({job_seeker_info}) => job_seeker_info)

  const [width, set_width] = useState(window.innerWidth)

  useEffect(()=>{
    showLoader();
    addClass();
    dispatch(set_loading_true())

    if (!Cookies.get('user')){
      window.localStorage.clear()
    }
    
    if(user){
      dispatch(init_autocomplete())

      if(user.is_job_provider){
        dispatch(get_companies())
        dispatch(get_all_job_openings())
      }

      if(user.is_job_seeker){
        dispatch(get_js_info(user))
        dispatch(get_all_interestedJobs())
        dispatch(init_work_experiences())
        dispatch(init_educations())
        dispatch(init_licenses())
        dispatch(init_certifications())
        dispatch(get_seeker_skills())
        dispatch(get_seeker_languages())
        if (job_seeker_info?.completed_profile){
          dispatch(sidebar_change_size(200))
        }
      }
    }
    dispatch(set_loading_false())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user,dispatch])

  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
    history.push('/login')
  }

  const handleResize = () =>  {
    set_width(window.innerWidth)
    if (window.innerWidth<801){
      dispatch(sidebar_change_size(0))
    }
    else {
      if (jp_has_company() || (role()==='js' && job_seeker_info?.completed_profile))
        dispatch(sidebar_change_size(200))
    }
  }

  // dispatch(set_loading_false())
  useEffect(()=>{
    dispatch(set_loading_true())
    handleResize()
    window.addEventListener('resize', handleResize)
    dispatch(set_loading_false())

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.innerWidth])

  if ( role() && ( !user || !job_seeker_info ) ){
    return (
      <div className='logged-in-container'>
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    
    <div className="d-flex flex-column" style={{backgroundColor:'rgb(249,249,249)'}}>
      
      {/* Top nav when user is logged in */}
      {user && <Navigation user={user} handleLogout={handleLogout}/>}
      
      {/* The Sidebar and Main Content */}
      <div className="d-flex flex-row">
      <SocketService/>


      { width > 1100 ? <SidePhoto/> : null }

      {/* Sidebar when user is logged in */}
      <Sidebar/>

      <CustomAlert/>

      {is_loading ? <LoadingSpinner/>: null}
      
      <div style={{flex:1}}>      


        <Switch>


      {/* Logged out paths */}
          <LOGGED_OUT_ROUTE exact path="/" component={Landing}/>
          <LOGGED_OUT_ROUTE exact path='/jp/register' user={user} component={RegisterJP}/>
          <LOGGED_OUT_ROUTE exact path='/js/register' user={user} component={RegisterJS}/>
          <LOGGED_OUT_ROUTE exact path='/registeredsuccess' component={RegisterFeedBackPage}/>


      {/* Job Provider paths */}
          <JOB_PROVIDER_ROUTE_NO_COMPANY exact path="/jp/newcompany" component={CreateCompany}/>
          <JOB_PROVIDER_ROUTE exact path="/jp" component={HomeJP} />
          <JOB_PROVIDER_ROUTE exact path="/jp/managecompany" component={ManageCompany}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/previewcompany" component={PreviewCompany}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/jobs" component={LandingOpenings} />
          <JOB_PROVIDER_ROUTE exact path="/jp/invoices" component={InvoicesList} />
          <JOB_PROVIDER_ROUTE exact path="/jp/newopening" component={CreateJobOpening}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/opening/:id" component={OpeningDetail}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/jobopenings" component={ListOpenings}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/opening/:id/filtered" component={FilteredList}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/opening/:id/suggestions" component={SuggestionList}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/opening/:listID/filtered/:sugID" component={FilteredDetail}/>
          <JOB_PROVIDER_ROUTE exact path="/jp/opening/:listID/approved/:sugID" component={SuggestionDetail}/>
          <JOB_PROVIDER_ROUTE exact path='/jp/invoice/pay' component={PayInvoice}/>
          <JOB_PROVIDER_ROUTE exact path='/jp/legal' component={LegalPreview}/>


      {/* Job seeker paths  */}
          <JOB_SEEKER_ROUTE exact path="/js/createprofile" js={job_seeker_info} component={CreateProfile}/>
          <JOB_SEEKER_ROUTE exact path="/js/createresume" js={job_seeker_info} component={CreateResume}/>
          <JOB_SEEKER_ROUTE exact path="/js" component={HomeJS} />
          <JOB_SEEKER_ROUTE exact path="/js/myprofile" component={HomeJS}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/profile" component={EditProfile}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/education" component={EditEducation}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/licences" component={EditLicences}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/certifications" component={EditCertifications}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/skills" component={EditSkills}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/languages" component={EditLanguages}/>
          <JOB_SEEKER_ROUTE exact path="/js/edit/workexperience" component={EditWorkExperiences}/>
          <JOB_SEEKER_ROUTE exact path="/js/addposition" component={AddPosition}/>
          <JOB_SEEKER_ROUTE exact path="/js/mypositions" component={ListedPositions}/>
          <JOB_SEEKER_ROUTE exact path="/js/offers" component={OfferList}/>
          <JOB_SEEKER_ROUTE exact path="/js/offers/:offerID" component={OfferDetail}/>
          <JOB_SEEKER_ROUTE exact path="/js/extensions/:extension_id" component={ExtensionDetail}/>
          <JOB_SEEKER_ROUTE exact path="/js/mypositions/:posID" component={InterestedPositionDetail}/>
          <JOB_SEEKER_ROUTE exact path="/js/previewprofile" component={ProfilePreviewJS}/>
          <JOB_SEEKER_ROUTE exact path='/menu' component={MenuJS}/>
          <JOB_SEEKER_ROUTE exact path='/js/passiveincome' component={PassiveIncome}/>
          <JOB_SEEKER_ROUTE exact path='/js/invitereferral' component={InviteReferral}/>
          <JOB_SEEKER_ROUTE exact path='/js/legal' component={LegalPreview}/>

      
      {/* Shared paths */}
          <SHARED_LOGGED_IN_ROUTE exact path="/approvalhistory" component={ApprovalHistoryJP}/>
          <SHARED_LOGGED_IN_ROUTE exact path="/messages" component={Message}/>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/reference/rate/:experience_id" component={RateExperience}/>
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route component={PageNotFound}/>

        </Switch>
      </div>
      </div>
      {/* <Footer/> */}
    </div>
  )
}

export default App;