import React from 'react'
import { useSelector } from 'react-redux'

const Profile = ({chatter, position, company}) => {
  const my_company = useSelector( ({company}) => company );

  return(
      <div className="contact-profile" >
      {chatter ? (
        <div>
          <img src={chatter.profile_image} className='profile-pic' alt="Rapihire profile pic" />
          <p>{chatter.first_name} - {!my_company ? company : position}</p>
        </div>
      ) : null}
    </div>
  )
}

export default Profile;