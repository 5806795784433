import React from 'react'
import './reusable_components.css'

const styles = {
 

    text:{
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#ffffff'
    }
    
}

const CustomButton = (props) => (

    <button className='button__normal' type={props.type} onClick={props.handle_click} >
        <p style={styles.text}>{props.label}</p>
    </button>

)

export default CustomButton;