import userService from '../../services/user'
import { errorAlert, successAlert } from '../alertReducer'
import { set_loading_false, set_loading_true } from '../loadingReducer'


const experienceReducer = (state=[], action) => {
    switch (action.type){

        case 'INIT_WORK_EXPERIENCE':
            return action.data

        case 'ADD_NEW_WORK_EXPERIENCE':
            return [...state, action.data]
            
        case 'DELETE_WORK_EXPERIENCE':
            const new_state = state.filter(e => e.id.toString() !== action.id.toString())
            return new_state
        default:
            return state
    }
}

export const init_work_experiences = () => {
    return async dispatch => {
        try{
            const data = await userService.getExperiences()
            dispatch({
                type:'INIT_WORK_EXPERIENCE',
                data
            })
        }
        catch(exception){
            console.log("Error fetching experience");
        }
    }
}


export const add_experience = (payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await userService.addExperience(payload)

            dispatch(successAlert(`Experience added successfully!`));
            setTimeout(()=>dispatch(errorAlert("")),3000);

            dispatch({
                type:'ADD_NEW_WORK_EXPERIENCE',
                data
            })
            dispatch(set_loading_false())
        }
        catch(exception){
            dispatch(errorAlert(`Cannot add experience!`));
            dispatch(set_loading_false())

            // setTimeout(()=>dispatch(errorAlert("")),3000);
        }
    }
}

export const delete_experience = (id) => {
    return async dispatch => {
        try{
            await userService.deleteExperience(id)

            dispatch({
                type:'DELETE_WORK_EXPERIENCE',
                id
            })
        }
        catch(exception){
            console.log("Error deleting experience");
        }
    }
}




export default experienceReducer;