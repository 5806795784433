import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Spinner} from 'react-bootstrap'
import { useHistory } from 'react-router'
import { Formik, Form } from 'formik'

import FormikDropDown from '../ReusableComponents/FormikDropDown'
import FormikTextInput from '../ReusableComponents/FormikTextInput'
import { create_company_profile } from '../../validations/company'
import { create_company } from '../../reducers/companyReducer'
import { validate_canada_zip, validate_us_zip } from '../../validations/GenralValidations'
import FormikPhoneNumber from '../ReusableComponents/FormikPhoneNumber'


const CreateCompany = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const company = useSelector( ({company}) => company )

    const [countries, setCountries] = useState([])

    const [cities, setCities] = useState([])
    const [provinces, setProvinces] = useState([])
    const [zip_error, set_zip_error] = useState(false);

    const general = useSelector( ({general}) => general)  

    useEffect(()=>{
        if(company)
            history.push('/jp')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[company])

    useEffect(() =>{
        
        if(general.length!==0){

            const countryArray = general.countries.map(pos => {
                return {value:pos.id,label:pos.name}
            })
            setCountries(countryArray)
            const cityArray = general.cities.map(pos => {
                return {value:pos.id,label:pos.name, province:pos.province}
            })
            setCities(cityArray)
            const provinceArray = general.provinces.map(pos => {
                return {value:pos.id,label:pos.name, country:pos.country}
            })
            setProvinces(provinceArray)
        }
        
    },[general])
    const [address_error, set_address_error] = useState(false);

    const validate_address = (country, province, city) => {
        const country_id = general.countries.find(c => Number(c.id) === Number(country.id)).id
        const province_id = general.provinces.find(c => Number(c.id) === Number(province.id)).id
        const province_country_id = general.provinces.find(c => Number(c.id) === Number(province.id)).country.id
        const city_province_id = general.cities.find(c => Number(c.id) === Number(city.id)).province.id
        if(country_id === province_country_id && province_id === city_province_id){
            set_address_error(0)
            return true
        }
        set_address_error(1)
        return false
    }


    const validate_zip = (zip, country) => {
        return (country.name === 'USA'
                           ? !validate_us_zip(zip)
                           : !validate_canada_zip(zip) )
     }
  
    if(!company) {
        return (

            <div className='logged-in-container' >
    
                <div className='company-create-div'>
                <p className="font-weight-bold text-secondary h4 mt-4 text-left">Company info</p>
                <hr/>

                <Formik
                    initialValues={{
                        name:'',
                        business_number:'',
                        address:'',
                        zip_code:'',
                        city:null,
                        province:null,
                        country:null,
                        contact_name: '',
                        contact_email: null,
                        contact_phone: '',
                    }}
                    validationSchema={create_company_profile}

                    onSubmit={async (values, {setSubmitting, resetForm})=>{
                        // resetForm();
                        setSubmitting(false);
                        set_zip_error(
                            values.country.name === 'USA'
                               ? !validate_us_zip(values.zip_code)
                              : !validate_canada_zip(values.zip_code),
                        );
                        const payload = {
                            name:values.name,
                            business_number: values.business_number,
                            address:values.address,
                            city_id:values.city.id,
                            zip_code:values.zip_code,
                            country_id:values.country.id,
                            province_id:values.province.id,
                            contact_name:values.contact_name,
                            contact_email:values.contact_email,
                            contact_phone:values.contact_phone
                        }

                        if (!validate_address(values.country, values.province, values.city)){
                            return
                        }

                        if ( ((values.country.name === 'USA' && validate_us_zip(values.zip_code)) ||
                    (values.country.name === 'Canada' && validate_canada_zip(values.zip_code))) ) {

                        dispatch(create_company(payload));
                        }
                        else {
                            console.log('invalid');
                        }
                    }}

                >
                {props => (
                    <Form>
                        
                        <div>
                            <FormikTextInput label='Company *' name='name' placeholder={'Company name'}  />
                            <FormikTextInput label='Busines Number *' name='business_number' placeholder={'Business number'}  />
                        </div>

                        <div>
                            <FormikDropDown 
                                label='Address *'                 
                                options={countries}
                                name='country'
                                placeholder={'Country'}
                                value={countries.find(pos => pos.value === props.values.country?.id)}
                            />
                            <FormikDropDown
                                options={provinces.filter(province =>  (province.country.id) === (props.values.country?.id) )}
                                name='province'
                                placeholder={'Province'}
                                value={provinces.find(pos => pos.value === props.values.province?.id)}
                            />
                            <FormikDropDown
                                options={cities.filter(city =>  (city.province.id) === (props.values.province?.id) )}
                                name='city'
                                placeholder={'City'}
                                value={cities.find(pos => pos.value === props.values.city?.id)}

                            />
                            <div className='d-flex justify-content-between'>
                                <FormikTextInput style={{width:'150%'}} name='address' placeholder='Address'/>
                                <FormikTextInput  name='zip_code' placeholder='ZIP/Postal'/>
                            </div>
                        { zip_error && validate_zip(props.values.zip_code, props.values.country) && <p style={{textAlign:'right'}} className='invalid-feedback'>Invalid ZIP for {props.values.country.name}</p>}
                        { address_error && <p className='invalid-feedback text-right'>Please check Country, Province and City </p>}


                        </div> 

                        <div>
                            <FormikTextInput label='Contact' name='contact_name' placeholder='Contact Name *'  />
                            <FormikTextInput name='contact_email' placeholder='Contact Email'  />
                            <FormikPhoneNumber
                                type={"text"}
                                id={"phone"}
                                name="contact_phone"
                                label="Phone Number *"
                                placeholder={"1234567890"}
                            />    
                        </div>

                        <div className='text-center'>
                            <button className="btn btn-primary w-50 mt-4" type='submit'>{props.isSubmitting ? 'Loading...' : 'Confirm'}</button>  
                        </div>  

                    </Form>
                )}
                </Formik>

                
            </div>
            </div>)
    }

    return (
        <div style={{textAlign:'center', paddingTop:'100px'}}>
            <Spinner style={{}} animation="border" /> 
        </div>
    )

    
}

export default CreateCompany