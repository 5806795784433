import userService from '../../services/user'
import { errorAlert, successAlert } from '../alertReducer'
import { set_loading_false, set_loading_true } from '../loadingReducer'


const licenseReducer = (state=[], action) => {
    switch (action.type){

        case 'INIT_LICENSES':
            return action.data

        case 'ADD_NEW_LICENSE':
            return [...state, action.data]
            
        case 'DELETE_LICENSE':
            const new_state = state.filter(l => l.id.toString() !== action.id.toString())
            return new_state
        default:
            return state
    }
}

export const init_licenses = () => {
    return async dispatch => {
        try{
            const data = await userService.getLicenses()
            dispatch({
                type:'INIT_LICENSES',
                data
            })
        }
        catch(exception){
            console.log("Error fetching License");
        }
    }
}

export const add_license= (payload, formData) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await userService.addLicense(payload)

            dispatch({
                type:'ADD_NEW_LICENSE',
                data
            })
            try{
                await userService.uploadLicense(formData, data.id)
                dispatch(successAlert(`Licence added successfully!`));
            }
            catch(exception){
                dispatch(errorAlert(`License added successfully, but you didn't upload any document!`));
            }
            dispatch(init_licenses())
            dispatch(set_loading_false())
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert(`Cannot add licence!`));
            // setTimeout(()=>dispatch(errorAlert("")),3000);
        }
    }
}

export const delete_license = (id) => {
    return async dispatch => {
        try{
            await userService.deleteLicense(id)

            dispatch({
                type:'DELETE_LICENSE',
                id
            })
        }
        catch(exception){
            console.log("Error deleting License");
        }
    }
}




export default licenseReducer;