import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { add_interested_position } from "../../../reducers/interestedJobsReducer";
import InfoIcon from "@material-ui/icons/Info";

import { Formik, Form } from "formik";
import FormikTextInput from "../../ReusableComponents/FormikTextInput";
import FormikDateInput from "../../ReusableComponents/FormikDate";
import FormikDropDown from "../../ReusableComponents/FormikDropDown";
import { interested_position, interested_position_permanent } from "../../../validations/seeker_profile";

const styles = {
  button: {
    width: "100%",
    maxWidth: "800px",
    outlineStyle: "dotted",
    color: "#249afa",
  },
};

const JTL_CHOICES = [
  { value: "Hour", label: "Hour" },
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Piece of work", label: "Piece of work" },
];

const AddPosition = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [positions, setPositions] = useState([]);
  const general = useSelector(({ general }) => general);
  const my_positions = useSelector(({ interestedJobs }) =>
    interestedJobs.map((job) => job.position.id)
  );
  const job_seeker_info = useSelector(({ job_seeker_info }) => job_seeker_info);

  const [job_all_length, set_job_all_length] = useState(
    "Either Permanent or Temporary"
  ); // permanent/temporary

  const [is_remote, set_is_remote] = useState(false)


  useEffect(() => {
    if (general.length !== 0) {
      const positionsArray = general.positions.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setPositions(positionsArray);
    }
  }, [general]);

  const handleJobAllLength = (e) => {
    set_job_all_length(e.target.value);
  };

  const handleRemote = (e) => {
    set_is_remote(e.target.value == 'true');
  };

  return (  
    <div className="logged-in-container">
      <div
        className='w-50 m-auto p-4 shadow'
        style={{ minWidth: "400px", maxWidth:"450px" }}
      >
        <p className="font-weight-bold text-secondary h5 mt-4 mb-5 text-left">Add Interested Positions </p>

        <Formik
          initialValues={{
            position: undefined,
            start_date: new Date(),
            // end_date:null,
            job_all_length: "",
            is_remote: null,
            daily_desired_hours: 0,
            desired_rate_hour: 0,
            job_time_length: 0,
            job_time_length_quantity: 0,
          }}
          validationSchema={job_all_length !== 'Permanent' ? interested_position : interested_position_permanent }
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            resetForm();
            setSubmitting(false);

            const payload = {
              position_id: values.position.id,
              start_date: values.start_date,
              end_date: values.end_date,
              job_time_length: values.job_time_length.name,
              is_remote: is_remote,
              daily_desired_hours: values.daily_desired_hours,
              desired_rate_hour: values.desired_rate_hour,
              job_all_length,
              job_time_length_quantity: values.job_time_length_quantity,
            };
            dispatch(add_interested_position(payload));
            if (job_seeker_info.completed_profile)
              history.push("/js/mypositions");
          }}
        >
          {(props) => (
            <Form>
              <FormikDropDown
                label={"Position you are interested in *"}
                options={positions.filter(
                  (pos) => !my_positions.includes(pos.value)
                )}
                name="position"
                placeholder={"Position"}
                value={positions.find(
                  (pos) => pos.value === props.values.position?.id
                )}
              />
              <FormikDateInput name="start_date" label="Start Date" />
              <br />
              <div>
                <p className="text-input-label">
                  Employment type?
                  <InfoIcon onClick={()=>alert('Employment Type')} style={{ float: "right", cursor:'pointer' }} />
                </p>
                <input
                  type="radio"
                  value="Permanent"
                  onChange={handleJobAllLength}
                  checked={job_all_length === "Permanent"}
                />{" "}
                Permanent <br />
                <input
                  type="radio"
                  value="Temporary"
                  onChange={handleJobAllLength}
                  checked={job_all_length === "Temporary"}
                />{" "}
                Temporary <br />
                <input
                  type="radio"
                  value="Either Permanent or Temporary"
                  onChange={handleJobAllLength}
                  checked={job_all_length === "Either Permanent or Temporary"}
                />{" "}
                Either Permanent or Temporary <br />
              </div>
              <br/>
              <div>
                <p className="text-input-label">
                    Can the position be performed remotely?
                    <InfoIcon onClick={()=>alert('Can the position be performed remotely?')} style={{ float: "right", cursor:'pointer' }} />
                  </p>
                  <input
                    type="radio"
                    value={true}
                    onChange={handleRemote}
                    checked={is_remote === true}
                  />{" "}
                  Yes <br />
                  <input
                    type="radio"
                    value={false}
                    onChange={handleRemote}
                    checked={is_remote === false}
                  />{" "}
                  No <br />
              </div>

              {
                job_all_length !== 'Permanent' &&
              <>
              <FormikDropDown
                info={1}
                label={"Job Period *"}
                options={JTL_CHOICES}
                name="job_time_length"
                info_text={'Select how do you want to measure the period: Hour/Day/Week/Month/Piece of Work/Permanent'}
              />
              <FormikTextInput
                info={1}
                label={"Unit *"}
                name="job_time_length_quantity"
                info_text={'Select the quantity of selected Job Period. If Job Period is selected Day then this field tells how many days you want to be hired. E.g. if you selected 5 , it means 5 days.'}
              />
              </>
              }
              <div className="d-flex justify-content-between">
                <FormikTextInput
                  info={1}
                  name="daily_desired_hours"
                  type="number"
                  label="Daily hours *"
                  info_text={'Daily desired hours'}
                />
                <FormikTextInput
                  info={1}
                  name="desired_rate_hour"
                  type="number"
                  label="Desired rate $/h *"
                  info_text={'Desired rate per hour in dollars'}
                />
              </div>
              <br />
              <br />
              <div className='text-center'>
                <button type="submit" className="btn btn-outline-primary w-100">
                  {props.isSubmitting ? "Loading..." : "Add position"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPosition;
