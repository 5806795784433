import jobService from '../services/jobs'
import { errorAlert, successAlert } from './alertReducer'
import { set_loading_false, set_loading_true } from './loadingReducer'

const interestedJobsReducer = (state=[], action) => {
    switch (action.type){

        case 'NEW_INTERESTED_POSITION':
            return [...state, action.data]

        case 'INIT_INTERESTED_POSITIONS':
            return action.data
        
        case 'DELETE_INTERESTED_POSITION':
            const new_state = state.filter(p => p.id.toString() !== action.data.id.toString())
            return new_state
        default:
            return state
    }
}

export const get_all_interestedJobs = () => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await jobService.listInterested()
            dispatch({
                type:'INIT_INTERESTED_POSITIONS',
                data
            })
            dispatch(set_loading_false())
        }
        catch(exception){
            dispatch(set_loading_false())
            console.log("Error listing interested jobs");
        }
    }
}

export const update_interested_position = (id, payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            await jobService.updateInterested(id, payload)

            dispatch(get_all_interestedJobs())

            dispatch(set_loading_false())
            dispatch(successAlert('Interested position updated successfully!'))
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert(`Cannot update Interested position!`));
        }
    }
}

export const add_interested_position = (payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await jobService.createInterested(payload)
            dispatch({
                type:'NEW_INTERESTED_POSITION',
                data
            })
            
            dispatch(set_loading_false())
            dispatch(successAlert('Interested position added successfully!'))
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert(`Cannot add Interested position!`));
        }
    }
}


export const delete_interested_position = (id) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
           
            await jobService.deleteInterestedById(id)
            const data = {id}
            dispatch({
                type:'DELETE_INTERESTED_POSITION',
                data
            })
            dispatch(set_loading_false())
            dispatch(successAlert('Interested position deleted successfully!'))
            setTimeout(()=>dispatch(errorAlert("")),3000);
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert(`Cannot delete Interested position!`));
        }
    }
}



export default interestedJobsReducer