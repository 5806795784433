import React from 'react'



const MenuButton = (props) => (

    <button 
        className= {props.className ?"button__menu" + " " + props.className : "button__menu"}
        style={{
          backgroundColor: props.is_active ? '#249afa' :'#ffffff',
          color: props.is_active ? '#ffffff' :'#253343',
          borderColor: props.is_active ? '#249afa' :'#253343',
        }}  
        type={props.type} onClick={props.handle_click} 
    >
    {props.label}
    </button>

)

export default MenuButton;