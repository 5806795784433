import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { resume_education, resume_item } from "../../../../../validations/resume";
import FormikTextInput from "../../../../ReusableComponents/FormikTextInput";
import { useDispatch } from "react-redux";

import userService from "../../../../../services/user";
import { errorAlert, successAlert } from "../../../../../reducers/alertReducer";
import { init_educations } from "../../../../../reducers/jsResume/educationReducer";
import { format_file } from "../EditLicences";

const UpdateEducation = ({ education, set_show_edit }) => {
  const dispatch = useDispatch();

  const [file, set_file] = useState(null);

  const handleClose = () => {
    set_show_edit(false);
  };

  const handle_file_select = (e) => {
    // Allow only files with .pdf and .jpg, .jpeg and .png extensions
    const file_extension = e.target.files[0].name.split(".").pop();
    if (
      file_extension === "pdf" ||
      file_extension === "jpg" ||
      file_extension === "jpeg" ||
      file_extension === "png"
    ) {
      set_file(e.target.files[0]);
    } else {
      alert("Only .pdf, .jpg, .jpeg and .png files are allowed");
    }
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit education</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            title: education.title,
            year_achieved: education.year_achieved,
            institution: education.institution,
            institution_location: education.institution_location,
          }}
          validationSchema={resume_education}
          onSubmit={async (values, { setSubmitting }) => {
            // Reset Form
            // resetForm()
            setSubmitting(false);
            const payload = {
              title: values.title,
              year_achieved: values.year_achieved,
              institution: values.institution,
              institution_location: values.institution_location,
            };
            const formData = new FormData();
            formData.append("file", file);
            userService
              .updateEducation(payload, education.id)
              .then((data) => {
                handleClose();
                dispatch(init_educations());
                dispatch(successAlert("Updated Successfully"));
                setTimeout(() => {
                  dispatch(errorAlert(""));
                }, 2000);
              })
              .catch((error) => dispatch(errorAlert("Problem updating!")));

            userService
              .uploadEducation(formData, education.id)
              .then((data) => dispatch(init_educations()))
              .catch((err) => console.log("Error uploading!"));
          }}
        >
          {(props) => (
              <Form className="text-left m-auto" >
              <FormikTextInput name="title" placeholder="Title" />
              <FormikTextInput
                name="year_achieved"
                placeholder="Year Achieved"
              />
              <FormikTextInput name="institution" placeholder="Institution" />
              <FormikTextInput
                name="institution_location"
                placeholder="Institution Location"
              />

              {education.document && (
                <>
                  {" "}
                  <br />{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={education.document}
                  >
                    {format_file(education.document)}
                  </a>{" "}
                  <br />{" "}
                </>
              )}

              <input
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={handle_file_select}
                type="file"
              />

              <br />
              <br />

              <div textAlign="text-center">
                <button
                    className="btn btn-outline-primary w-100"
                    type="submit"
                  >
                    {props.isSubmitting ? "Loading.." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateEducation;
