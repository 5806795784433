import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import matchService from "../../../services/match";

const NewCandidates = () => {
  const company = useSelector(({ company }) => company);
  const [candidates, set_candidates] = useState([]);

  useEffect(() => {
    if (company) {
      matchService
        .get_company_suggestions(company.id)
        .then((data) => set_candidates(data))
        .catch((err) => console.log("error"));
    }
  }, [company]);
  return (
    <div>
      {candidates.map((opening) => (
        <div className="new-candidate-card" key={opening.id}>
          <div className="new-candidate-card-text">
            You have new candidates for {opening.position.name}, which needs
            your review and approval
            <Link
              className="new_candidate-view-button"
              to={`/jp/opening/${opening.id}/filtered`}
            >
              View Candidates
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewCandidates;
