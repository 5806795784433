import companyService from '../services/company'
import {errorAlert, successAlert} from './alertReducer'
import { set_loading_false, set_loading_true } from './loadingReducer'
import { sidebar_change_size } from './sidebarReducer'


const companyReducer = (state=null, action) => {
    switch (action.type){

        case 'INIT_COMPANY':
            return action.data
            
        default:
            return state
    }
}

export const get_companies = () => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())

            const data = await companyService.getAll()
            dispatch({
                type:'INIT_COMPANY',
                data:data[0]
            })
            window.localStorage.setItem('has_company',  data.length === 1 ? true : false)
            
            if (window.innerWidth > 800 )
                dispatch(sidebar_change_size(180))
        
            dispatch(set_loading_false())
        }
        catch(exception){
            dispatch(set_loading_false())
            console.log("Error fetching companies")
        }
    }
}


export const update_company = (payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            const data = await companyService.update(payload)
            dispatch({
                type:'INIT_COMPANY',
                data: data
            })
            dispatch(successAlert("Company info updated!"));
            setTimeout(()=>dispatch(errorAlert("")),3000);
            dispatch(set_loading_false())

        }
        catch(exception){
            dispatch(set_loading_false)
            dispatch(errorAlert("There is a problem with updating company info!"));
        }
    }
}


export const create_company = (payload) => {
    return async dispatch => {
        try{
            dispatch(set_loading_true())
            await companyService.createCompany(payload)
            dispatch(get_companies())

            dispatch(set_loading_false())
            dispatch(successAlert("Company Added to the system!"));
            setTimeout(()=>dispatch(errorAlert("")),5000);
            
        }
        catch(exception){
            dispatch(set_loading_false())
            dispatch(errorAlert("Company cannot be added to the system, a company with the same name already exists!"));
        }
    }
}

export default companyReducer