import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import SingleSkill from "../../Shared/SingleSkill";
import { job_listing } from "../../../theme";

const Skills = (props) => {
  const [skills, set_skills] = useState([]);
  const [languages, set_languages] = useState([]);

  const general = useSelector(({ general }) => general);

  useEffect(() => {
    if (general.length !== 0) {
      const skills_array = general.skills.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      set_skills(skills_array);

      const languages_array = general.languages.map((lan) => {
        return { value: lan.id, label: lan.name };
      });
      set_languages(languages_array);
    }
  }, [general]);

  const handleRSkill = (choice) => {
    const nxt_skill = choice.value;
    if (
      !props.optional_skills.some((skill) => skill.id === nxt_skill) &&
      !props.required_skills.some((skill) => skill.id === nxt_skill)
    )
      // props.set_required_skills([...props.required_skills, choice.value])
      props.set_required_skills([
        ...props.required_skills,
        {
          id: choice.value,
          name: choice.label,
          years_of_experience: 0,
          importance: 1,
        },
      ]);
    else alert(`You already picked skill ${choice.label}`);
  };

  const handleOSkill = (choice) => {
    const nxt_skill = choice.value;

    if (
      !props.optional_skills.some((skill) => skill.id === nxt_skill) &&
      !props.required_skills.some((skill) => skill.id === nxt_skill)
    )
      props.set_optional_skills([
        ...props.optional_skills,
        {
          id: choice.value,
          name: choice.label,
          years_of_experience: 0,
          importance: 1,
        },
      ]);
    else alert(`You already picked skill ${choice.label}`);
  };

  const handleRequiredLanguage = (choice) => {
    const nxt_language = choice.value;

    if (
      !props.required_languages.some((lan) => lan.id === nxt_language) &&
      !props.optional_languages.some((lan) => lan.id === nxt_language)
    )
      props.set_required_languages([
        ...props.required_languages,
        {
          id: choice.value,
          name: choice.label
        }
      ]);
    else alert(`You already picked language ${choice.label}`);
  }

  const handleOptionalLanguage = (choice) => {
    const nxt_language = choice.value;

    if (
      !props.required_languages.some((lan) => lan.id === nxt_language) &&
      !props.optional_languages.some((lan) => lan.id === nxt_language)
    )
      props.set_optional_languages([
        ...props.optional_languages,
        {
          id: choice.value,
          name: choice.label
        }
      ]);
    else alert(`You already picked language ${choice.label}`);
  }

  const handleDeleteRequiredLanguage = (e, lang) => {
    e.preventDefault();
    props.set_required_languages(
      props.required_languages.filter((s) => s.name !== lang)
    );
  };
  
  const handleDeleteOptionalLanguage = (e, lang) => {
    e.preventDefault();
    props.set_optional_languages(
      props.optional_languages.filter((s) => s.name !== lang)
    );
  };

  const handleDeleteRequiredSkill = (e, skill) => {
    e.preventDefault();
    props.set_required_skills(
      props.required_skills.filter((s) => s.name !== skill)
    );
  };

  const handleDeleteOptionalSkill = (e, skill) => {
    e.preventDefault();
    props.set_optional_skills(
      props.optional_skills.filter((s) => s.name !== skill)
    );
  };

  const [valid, set_valid] = useState(true);
  const validate_skills = () => {
    return props.required_skills.length > 0;
  };

  return (
    <div>
      {/* <p className="font-weight-bold text-secondary">Required Skills *</p> */}
      <p className="font-weight-bold text-secondary">Required Experiences *</p>

      <Select onChange={handleRSkill} options={skills} />
      <br />

      <div style={job_listing.skill_list}>
        {props.required_skills.map((skill) => {
          return (
            <SingleSkill
              delete={handleDeleteRequiredSkill}
              key={skill.id}
              label={skill.name}
            />
          );
        })}
      </div>

      {/* <p className="font-weight-bold text-secondary">Optional Skills </p> */}
      <p className="font-weight-bold text-secondary"> Skills </p>
      <Select onChange={handleOSkill} options={skills} />
      <br />

      <div style={job_listing.skill_list}>
        {props.optional_skills.map((skill) => {
          return (
            <SingleSkill
              delete={handleDeleteOptionalSkill}
              key={skill.id}
              label={skill.name}
            />
          );
        })}
      </div>

      <p style={job_listing.header}> Languages </p>

      <p className="font-weight-bold text-secondary"> Required Languages </p>
      <Select onChange={handleRequiredLanguage} options={languages} />
      <div style={job_listing.skill_list}>
        {props.required_languages.map((language) => {
          return (
            <SingleSkill
              delete={handleDeleteRequiredLanguage}
              key={language.id}
              label={language.name}
            />
          );
        })}
      </div>

      <br/>
      <p className="font-weight-bold text-secondary"> Optional Languages </p>
      <Select onChange={handleOptionalLanguage} options={languages} />
      <div style={job_listing.skill_list}>
        {props.optional_languages.map((language) => {
          return (
            <SingleSkill
              delete={handleDeleteOptionalLanguage}
              key={language.id}
              label={language.name}
            />
          );
        })}
      </div>

      {!valid ? (
        <p  className="invalid-feedback bh-white mt-4" >
          Please select at least one required skill!
        </p>
      ) : null}

      <div className='mt-4'>
        <button className="btn btn-light w-50" onClick={props.prevSection}>
          Previous
        </button>
        <button
          className="btn btn-primary w-50"
          onClick={(e) => {
            if (validate_skills()) {
              props.nextSection(e);
              set_valid(true);
            } else set_valid(false);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Skills;
