import * as Yup from 'yup'

const  is_number = (char) => !isNaN(parseInt(char));

export const create_company_profile = Yup.object({
    name: Yup.string().required('Required'),
    address: Yup.string()
        .required('Required')
        .max(30, 'Should be less than 30 chars'),
    business_number: Yup.string()
        .required('Required')
        .trim()
        .matches(/^[0-9]*$/, 'Should contain only digits!')
        .length(9, 'Should be exactly 9 digits!'),
    // zip_code: Yup.string().required('Required')
    //     .test(value => {
    //         if(!value)return false
    //         return (value.length === 6 && /^\d+$/.test(value) )
    //                     ||
    //         (value.length===6 && !is_number(value[0]) && is_number(value[1])
    //                           && !is_number(value[2]) && is_number(value[3]) 
    //                           && !is_number(value[4]) && is_number(value[5])  )
    //     }),
    city: Yup.object().required('Required').nullable(),
    country: Yup.object().required('Required').nullable(),
    province: Yup.object().required('Required').nullable(),
    accepted_terms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    contact_name: Yup.string().required('Required'),
    // contact_email: Yup.string().email('Please enter a valid email').required('Required'),
    contact_phone: Yup
        .string('Required')
        .required('Required')
        .matches(/^[0-9]*$/, 'Should contain only digits!')
        .length(10, 'Should be exactly 10 digits!'),
    
})

const today = new Date();
export const update_listing = Yup.object({
    nr_employees_wanted: Yup.number()
        .min(1, 'Should be more than 1')
        .max(200, 'Should be less than 200')
        .required('Required'),
    daily_hours: Yup.number()
        .min(1, 'Should be more than 1')
        .max(24, 'Should be less than 24')
        .required('Required'),
    rate_per_hour: Yup.number()
        .min(1, 'Should be more than 1')
        .max(1001, 'Should be less than 1000')
        .required('Required'),
    job_time_length_quantity: Yup.number().min(1, 'Should be more than 1').required('Required'),

    address: Yup.string()
        .required('Required')
        .max(30, 'Should be less than 30 chars'),
    start_date: Yup.date().required('Required').min(today, 'Start date cannot be in the past'),
    
})