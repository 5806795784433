import React, { useState } from "react";
import Select from "react-select";
import InfoIcon from "@material-ui/icons/Info";
import { jl_how_many_times } from "../../../validations/Info";

function time_now() {
  const now = new Date();
  var d = new Date(now),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const JTL_CHOICES = [
  { value: "Hour", label: "Hour" },
  { value: "Day", label: "Day" },
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Piece of work", label: "Piece of work" },
  { value: "Permanent", label: "Permanent" },
];

const SHIFT_CHOICES = [
  { value: "Shift 1", label: "Shift 1" },
  { value: "Shift 2", label: "Shift 2" },
  { value: "Shift 3", label: "Shift 3" },
];

const Length = (props) => {
  const [valid, set_valid] = useState(true);

  const handleShift = (choice) => {
    props.set_shift(choice.value);
  };

  const handleJobTimeLength = (choice) => {
    props.set_job_time_length(choice.value);
  };
  
  const set_info = (text) => {
    alert(text)
  }

  const [shift_error, set_shift_error] = useState(false);
  const [jtl_error, set_jtl_error] = useState(false);
  const [jtlq_error, set_jtlq_error] = useState(false);
  const [hours_error, set_hours_Error] = useState(false);
  const [rate_error, set_rate_error] = useState(false);
  const [start_date_error, set_start_date_error] = useState(false);

  const validate_form = () => {
    // return true

    if (props.job_time_length === 'Permanent'){
      props.job_time_length_quantity.setValue(1)
    }

    set_shift_error(props.shift.length > 2 ? false : true);
    set_jtl_error(props.job_time_length ? false : true);
    set_jtlq_error(props.job_time_length_quantity.value > 0 ? false : true);
    set_jtlq_error(
      (props.job_time_length_quantity.value < 300 && props.job_time_length !== 'Permanent') 
      ? false 
      : true);
    set_hours_Error(props.daily_hours.value > 0 ? false : true);
    set_hours_Error(props.daily_hours.value < 25 ? false : true);
    set_rate_error(props.rate_per_hour.value > 0 ? false : true);
    set_rate_error(props.rate_per_hour.value < 1000 ? false : true);
    set_start_date_error(props.start_date >= time_now() ? false : true);

    return (
      props.shift.length > 2 &&
      props.job_time_length.length > 2 &&
      (props.job_time_length_quantity.value > 0 || props.job_time_length === 'Permanent' )&&
      props.job_time_length_quantity.value < 300 &&
      props.daily_hours.value > 0 &&
      props.daily_hours.value < 25 &&
      props.rate_per_hour.value > 0 &&
      props.rate_per_hour.value < 1000 &&
      props.start_date >= time_now()
    );
  };


  const handleNext = (e) => {
    if (validate_form()) {
      props.nextSection(e);
      set_valid(true);
    } else set_valid(false);
  }


  return (
    <div>
      <form>
        <p className="font-weight-bold text-secondary">
          Schedule/Shift * <InfoIcon className="float-right" />
        </p>
        <Select
          options={SHIFT_CHOICES}
          onChange={handleShift}
          value={SHIFT_CHOICES.find((p) => p.value === props.shift)}
        />
        {shift_error ? <p className="invalid-feedback">Required</p> : null}
        <br />
        <p className="font-weight-bold text-secondary">
          Job period *<InfoIcon className="float-right" />
        </p>
        <Select
          options={JTL_CHOICES}
          onChange={handleJobTimeLength}
          value={JTL_CHOICES.find((p) => p.value === props.job_time_length)}
        />
        {jtl_error ? <p className="invalid-feedback">Required</p> : null}
        <br />

        {props.job_time_length !== 'Permanent' &&
        <div className="form-group" controlId="hmt">
          <p className="font-weight-bold text-secondary">
            How many times *
            <InfoIcon 
              className="float-right"
              style={{cursor:'pointer' }}
              onClick={() => set_info(jl_how_many_times)}
            />
          </p>
          <input className="form-control"
            {...props.job_time_length_quantity}
            placeholder="How many times"
          />
          {jtlq_error ? (
            <p className="invalid-feedback">Enter a valid number</p>
          ) : null}

        </div> }
        <div className="form-group" controlId="hmt">
          <p className="font-weight-bold text-secondary">
            Start date *<InfoIcon className="float-right" />
          </p>
          <input className="form-control"
            type="date"
            value={props.start_date}
            onChange={(e) => props.set_start_date(e.target.value)}
          />
          {start_date_error ? (
            <p className="invalid-feedback">Please choose a valid date</p>
          ) : null}
        </div>
        <div className="form-row">

          <div className="form-group col" controlId="hmt">
              <label className="font-weight-bold text-secondary">Daily Hours *</label>
              <input className="form-control" {...props.daily_hours} placeholder="Daily Hours" />
              {hours_error ? (
                <p className="invalid-feedback">Range 1-24</p>
              ) : null}
          </div>

          <div className="form-group col" controlId="hmt">
              <label className="font-weight-bold text-secondary">Rate per hour *</label>
              <input className="form-control" {...props.rate_per_hour} placeholder="Rate per hour" />
              {rate_error ? (
                <p className="invalid-feedback">Enter a valid number</p>
              ) : null}
          </div>
        </div>
      </form>

      {/* Validations */}

      {!valid ? (
        <p className="invalid-feedback bg-white mt-4">
          All the fields are required!
        </p>
      ) : null}

      {/* Buttons */}

      <div>
        <button className="btn btn-light w-50" onClick={props.prevSection}>
          Previous
        </button>

        <button className="btn btn-primary w-50" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Length;
