import React, { useEffect, useState } from "react";
import matchService from "../../../services/match";

import OfferTabs from "./OfferTabs";
import MenuButton from "../../ReusableComponents/MenuButton";
import { useDispatch } from "react-redux";
import {
  set_loading_true,
  set_loading_false,
} from "../../../reducers/loadingReducer";
import { Link } from "react-router-dom";
import ExtensionOfferTabs from "./Extension/ExtensionOfferTabs";
import Notifications from "../../Shared/Notification/Notifications";

const OfferList = () => {
  const [filter, set_filter] = useState("Pending");
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  useEffect(() => {
    dispatch(set_loading_true());
    matchService
      .getOffersForJS()
      .then((data) => {
        setOffers(
          data.filter((offer) => {
            if (filter === "All") {
              return true;
            } else if (filter === "Won") {
              return offer.match_status === "Accepted";
            } else if (filter === "Declined") {
              return offer.match_status === "Declined";
            } else if (filter === "Pending") {
              return offer.match_status === "Pending";
            } else return false;
          })
        );
        dispatch(set_loading_false());
      })
      .catch((error) => {
        console.log("Error");
        dispatch(set_loading_false());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  return (
    <div className="logged-in-container">
      <br/>
      <MenuButton
        handle_click={(e) => {
          e.preventDefault();
          set_filter("Pending");
        }}
        is_active={filter === "Pending"}
        label={"Pending"}
      />
      <MenuButton
        handle_click={(e) => {
          e.preventDefault();
          set_filter("Won");
        }}
        is_active={filter === "Won"}
        label={"Won"}
      />
      <MenuButton
        handle_click={(e) => {
          e.preventDefault();
          set_filter("Declined");
        }}
        is_active={filter === "Declined"}
        label={"Declined"}
      />
      <MenuButton
        handle_click={(e) => {
          e.preventDefault();
          set_filter("All");
        }}
        is_active={filter === "All"}
        label={"All"}
      />

      <OfferTabs />
      <ExtensionOfferTabs/>
      <Notifications/>
      
      {offers.length === 0 ? (
        <p className="text-muted font-weight-bold mt-4 text-center">
          No offers available for the moment.
        </p>
      ) : null}

      <div className="custom-table-div">
        <table className="custom-table">
          <thead>
            <tr>
              <td>
                <b>Position</b>
              </td>
              <td>
                <b>Company</b>
              </td>
              <td>
                <b>Start date</b>
              </td>
              <td>
                <b>Match date</b>
              </td>
              <td>
                <b>Job Period</b>
              </td>
              <td>
                <b>Location</b>
              </td>
              <td>
                <b>Hourly Rate</b>
              </td>
              <td style={{ textAlign: "right" }}>
                <b>Status</b>
              </td>
            </tr>
          </thead>

          <tbody>
            {offers.map((offer) => (
              <tr className="custom-table-row" key={offer.id}>
                <td>
                  <Link
                    to={
                      offer.match_status === "Pending"
                        ? `/js/offers/${offer.id}?`
                        : `/js/offers/${offer.id}?detail=yes`
                    }
                  >
                    {" "}
                    {offer.interested_position.position.name}{" "}
                  </Link>
                </td>

                <td>{offer.job_listing.company.name}</td>

                <td>
                  {offer.response_time
                    ? new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                      }).format(
                        Date.parse(offer.response_time)
                      )
                    : "--"}
                </td>

                <td>
                  {offer.start_date
                    ? new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                      }).format(
                        Date.parse(offer.start_date)
                      )
                    : "--"}
                </td>

                <td>
                  {offer.job_listing.job_time_length_quantity}{" "}
                  {offer.job_listing.job_time_length}s
                </td>

                <td>
                  {offer.job_listing.province.name},{" "}
                  {offer.job_listing.city.name}
                </td>
                
                <td>
                  {offer.initial_net_payment.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </td>

                <td
                  style={{
                    textAlign: "right",
                    color:
                      offer.match_status === "Accepted"
                        ? "#00ccaa"
                        : offer.match_status === "Declined"
                        ? "#ee5566"
                        : "black",
                  }}
                >
                  {offer.match_status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfferList;
