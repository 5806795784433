import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {CloseButton,Modal} from 'react-bootstrap'
import { errorAlert } from '../reducers/alertReducer';



const CustomAlert = () => {
  const dispatch = useDispatch()
    const alert = useSelector(({alert})=>alert)
    if(alert.text){
       return (

        <Modal
          show={true}
        >
        <Modal.Header style={{
            backgroundColor: alert.error ?'rgba(197,94,93)':'rgba(39,203,117)',
            color: alert.error ? '#ffffff' : 'black'

            }}>
          { alert.error ?'Error': 'Success' }
          
          <CloseButton onClick={(e)=>{e.preventDefault();dispatch(errorAlert(''))}}/>
        
        </Modal.Header>

        <Modal.Body 
          style={{
            backgroundColor:'#ffffff',
            color:'black'
        }}
          
        >
          <div className='d-flex justify-content-between'>
          {alert.text}
          {alert.error ? 
          <button  
              onClick={(e)=>{e.preventDefault();dispatch(errorAlert(''))}} 
              style={{border:'none'}}
              className='btn btn-danger'
            >Ok</button> : null}
          </div>
        </Modal.Body>

      </Modal>
       )
    }
    return null
}

export default CustomAlert;