
const sidebarReducer = (state=0, action) => {
    switch (action.type){

        case 'SIDEBAR_CHANGE_SIZE':
            return action.size

        case 'SIDEBAR_MINIMIZE':
            return action.size
            
        default:
            return state
    }
}

export const sidebar_change_size = (size) => {
    return ({
        type: 'SIDEBAR_CHANGE_SIZE',
        size: size
    })
}


export default sidebarReducer;
