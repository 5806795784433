import React, { useState } from "react";
import MyProfileJP from "./Profile/MyProfileJP";
import Preferences from "./Profile/Preferences";
import Membership from "./Profile/Membership";
import Documents from "./Profile/Documents";
import NewCandidates from "../JobOpening/MatchNotification/NewCandidates";
import { useSelector } from "react-redux";
import { ProfileDeclinedJP, WaitForApprovalJP } from "../Shared/ApprovalMessage";
import Notifications from "../Shared/Notification/Notifications";
import { useHistory } from "react-router-dom";

const HomeJP = () => {
  const [section, set_section] = useState("Profile");
  const company = useSelector(({ company }) => company);
  const user_id = useSelector( ({ user }) => user.id)
  const history = useHistory()

  return (
    <div className="logged-in-container account">
      <NewCandidates />
      <Notifications/>
      {company?.is_approved === "Pending" && <WaitForApprovalJP />}
      {company?.is_approved === "Declined" && <ProfileDeclinedJP user_id={user_id} />}
      <br />
      <div className="home-tab-nav">
        {/* Profile */}
        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Profile");
          }}
        >
          Profile{" "}
        </p>

        <hr />

        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Preferences");
          }}
        >
          Preferences
        </p>

        <hr />

        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Documents");
          }}
        >
          Documents
        </p>

        <hr />
        {/* Membership */}
        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            set_section("Membership");
          }}
        >
          Membership Plan
        </p>

        <hr />
        {/* Membership */}
        <p
          className="home-tab-text"
          onClick={(e) => {
            e.preventDefault();
            history.push('/approvalhistory')
          }}
        >
          Approval history
        </p>
      </div>

      {section === "Profile" ? <MyProfileJP /> : null}

      {section === "Preferences" ? <Preferences /> : null}

      {section === "Membership" ? <Membership /> : null}

      {section === "Documents" ? <Documents /> : null}
    </div>
  );
};

export default HomeJP;
