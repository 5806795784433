import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { update_js_info } from "../../../reducers/jsReducer";

import CustomButton from "../../ReusableComponents/CustomButton";
import CustomCheckbox from "../../ReusableComponents/CustomCheckbox";
import MenuButton from "../../ReusableComponents/MenuButton";
import {
  ProfileDeclinedJS,
  ProfileRejectedJS,
  WaitForApprovalJS,
} from "../../Shared/ApprovalMessage";
import OfferTabs from "../Offers/OfferTabs";
import ExtensionOfferTabs from '../Offers/Extension/ExtensionOfferTabs'
import UpdateInterestedPosition from "./UpdateInterestedPosition";
import Notifications from "../../Shared/Notification/Notifications";


const styles = {
  header: {
    textAlign: "center",
  },
  name_button: {
    background: "none",
    color: "rgb(73,154,243)",
    border: "none",
  },
};

const ListedPositions = () => {
  const dispatch = useDispatch();
  const interestedJobs = useSelector(({ interestedJobs }) => interestedJobs);
  const completed_profile = useSelector(
    ({ job_seeker_info }) => job_seeker_info.completed_profile
  );
  const [show_edit, set_show_edit] = useState(false);
  const [selected_position, set_selected_position] = useState(null);
  const available_for_work = useSelector(
    ({ job_seeker_info }) => job_seeker_info.available_for_work
  );
  const is_approved = useSelector(
    ({ job_seeker_info }) => job_seeker_info.approved_by_system
  );
  const user_id = useSelector(
    ({ job_seeker_info }) => job_seeker_info.user.id
  );

  const handle_edit_click = (e, id) => {
    e.preventDefault();
    set_show_edit(true);
    set_selected_position(interestedJobs.find((i) => i.id === id));
  };

  const handle_available = () => {
    const payload = { available_for_work: !available_for_work };
    dispatch(update_js_info(payload));
  };

  return (
    <div className="logged-in-container">
      {is_approved === "Pending" && <WaitForApprovalJS />}
      {is_approved === "Declined" && <ProfileDeclinedJS user_id = {user_id} />}
      {is_approved === "Rejected" && <ProfileRejectedJS user_id = {user_id} />}
      {show_edit ? (
        <UpdateInterestedPosition
          set_show_edit={set_show_edit}
          position={selected_position}
        />
      ) : null}
      <p className="font-weight-bold text-secondary h5 mt-4 mb-4 text-center"> Positions you are interested</p>
      <MenuButton is_active={true} label={"All"} />
      <p className="float-right">     
      <CustomCheckbox
        label={"Available for work"}
        onChange={handle_available}
        checked={available_for_work}
      /></p>
   
      <OfferTabs />
      <ExtensionOfferTabs/>
      <Notifications/>

      <br /> <br />
      {completed_profile && interestedJobs.length < 10 ? (
        <Link className="float-right" to="/js/addposition">
          <button className="btn btn-primary w-100 font-weight-bold" >Add new position</button>
        </Link>
      ) : (
        <div className="float-right">
          <CustomButton label={"Max of 10 Jobs posted"} />
        </div>
      )}
      <br /> <br />
      <div className="custom-table-div" style={{ marginTop: 5 }}>
        <table className="custom-table">
          <thead>
            <tr>
              <td>
                <b>Job Position</b>
              </td>
              <td>
                <b>Start date</b>
              </td>
              <td style={{ textAlign: "right" }}>
                <b>Desired Rate</b>
              </td>
            </tr>
          </thead>

          <tbody>
            {interestedJobs.map((job) => (
              <tr className="custom-table-row" key={job.id}>
                <td>
                  <button
                    style={styles.name_button}
                    onClick={(e) => handle_edit_click(e, job.id)}
                  >
                    {job.position.name}
                  </button>
                </td>

                <td>
                  {job.start_date
                    ? new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                      }).format(Date.parse(job.start_date))
                    : "Not provided"}
                </td>

                <td className="text-right">${job.desired_rate_hour}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {completed_profile ? (
        <Link className="job-listings-page-button2" to="/js/addposition">
          <button className="btn btn-primary w-75 font-weight-bold" >Add new position</button>
        </Link>
      ) : null}
    </div>
  );
};

export default ListedPositions;
