import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'


const io = require('socket.io-client')
// const socket = io('http://localhost:9000',{transports: ['websocket']})
const socket = io('https://websocket.rapihire.com',{transports: ['websocket']})


const SocketService = ( {fire_message} ) => {
    const user_id = useSelector( ({user}) => user?.id )

    const listen_socket = () => {
        socket.on('message', (payload)=>{
            const receiverID = payload.receiver.id

            if (receiverID === user_id) {
                if (fire_message){
                 fire_message(payload)
                }
            }
        })
    }

    useEffect(()=>{
        if (user_id) listen_socket()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user_id])
    
    return <> </>
}

export default SocketService;
