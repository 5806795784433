import React from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import SingleSkill from "../../../Shared/SingleSkill";
import {
  add_job_seeker_skills,
  delete_job_seeker_skill,
} from "../../../../reducers/skillReducer";

const styles = {
  skill_list: {
    display: "flex",
    flexWrap: "wrap",
  },
  year_input: {
    width: "60px",
    background: "none",
    border: "2px solid green",
    borderRadius: "8px",
    paddingLeft: "7px",
    height: "40px",
  },
};

const Skills = () => {
  const dispatch = useDispatch();


  const skills = useSelector(({ general }) =>
    general.skills.map((skill) => {
      return { value: skill.id, label: skill.name };
    })
  );

  const my_skills = useSelector(({ job_seeker_skills }) => job_seeker_skills);

  // Make sure user doesn't choose the same skill twice
  const handleSkill = (choice) => {
    const nxt_skill = choice.value;
    if (
      !my_skills.some((skill_info) => skill_info.skill.id === nxt_skill)
    ) {
      addSkills(nxt_skill);
    } else {
      alert(`You already chose the skill ${choice.label}`);
    }
  };

  const delete_your_skill = (e, skill_id) => {
    e.preventDefault();
    dispatch(delete_job_seeker_skill(skill_id));
  };


  const addSkills = (skill_id) => {
    const skills_to_add = [{
      skill_id: skill_id,
      years_of_experience:1,
    }]

    dispatch(add_job_seeker_skills(skills_to_add));
  };


  return (
    <div className="logged-in-container">
      <div className="resume-item-page">
    
        <p className="font-weight-bold text-secondary h5">
          My Skills
        </p>
        <div className="text-left">
          <Select onChange={handleSkill} options={skills} />
          <br />
        </div>
        
        <div style={styles.skill_list}>
          {my_skills.map((skill_info) => {
            return (
              <SingleSkill
                key={skill_info.id}
                years_of_experience={skill_info.years_of_experience}
                label={skill_info.skill.name}
                delete={(e) => delete_your_skill(e, skill_info.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Skills;
