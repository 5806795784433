import React from 'react'
import '../../../styles/account-jp.css';

const styles = {

  label:{
    textAlign:'center'
  }
}

const LegalPreview = () => {

    return (
      <div className="legal-container">
      <div>
      <p style={styles.label} className="font-weight-bold h3 text-secondary  mt-4 float-center">
              Legal Agreements
      </p>  
      <hr/>
      <a  className="legal-agreements" target='_blank' href={`https://rapihire.com/official_docs/terms&conditions.html`}>
      <p style={styles.label} className="font-weight-bold h5 text-secondary legal-agreements mt-4 float-center">
              Terms and Conditions
      </p>
     </a> 
      </div>
      <div>
      <a  className="legal-agreements" target='_blank' href={`https://rapihire.com/official_docs/privacy.html`}>
      <p style={styles.label} className="font-weight-bold h5 text-secondary legal-agreements mt-4">
      Privacy Policy
      </p>
     </a> 
      </div>
      <div>
        <a  className="legal-agreements" target='_blank' href={`https://rapihire.com/official_docs/cookies.html`}>
        <p style={styles.label} className="font-weight-bold h5 text-secondary legal-agreements mt-4">
          Cookies Policy
        </p>
      </a> 
      </div>
      <div>
        <a  className="legal-agreements" target='_blank' href={`https://rapihire.com/official_docs/eula.html`}>
        <p style={styles.label} className="font-weight-bold h5 text-secondary legal-agreements mt-4">
          EULA
        </p>
      </a> 
      </div>
      </div>

    );
};

export default LegalPreview;
