import React, { useEffect, useState } from "react";
import userService from "../../services/user";
import generalService from "../../services/general";
import { errorAlert, successAlert } from "../../reducers/alertReducer";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FormikTextInput from "../ReusableComponents/FormikTextInput";
import FormikDropDown from "../ReusableComponents/FormikDropDown";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Formik, Form } from "formik";
import { register_validation_js } from "../../validations/Authentication";
import {
  set_loading_false,
  set_loading_true,
} from "../../reducers/loadingReducer";
import FormikCheckBox from "../ReusableComponents/FormikCheckBox";
import TwilioConfirmNumberModal from "../Shared/TwilioConfirmNumberModal";
import FormikPhoneNumber from "../ReusableComponents/FormikPhoneNumber";
import logo from "../../icons/logo_blue.png";
import data from "./countries.json"
const COUNTRIES = data

const RegisterJS = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [show_password, set_show_password] = useState(false);
  const [countries, set_countries] = useState([]);
  const [show_phone_confirm, set_show_phone_confirm] = useState(false);
  const [payload, set_payload] = useState({});

  const tooglePassword = (e) => {
    e.preventDefault();
    set_show_password(!show_password);
  };

  useEffect(() => {
    // let country_array = COUNTRIES.map((pos) => {
    //   return { value: pos.id, label: pos.name };
    // });
    // set_countries(country_array);
    generalService.listAllCountries().then((data) => {
      // set_all_countries(data)
      let country_array = data.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      set_countries(country_array);
    });
  }, []);

  const search = useLocation().search;
  const referrer_id = new URLSearchParams(search).get("uid");
  const hashed_email = new URLSearchParams(search).get("he");

  const [emails_not_match, set_emails_not_match] = useState(false);
  const [country_not_selected, set_country_not_selected] = useState(false);

  const handle_registration = async (payload) => {
    dispatch(set_loading_true());
    try {
      await userService.createJS(payload);
      history.push("/registeredsuccess");
      dispatch(set_loading_false());
      // dispatch(
      //   successAlert(
      //     "Thank You! You successfully registered! Please check the confirmation email we sent to your email address!"
      //   )
      // );
      // setTimeout(() => dispatch(errorAlert("")), 6000);
    } catch (exception) {
      dispatch(set_loading_false());
      dispatch(errorAlert("An account with this email already exists!"));
    }
  };

  const handle_twilio = async (phone_number) => {
    try {
      dispatch(set_loading_true());
      const res = await userService.twilio_send_code(phone_number);
      if (res.status === "pending") {
        set_show_phone_confirm(true);
        dispatch(set_loading_false());
      }
    } catch (err) {
      console.log(err);
      dispatch(set_loading_false());
      dispatch(
        errorAlert(
          "Your phone number is unreachable! Please make sure you enter a Canadian/US phone number!"
        )
      );
    }
  };

  return (
    <div className="text-right mt-4">
      <Link to="/login" className=" p-5 ">
        Log in
      </Link>

      <div style={{ marginTop: "1vh" }} />
      <div className="text-center mb-3">
        <img id="logo-img" style={{ width: 35, margin: 7 }} src={logo} />{" "}
        <span
          style={{ fontSize: 24, color: "#022859" }}
          className="font-weight-bold"
        >
          RAPIHIRE
        </span>
      </div>
      <div className="form-width-size m-auto p-4 shadow text-left">
        <p className="font-weight-bold text-secondary h5 mb-4">
          Create an account - Job Seeker
        </p>

        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            country: null,
            email: "",
            confirm_email: "",
            password: "",
            confirm_password: "",
            phone_number: "",
            accepted_terms: false,
          }}
          validationSchema={register_validation_js}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // resetForm()
            setSubmitting(false);

            if (
              values.email.toLowerCase() !== values.confirm_email.toLowerCase()
            ) {
              set_emails_not_match(true);
            }
            if (!values.country) {
              set_country_not_selected(true);
            } else {
              set_emails_not_match(false);
              set_country_not_selected(false);

              const payload = {
                first_name: values.first_name,
                last_name: values.last_name,
                country_id: values.country.id,
                email: values.email,
                password: values.password,
                phone_number: values.phone_number,
                referrer_id,
                hashed_email,
              };

              set_payload(payload);
              handle_twilio(values.phone_number);
            }
          }}
        >
          {(props) => (
            <Form>
              <FormikTextInput name="first_name" label="First Name *" />
              <FormikTextInput name="last_name" label="Last Name *" />
              <p className="text-input-label mt-3">Country *</p>
              <FormikDropDown
                options={countries}
                name="country"
                placeholder={"Country"}
                value={countries.find(
                  (pos) => pos.value === props.values.country?.id
                )}
              />
              {country_not_selected && (
                <span className="invalid-feedback">Please select country!</span>
              )}
              <FormikTextInput
                placeholder={"example@email.com"}
                name="email"
                label="Email *"
              />
              <FormikTextInput name="confirm_email" label="Repeat Email *" />
              {emails_not_match && (
                <span className="invalid-feedback">Emails must match!</span>
              )}
              <div className="pwd-container">
                <FormikTextInput
                  type={show_password ? "text" : "password"}
                  name="password"
                  label="Password *"
                />
                {show_password ? (
                  <VisibilityOffIcon
                    onClick={tooglePassword}
                    className="icon"
                  />
                ) : (
                  <VisibilityIcon onClick={tooglePassword} className="icon" />
                )}
              </div>
              <FormikTextInput
                type={show_password ? "text" : "password"}
                name="confirm_password"
                label="Repeat Password *"
              />
              <FormikPhoneNumber
                type={"text"}
                id={"phone"}
                name="phone_number"
                label="Phone Number *"
                placeholder={"1234567890"}
              />

              <br />
              <div className="text-center">
                <FormikCheckBox
                  checked={props.values.accepted_terms}
                  name="accepted_terms"
                />{" "}
                I Accept{" "}
                <a
                  style={{ color: "rgb(73,154,243)" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://rapihire.com/official_docs/terms&conditions.html`}
                >
                  {" "}
                  Terms and Conditions{" "}
                </a>
                , including
                <a
                  style={{ color: "rgb(73,154,243)" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://rapihire.com/official_docs/privacy.html`}
                >
                  {" "}
                  Privacy Policy{" "}
                </a>
                ,
                <a
                  style={{ color: "rgb(73,154,243)" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://rapihire.com/official_docs/cookies.html`}
                >
                  {" "}
                  Cookies Policy{" "}
                </a>{" "}
                and
                <a
                  style={{ color: "rgb(73,154,243)" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://rapihire.com/official_docs/eula.html`}
                >
                  {" "}
                  EULA{" "}
                </a>
                <span className="invalid-feedback mt-4 mb-2">
                  * Please accept Terms and Conditions to register!
                </span>
                <button
                  disabled={!props.values.accepted_terms}
                  className="btn btn-primary w-50"
                  type="submit"
                >
                  {props.isSubmitting ? "Loading..." : "Register"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <TwilioConfirmNumberModal
        payload={payload}
        show_phone_confirm={show_phone_confirm}
        set_show_phone_confirm={set_show_phone_confirm}
        handle_registration={handle_registration}
      />
    </div>
  );
};

export default RegisterJS;
