import React from 'react'
import userService from "../../../services/user";


const SingleNotification = ({notification, set_notifications, notifications}) => {

    const handle_close = async (e) => {
        e.preventDefault()

        try{
            await userService.close_notification({notification_id: notification.id})
            set_notifications(
                notifications.filter(n => n.id !== notification.id)
            )
        }
        catch(err){
            console.log('Error');
        }

    }

    return (
        <div className="new-candidate-card bg-danger" key={notification.id}>
        <div className="new-candidate-card-text">
            {" "}
            {notification.content}
            <button
              className="new_candidate-view-button text-danger font-weight-bold"
              onClick={handle_close} 
            >
            Close
            </button>
        </div>
        </div>
    )
}

export default SingleNotification;