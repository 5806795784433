import React from "react";
// import '../JobOpening/styles.css'
import MenuButton from "../ReusableComponents/MenuButton";
import { Link } from "react-router-dom";
import { logged_in } from "../../theme";

const styles = {
  info: {
    color: "#839eaf",
    fontSize: "16px",
    float: "right",
  },
  unpaid: {
    color: "#f84071",
    fontSize: "28px",
    fontWeight: 500,
    marginLeft: "10px",
  },
};

const InvoicesList = () => {
  return (
    <div style={{ ...logged_in.container, width: "100%" }}>
      <div className="job-listings-page">
        <MenuButton is_active={true} label={"Due"} />
        <MenuButton label={"Active"} />
        <MenuButton label={"Paid"} />

        <p style={styles.info}>
          Total amount of unpaid invoices:
          <Link to="/jp/invoices" style={styles.unpaid}>
            $1234.123
          </Link>
        </p>

        <div className="custom-table-div">
          <table className="custom-table">
            <thead>
              <tr>
                <td>
                  <b>Name</b>
                </td>
                <td className="text-right">
                  <b>Invoice ID</b>
                </td>
                <td className="text-right">
                  <b>Due date</b>
                </td>
                <td className="text-right">
                  <b>Open Amount</b>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr className="custom-table-row">
                <td>
                  <Link to="/jp/invoice/pay">John Doe</Link>
                </td>

                <td className="text-right">{100412341}</td>
                <td className="text-right">12/06/2021</td>
                <td className="text-right">$1234.123</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoicesList;
