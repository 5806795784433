import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import matchService from "../../../services/match";
import { Link, useParams } from "react-router-dom";
import {
  set_loading_true,
  set_loading_false,
} from "../../../reducers/loadingReducer";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@material-ui/icons/Star";

const CandidateList = () => {
  const dispatch = useDispatch();
  const [filtered_candidates, set_filtered_candidates] = useState([]);

  const opening_id = useParams().id;
  const jobOpening = useSelector(({ jobOpenings }) =>
    jobOpenings.find((j) => j.id === Number(opening_id))
  );
  const is_loading = useSelector(({ is_loading }) => is_loading);

  useEffect(() => {
    if (jobOpening) {
      dispatch(set_loading_true());
      matchService
        .getCandidates(jobOpening.id)
        .then((data) => {
          set_filtered_candidates(data);
          dispatch(set_loading_false());
        })
        .catch((error) => {
          console.log(error);
          dispatch(set_loading_false());
        });
    }
  }, [jobOpening]);

  if (!jobOpening) {
    return (
      <div style={{ textAlign: "center", paddingTop: "100px" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  }

  return (
    <div className="logged-in-container">
      <div className="candidates-div-list">
        <p className="candidates-div-list-header">
          These are the top candidates for the position{" "}
          {jobOpening.position.name}
        </p>

        {!is_loading && filtered_candidates.length === 0 ? (
          <h4>No suggestions available yet.</h4>
        ) : null}

        <div className="candidates-div-list-candidates">
          {filtered_candidates.map((candidate_position) => (
            <Link
              key={candidate_position.id}
              to={`/jp/opening/${opening_id}/filtered/${candidate_position.id}`}
            >
              <div className="candidates-div-list-candidate">
                <img alt="Rapihire candidate" src={candidate_position.job_seeker.profile_image} />
                <div className="candidates-div-list-candidate-name">
                  {candidate_position.job_seeker.first_name} - <StarIcon fontSize='large'/> {candidate_position.average_rating ? candidate_position.average_rating : 'N/A'}
                </div>
              </div>
            </Link>
          ))}
        </div>

        <p className="candidates-div-list-warning">
          *Faster you accept the candidates, more chances to have them. We can
          not guaranty that this candidates will be available on the moment you
          will accept them, because another company might accept before you.
        </p>
      </div>
    </div>
  );
};

export default CandidateList;
