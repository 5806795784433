import React from 'react'

const CustomCheckbox = (props) => {
    return (
    <>
        <label className="switch">
        <input type='checkbox' checked={props.checked}  onChange={props.onChange} />
        <span className="slider round"></span>
        </label> {' '} <span style={{fontWeight:500, color:'#445566'}}>{props.label}</span>
    </>
    )
}

export default CustomCheckbox;