import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import RangeSlider from "../ReusableComponents/RangeSlider";
import { update_js_info } from "../../reducers/jsReducer";
import { job_listing } from "../../theme";
import { Link, useHistory } from "react-router-dom";
import { sidebar_change_size } from "../../reducers/sidebarReducer";
import { logout } from "../../reducers/userReducer";
import { Formik, Form } from "formik";
import { job_seeker_create_profile } from "../../validations/seeker_profile";

import FormikTextInput from "../ReusableComponents/FormikTextInput";
import FormikDropDown from "../ReusableComponents/FormikDropDown";
import { errorAlert } from "../../reducers/alertReducer";
import {
  set_loading_false,
  set_loading_true,
} from "../../reducers/loadingReducer";
import {
  validate_canada_zip,
  validate_us_zip,
} from "../../validations/GenralValidations";

const OptionButton = (props) => {
  return (
    <button
      className="option-button"
      style={{
        backgroundColor: props.checked ? "#249afa" : "white",
        color: props.checked ? "white" : "black",
      }}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const CreateProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [job_distance, set_job_distance] = useState(60);
  const [commute_time, set_commute_time] = useState(60);

  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [zip_error, set_zip_error] = useState(false);

  const [allowed_work, set_allowed_work] = useState(true);
  const [remote, set_remote] = useState("Yes");
  const [employment_type, set_employment_type] = useState("Both");

  const general = useSelector(({ general }) => general);
  const user = useSelector(({ user }) => user);

  const js_data = useSelector(({ job_seeker_info }) => job_seeker_info);
  const [address_error, set_address_error] = useState(false);

  const validate_address = (country, province, city) => {
    const country_id = general.countries.find(c => Number(c.id) === Number(country.id)).id
    const province_id = general.provinces.find(c => Number(c.id) === Number(province.id)).id
    const province_country_id = general.provinces.find(c => Number(c.id) === Number(province.id)).country.id
    const city_province_id = general.cities.find(c => Number(c.id) === Number(city.id)).province.id
    if(country_id === province_country_id && province_id === city_province_id){
        set_address_error(0)
        return true
    }
    set_address_error(1)
    return false
  }

  useEffect(() => {
    dispatch(set_loading_true());
    dispatch(sidebar_change_size(0));
    if (js_data.completed_profile === true) {
      history.push("/js/createresume");
      window.location.reload();
    }
    if (general.length !== 0) {
      const countryArray = general.countries.map((pos) => {
        return { value: pos.id, label: pos.name };
      });
      setCountries(countryArray);
      const cityArray = general.cities.map((pos) => {
        return { value: pos.id, label: pos.name, province: pos.province };
      });
      setCities(cityArray);
      const provinceArray = general.provinces.map((pos) => {
        return { value: pos.id, label: pos.name, country: pos.country };
      });
      setProvinces(provinceArray);
    }
    dispatch(set_loading_false());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, general, js_data, dispatch]);

  if (!user || !js_data) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner style={{}} animation="border" />
      </div>
    );
  }

  const handleLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  const validate_zip = (zip, country) => {
    return country.name === "USA"
      ? !validate_us_zip(zip)
      : !validate_canada_zip(zip);
  };

  return (
    <div className="logged-in-container">
  
      <Link
        to="/login"
        onClick={handleLogout}
        className="float-right text-danger"
      >
        Logout
      </Link>

      <div className="profile-create-div">
        <p className="font-weight-bold text-secondary h4 mt-4 text-left">
          Please provide the info to create profile
        </p>
        <hr />

        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            telephone: js_data.telephone ? js_data.telephone : "",
            address: js_data.address ? js_data.address : "",
            province: js_data.province ? js_data.province : null,
            city: js_data.city ? js_data.city : null,
            country: js_data.country ? js_data.country : null,
            zip_code: js_data.zip_code ? js_data.zip_code : "",
          }}
          validationSchema={job_seeker_create_profile}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // resetForm();
            setSubmitting(false);
            set_zip_error(
              values.country.name === "USA"
                ? !validate_us_zip(values.zip_code)
                : !validate_canada_zip(values.zip_code)
            );
            if (!validate_address(values.country, values.province, values.city) ){
              return;
            }
            const payload = {
              telephone: values.telephone,
              address: values.address,
              zip_code: values.zip_code,
              city_id: values.city.id,
              country_id: values.country.id,
              province_id: values.province.id,
              commute_time,
              job_distance,
              is_legally_work: allowed_work,
            };
            // console.log(payload);
            if (
              (values.country.name === "USA" && validate_us_zip(values.zip_code)) ||
              (values.country.name === "Canada" && validate_canada_zip(values.zip_code))
            ) {
              dispatch(update_js_info(payload, user));
              history.push("/js/createresume");
            } else {
              return
            }
          }}
        >
          {(props) => (
            <Form>
              <FormikTextInput name="first_name" label="First Name" disabled />
              <FormikTextInput name="last_name" label="Last Name" disabled />
              <FormikTextInput name="telephone" label="Phone Number *" />

              <div className="profile-question-div">
                <p>Address *</p>
                <FormikDropDown
                  options={countries}
                  name="country"
                  placeholder={"Country"}
                  value={countries.find(
                    (pos) => pos.value === props.values.country?.id
                  )}
                />
                <FormikDropDown
                  options={provinces.filter(
                    (province) =>
                      province.country.id === props.values.country?.id
                  )}
                  name="province"
                  placeholder={"Province"}
                  value={provinces.find(
                    (pos) => pos.value === props.values.province?.id
                  )}
                />
                <FormikDropDown
                  options={cities.filter(
                    (city) => city.province.id === props.values.province?.id
                  )}
                  name="city"
                  placeholder={"City"}
                  value={cities.find(
                    (pos) => pos.value === props.values.city?.id
                  )}
                />

                <div className="d-flex justify-content-between">
                  <FormikTextInput
                    style={{ width: "180%" }}
                    name="address"
                    placeholder="41 Street Example"
                  />
                  <FormikTextInput name="zip_code" placeholder="ZIP/Postal" />
                </div>
                {zip_error &&
                  validate_zip(props.values.zip_code, props.values.country) && (
                    <span
                      className="invalid-feedback text-right"
                    >
                      Invalid ZIP for {props.values.country.name}
                    </span>
                  )}
                { address_error ? <p className='invalid-feedback text-right'>Please check Country, Province and City </p> : null}
              </div>

              <hr />

              <div className="profile-question-div">
                <p>Are you legally allowed to work in this country? *</p>
                <div style={{ textAlign: "center" }}>
                  <OptionButton
                    label={"No"}
                    checked={allowed_work === false}
                    onClick={(e) => {
                      e.preventDefault();
                      set_allowed_work(false);
                      dispatch(
                        errorAlert(
                          "Our Platform cannot help you without your permission to work!"
                        )
                      );
                    }}
                  />
                  <OptionButton
                    label={"Yes"}
                    checked={allowed_work === true}
                    onClick={(e) => {
                      e.preventDefault();
                      set_allowed_work(true);
                    }}
                  />
                </div>
              </div>

              <div className="profile-question-div">
                <p>
                  Are you interested working remotely, meaning primarly from
                  home? *
                </p>
                <input
                  style={job_listing.radio}
                  type="radio"
                  onChange={(e) => set_remote("No")}
                  checked={remote === "No"}
                />{" "}
                No <br />
                <input
                  style={job_listing.radio}
                  type="radio"
                  onChange={(e) => set_remote("Temporary")}
                  checked={remote === "Temporary"}
                />{" "}
                Temporary <br />
                <input
                  style={job_listing.radio}
                  type="radio"
                  onChange={(e) => set_remote("Yes")}
                  checked={remote === "Yes"}
                />{" "}
                Yes <br />
              </div>

              <div className="profile-question-div">
                <p>Employment type? *</p>
                <input
                  style={job_listing.radio}
                  type="radio"
                  onChange={(e) => set_employment_type("Full-time")}
                  checked={employment_type === "Full-time"}
                />{" "}
                Full-time <br />
                <input
                  style={job_listing.radio}
                  type="radio"
                  onChange={(e) => set_employment_type("Part-time")}
                  checked={employment_type === "Part-time"}
                />{" "}
                Part-time <br />
                <input
                  style={job_listing.radio}
                  type="radio"
                  value="False"
                  onChange={(e) => set_employment_type("Both")}
                  checked={employment_type === "Both"}
                />{" "}
                Either full-time or part-time <br />
              </div>

              <div className="profile-question-div">
                <p>Commute time (minutes) *</p>
                <RangeSlider
                  maxValue={90}
                  minValue={0}
                  step={1}
                  value={commute_time}
                  onChange={(value) => set_commute_time(value)}
                />
              </div>

              <div className="profile-question-div">
                <p>Job Distance (miles) *</p>
                <RangeSlider
                  maxValue={100}
                  minValue={0}
                  step={1}
                  value={job_distance}
                  onChange={(value) => set_job_distance(value)}
                />
              </div>

              <div className="text-center mt-4">
                <button
                  className="btn btn-primary w-25"
                  type="submit"
                  disabled={!allowed_work}
                >
                  {props.isSubmitting ? "Loading..." : "Confirm"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateProfile;
