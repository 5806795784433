import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'


import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { useRecoilValue } from 'recoil'
import { messages_state } from '../../../recoil_state'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';


const styles = {
    icons:{
        width:'30px',
        margin:'7px',
        marginRight:'10px'
    },
    badge:{
        marginLeft: '5px',
        fontWeight:'bold',
        backgroundColor:'#ee5566',
        padding:5,
        borderRadius:5
    }
}

const Sidebar = () => {
    const sidebar_size = useSelector ( ({sidebar_size}) => sidebar_size)
    const user = useSelector ( ({user})=>user)
    const js_data = useSelector ( ({job_seeker_info})=>job_seeker_info)
    const location = useLocation()
    const num_messages = useRecoilValue(messages_state)

    if(!user || !sidebar_size){
        return null;
    }
    if (location.pathname.includes('/reference/rate/'))return null

    if(user.is_job_seeker && js_data?.completed_profile===true)
        return (

            <div className="sidebar bg-primary sticky-top" style={{width:sidebar_size}}>

                <div className="sticky-top">
                <Link className={location.pathname === '/js/mypositions' ? "active" : ''} to={`/js/mypositions`}>
                    <span>
                        <HomeOutlinedIcon style={styles.icons} fontSize="large" />
                        Home
                    </span>
                </Link>

                <Link className={location.pathname ==='/js/offers' ? "active" : ''} to={'/js/offers'}>
                    <span>
                        <WorkOutlineOutlinedIcon style={styles.icons} fontSize="large" />
                        Offers & Jobs
                    </span>
                </Link>
                
                <Link className={location.pathname ==='/messages' ? "active" : ''}  to={'/messages'}>
                    <span>
                        <MailOutlineOutlinedIcon style={styles.icons} fontSize="large" />
                        Messages
                        {num_messages.length > 0  && <span style={styles.badge}>1</span>}
                    </span>
                </Link>

                <Link className={location.pathname ==='/js/myprofile' ? "active" : ''} to={'/js/myprofile'}>
                    <span>
                        <AccountCircleOutlinedIcon style={styles.icons} fontSize="large" />
                        Account
                    </span>
                </Link>

                <Link className={location.pathname ==='/js/passiveincome' ? "active" : ''} to={'/js/passiveincome'}>
                    <span>
                        <MonetizationOnOutlinedIcon style={styles.icons} fontSize='large'/>
                        Passive income
                    </span>
                </Link>
                </div>
            </div>
        )
    if(user.is_job_provider)
        return (

            <div className="sidebar bg-primary sticky-top" style={{width:sidebar_size}}>
                <div className="sticky-top">

                <Link className={location.pathname ==='/jp/jobs' ? "active" : ''} to={`/jp/jobs`}>
                    <span> 
                        <WorkOutlineOutlinedIcon style={styles.icons} fontSize="large" />
                        Jobs
                    </span>
                </Link>
                
                <Link className={location.pathname ==='/messages' ? "active" : ''}    to={'/messages'}>
                    <span>
                    <MailOutlineOutlinedIcon style={styles.icons} fontSize="large" />
                    Messages
                    {num_messages.length > 0 && <span style={styles.badge}>1</span>}
                    </span>
                </Link>

                <Link className={location.pathname ==='/jp/invoices' ? "active" : ''}  style={{ pointerEvents: 'none'} } to={'/jp/invoices'}>
                    <span>
                        <CreditCardOutlinedIcon style={styles.icons} fontSize="large" />
                        Invoices
                    </span>
                </Link>

                <Link className={location.pathname ==='/jp' ? "active" : ''}  to={'/jp'}>
                    <span>
                        <AccountCircleOutlinedIcon style={styles.icons} fontSize="large" />
                        Account
                    </span>
                </Link>
                </div>
            </div>
        )

        return (
            <div>...</div>
        )

  
}

export default Sidebar;