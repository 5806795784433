import React, { useState } from "react";
import userService from "../../../services/user";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { errorAlert, successAlert } from "../../../reducers/alertReducer";


const InviteReferral = () => {
  const dispatch = useDispatch();
  const [curr_email, set_curr_email] = useState("");
  const [loading, set_loading] = useState(false)

  const valid_email = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handle_add = (e) => {
    e.preventDefault();
    submit_directly(curr_email)
  };

  const submit_directly = email => {
    set_loading(1)
    userService
      .create_referral({ email })
      .then(() => {
        dispatch(successAlert("Invitation successfully sent!"));
        set_loading(0)
      })
      .catch((err) => {
        dispatch(
          errorAlert(
            "You are trying to invite a user which you have already invited or is registered at Rapihire. You can send invitation to other friends!"
          )
        );
        setTimeout(() => dispatch(errorAlert("")), 8000);
        set_loading(0)
      });
  }


  return (
    <div className="logged-in-container">
      <Link
        style={{ textDecoration: "none" }}
        to="/js/passiveincome"
        className="form_header"
      >
        <ArrowBackIcon
          style={{ marginBottom: 4, opacity: 0.6 }}
          fontSize="large"
        />{" "}
      </Link>

      <div className="w-75 m-auto">
        <p class="h5 font-weight-bold text-secondary">
          Invite friends
        </p>
        <br />
        <label class="input-label text-secondary">Email *</label>
        <br />
        <input
          class="form-control"
          value={curr_email}
          onChange={(e) => set_curr_email(e.target.value)}
        />
        {valid_email(curr_email) && (
          <button disabled={loading} class="btn btn-primary mt-4 w-100 text-center" onClick={handle_add} >
            <span>{loading ? 'Adding' : 'Add'}</span>
          </button>
        )}
        <br />
        {/* <div style={job_listing.skill_list}>
          {referrals.map((email, index) => (
            <div key={index}>
              <SingleSkill label={email} key={email} delete={handle_delete} />
            </div>
          ))}
        </div> */}
        {/* <div style={{ marginTop: 100, textAlign: "right" }}>
          {referrals.length > 0 && (
            <button onClick={handle_submit} style={styles.send}>
              Send Invite
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default InviteReferral;
