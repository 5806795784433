import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import logo from "../../icons/logo.svg";
import playstore_icon from "../../icons/google-play-badge.png";
import appstore_icon from "../../icons/AppStore.png";



const styles = {
  employer_button: {
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    border: "none",
    color: "black",
    padding: "15px",
    fontSize: 14
  },
  job_seeker_button: {
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    border: "none",
    color: "black",
    padding: "15px",
    fontSize: 14

  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  login: {
    padding: 10,
    border: "1px solid white",
    textAlign: "center",
    width: 100,
    position: "absolute",
    right: "5%",
  },
  header: {
    color: "white",
    fontSize: "36px",
    fontWeight: "bold",
  },
  div: {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100vh",
    backgroundImage: `url(https://rapihirepublic.s3.ca-central-1.amazonaws.com/webpage/landing.png)`,
  },
  nav: {
    color: "white",
    paddingRight: 40,
    fontWeight: "bold",
    textAlign: "center",
  },
};

const Landing = () => {
  const user = useSelector(({ user }) => user);

  const search = useLocation().search;
  const referrer_id = new URLSearchParams(search).get("uid");
  const hashed_email = new URLSearchParams(search).get("he");

  if (user) {
    if (user.is_job_seeker) return <Redirect push to="/js/myprofile" />;
    else return <Redirect push to="/jp" />;
  }


  return (
    <div style={styles.div}>
      <br />
      <div className="landing-nav">
        <a href="https://rapihire.com"> 
          <img
            style={{ paddingLeft: 10, marginRight: 60 }}
            alt="raihire logo"
            src={logo}
          />
        </a>
        {/* <a href='/' >Home</a>
                <a href='/' >Product</a>
                <a href='/' >Pricing</a>
                <a href='/' >About</a> */}
      </div>

      <div style={styles.login}>
        <a style={{ color: "white" }} href="/login">
          Sign In
        </a>
      </div>
      <div style={{ textAlign: "center", marginTop: "30vh" }}>
        <h2 style={styles.header}>Fast employment without job interviews</h2>
        <br />
        <div className="d-flex justify-content-center">

        <div style={{padding: 20, paddingRight:0}} >
              <button style={styles.job_seeker_button}>
                <a className="text-decoration-none" style={{ color: "black", fontWeight: 700 }} href={(referrer_id && hashed_email) ? `/js/register?uid=${referrer_id}&he=${hashed_email}` : '/js/register'}>
                  Create job seeker account
                </a>
                </button> <br/> <br/>
                <a className="text-decoration-none text-white" target="_blank" href="https://www.youtube.com/watch?v=jOUi6s9KlXQ">
                Trouble registering as <br/> a job seeker?
                </a>
                 <br/> <br/>
                <a className="text-decoration-none " target="_blank" href="https://play.google.com/store/apps/details?id=com.rapihire_js">
                <img style={{ width: 150, height:50  }} alt="Google Play" src={playstore_icon} />
                </a>
                <br/> <br/>
               <a className="text-decoration-none " target="_blank" href="https://apps.apple.com/app/rapihire/id1610855893">
               <img style={{ width: 150, height:50 }} alt="App Store" src={appstore_icon} />
               </a>
              </div>

          <div style={{borderLeft: '3px solid #fff', marginLeft: 20, padding: 20}}>
            <button style={styles.employer_button}>
              <a className="text-decoration-none" style={{ color: "black", fontWeight: 700 }} href={(referrer_id && hashed_email) ? `/jp/register?uid=${referrer_id}&he=${hashed_email}` : '/jp/register'}>
                Create business account
              </a>
            </button> <br/><br/>
            <a className="text-decoration-none text-white" target="_blank" href="https://www.youtube.com/watch?v=dQ1wjB4m1rA">
            Trouble registering as <br/> a business?
            </a>
            <br/><br/>
            <a className="text-decoration-none" target="_blank" href="https://play.google.com/store/apps/details?id=com.rapihire_jp">
                    <img style={{ width: 150, height:50 }} alt="Google Play" src={playstore_icon} />
            </a>
            <br/> <br/>
           <a className="text-decoration-none " target="_blank" href="https://apps.apple.com/app/rapihire-business/id1610517870">
           <img style={{ width: 150, height:50  }} alt="App Store" src={appstore_icon} />
           </a>
          </div>

        </div>
        <br />
        {/* <p style={{ color: "white", fontWeight: 700 }}>Need Help?</p> */}
      </div>
    </div>
  );
};

export default Landing;
