import userService from '../services/user'

const skillReducer = (state=[], action) => {
    switch (action.type){

        case 'GET_JOB_SEEKER_SKILLS':
            return action.data
        
        case 'ADD_JOB_SEEKER_SKILLS':
            return [...state, action.data]
        
        case 'DELETE_JOB_SEEKER_SKILL':
            const new_state = state.filter(p => p.id.toString() !== action.data.id.toString())
            return new_state

        default:
            return state
    }
}

export const get_seeker_skills = () => {
    return async dispatch => {
        try{
           
            const data = await userService.getUserSkills()
            dispatch({
                type:'GET_JOB_SEEKER_SKILLS',
                data
            })
        }
        catch(exception){
            // console.log(exception);
            console.log("Error getting job seeker skills");
        }
    }
}


export const add_job_seeker_skills = (payload) => {
    return async dispatch => {
        try{
           
            await userService.addUserSkills(payload)
            dispatch(get_seeker_skills())
        }
        catch(exception){
            // console.log(exception);
            console.log("Error getting job seeker skills");
        }
    }
}



export const delete_job_seeker_skill = (id) => {
    return async dispatch => {
        try{
           
            await userService.deleteUserSkill(id)
            const data = {id}
            dispatch({
                type:'DELETE_JOB_SEEKER_SKILL',
                data
            })
        }
        catch(exception){
            // console.log(exception);
            console.log("Error deleting skill");
        }
    }
}



export default skillReducer;